import {
  BackgroundTask,
  BackgroundTaskStates,
  BackgroundTaskType,
} from "@api/progress-api/progress-api-types";

/** Possible states of an SdbBackgroundTask entity */
export type SdbBackgroundTaskStates = BackgroundTaskStates | "Pending";

/** Minimal subset of the "status.result" field for taskType = "CloudRegistration". */
export type SdbStatusResultCloudRegistration = {
  additionalData?: {
    state?: "FAILED" | "PUBLISHED" | string;
    error?: "NO_ERROR" | string;
    pointcloudName?: string;
    registeredScans?: number;
    unregisteredScans?: number;
    inspectAndPublishUrl?: string;
    projectUrl?: string;
  }
};

export type SdbBackgroundTask = Pick<
  BackgroundTask,
  "id" | "createdAt" | "taskType" | "context" | "status"
> & {
  /** The background task category */
  taskCategory: BackgroundTaskCategory | null;

  /** Possible state values for a background task in the Progress API */
  state: SdbBackgroundTaskStates | null;

  /** Value that represents the completion of a task */
  progress?: string;

  /** ISO-8601 date-time string when the update happened */
  updatedAt: string | null;

  /** Additional info coming from the backend to show to the user */
  message?: string;

  /** A human readable name for the associated IElement */
  elementName?: string;

  /** Deep link to open the associated IElement in the Sphere Viewer */
  viewerDeepLink?: string;
};

export enum BackgroundTaskCategory {
  /** Point cloud background task */
  pointCloud = "PointCloud",

  /** Video mode background task */
  videoMode = "VideoMode",

  /** CAD background task */
  cad = "Cad",

  /** Sphere Legacy migration task */
  migration = "Migration",
}

/** Base props for a sdb background task entity */
export interface BaseBackgroundTaskProps {
  /** The SDB background task */
  task: SdbBackgroundTask;
}

/** Utility type to restrict an SdbBackgroundTask entity to an specific type and category */
export type TaskWithTypeAndCategory<
  T extends BackgroundTaskType,
  C extends BackgroundTaskCategory
> = SdbBackgroundTask & {
  taskType: T;
  taskCategory: C;
};

/** SdbBackgroundTask of type "CLoudRegistration" and category "PointCloud" */
export type CloudRegistrationTask = TaskWithTypeAndCategory<
  "CloudRegistration",
  BackgroundTaskCategory.pointCloud
>;
