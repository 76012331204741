import { SvgIcon } from "@mui/material";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import PublishedSvg from "@assets/icons/new/checkmark-circle_24px.svg?react";
// TODO: Exchange with better icon.
import UnpublishedSvg from "@assets/icons/new/thick-circle_24px.svg?react";
import StartSvg from "@assets/icons/new/info-circle_24px.svg?react";
import ErrorSvg from "@assets/icons/new/exclamation-mark-circle_24px.svg?react";
import { MemberTypes } from "@custom-types/member-types";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { FormatDate } from "@hooks/use-date-time";
import {
  TableHeaders,
  TableItem,
} from "@pages/project-details/project-data-management/data-management-types";
import { getDeviceType } from "@pages/project-details/project-data-management/data-management-utils";
import { sphereColors } from "@styles/common-colors";
import { getMemberNameById } from "@utils/member-utils";
import { SphereTooltip } from "@components/common/sphere-tooltip";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];

  /** Function to format date */
  formatDate: FormatDate;
}

/**
 * Reduce the tooltip delay since it isn't obvious that the icons have a tooltip and we want to offer faster
 * feedback to the user this way.
 */
const ENTER_DELAY = 200;

/**
 * Returns an object with the Staging Area table columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getDataManagementTableColumns({
  companyMembers,
  projectMembers,
  formatDate,
}: Props): Record<TableHeaders, GridColDef> {
  return {
    [TableHeaders.name]: {
      field: TableHeaders.name,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
        const name = params.row.entity.name;
        return (
          <FaroTableTextCell
            text={name}
            sx={{
              fontWeight: "bold",
            }}
          />
        );
      },
      valueGetter: (_, row: { entity: TableItem }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TableHeaders.createdBy]: {
      field: TableHeaders.createdBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.createdBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: TableItem }) =>
        getMemberNameById({
          memberId: row.entity.createdBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TableHeaders.createdAt]: {
      field: TableHeaders.createdAt,
      minWidth: 160,
      flex: 0.75,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
        const date = formatDate(
          params.row.entity.createdAt,
          DateTime.DATETIME_MED
        );
        return <FaroTableTextCell text={date} />;
      },
      valueGetter: (_, row: { entity: TableItem }) =>
        new Date(row.entity.createdAt),

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [TableHeaders.deviceType]: {
      field: TableHeaders.deviceType,
      minWidth: 100,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
        const deviceType = getDeviceType(params.row.entity.deviceType);
        return <FaroTableTextCell text={deviceType} />;
      },
      valueGetter: (_, row: { entity: TableItem }) =>
        getDeviceType(row.entity.deviceType),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [TableHeaders.status]: {
      field: TableHeaders.status,
      type: "actions",
      align: "right",
      minWidth: 100,
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<{ entity: TableItem }>) => {
        switch (params.row.entity.state) {
          case "uploading":
            return (
              <SphereTooltip title="The scan is currently getting uploaded. Please wait." enterDelay={ENTER_DELAY}>
                <FaroButtonSpinner loadingTrackColor={sphereColors.gray100} size="24px" marginLeft="0px" />
              </SphereTooltip>
            );
          case "processing":
            return (
              <SphereTooltip title="The scan is currently getting processed. Please wait." enterDelay={ENTER_DELAY}>
                <FaroButtonSpinner loadingTrackColor={sphereColors.gray100} size="24px" marginLeft="0px" />
              </SphereTooltip>
            );
          case "unpublished":
            return (
              <SphereTooltip title="The project containing the scan has not been published yet." enterDelay={ENTER_DELAY}>
                <SvgIcon inheritViewBox component={UnpublishedSvg} sx={{ color: sphereColors.blue500 }} />
              </SphereTooltip>
            );
          case "published":
            return (
              <SphereTooltip title="The project containing the scan has been published." enterDelay={ENTER_DELAY}>
                <SvgIcon inheritViewBox component={PublishedSvg} sx={{ color: sphereColors.blue500 }} />
              </SphereTooltip>
            );
          case "error":
            return (
              <SphereTooltip title="An error has occurred!" enterDelay={ENTER_DELAY}>
                <SvgIcon inheritViewBox component={ErrorSvg} sx={{ color: sphereColors.red500 }} />
              </SphereTooltip>
            );
          case "start":
          default:
            return (
              <SphereTooltip title="The scan will be uploaded and processed automatically. Please wait." enterDelay={ENTER_DELAY}>
                <SvgIcon inheritViewBox component={StartSvg} sx={{ color: sphereColors.gray500 }} />
              </SphereTooltip>
            );
        }
      },
    },
  };
}
