import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Grid, Typography } from "@mui/material";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";

interface Props {
  /** True if disconnect request is in progress */
  isDisconnecting: boolean;

  /** Callback while confirming disconnect dialog */
  onDisconnectConfirm(): Promise<void>;

  /** Callback while closing the dialog */
  onClose(): void;
}

/** Renders project integration connect dialog */
export function ProjectIntegrationsDisconnectDialog({
  isDisconnecting,
  onDisconnectConfirm,
  onClose,
}: Props): JSX.Element {
  return (
    <FaroDialog
      open={true}
      onConfirm={() => void onDisconnectConfirm()}
      isConfirmLoading={isDisconnecting}
      confirmText="Disconnect"
      title="Disconnecting integration?"
      onClose={onClose}
    >
      <Grid
        maxWidth="100%"
        alignItems="center"
        container
        flexDirection="column"
        justifyContent="center"
      >
        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            marginBottom: "32px",
          }}
        >
          By disconnecting, the integration will no longer work, but you can
          reconnect it later if needed.
        </Typography>
      </Grid>
    </FaroDialog>
  );
}
