import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { LabelWithHelp } from "@components/common/label-with-help";
import { Box, FormControl, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { HTMLInputTypeAttribute, useState } from "react";
import HideIcon from "@assets/icons/new/hide_24px.svg?react";
import ShowIcon from "@assets/icons/new/show_24px.svg?react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  setUpdatedEmail,
  updateCurrentUserProfile,
} from "@store/user/user-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useToast } from "@hooks/use-toast";
import { updatedEmailSelector } from "@store/user/user-selector";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { UserProfile } from "@utils/track-event/track-event-list";
import { isValidEmail } from "@utils/member-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Current user */
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;
}

/** Defines the possible input types for a text field */
type InputType = Extract<HTMLInputTypeAttribute, "password" | "text">;

/**
 * Component that displays an text input to change the user's email,
 * once the user accepts the changes, it shows a dialog to
 * prompt for the user's password.
 */
export function ChangeEmailDialog({ currentUser }: Props): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const updatedEmail = useAppSelector(updatedEmailSelector);
  const [newDisplayEmail, setNewDisplayEmail] = useState<string>(
    updatedEmail ?? currentUser?.email ?? ""
  );
  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  /** Alternate between password and text to show or hide the user's password in the text field  */
  const [passwordInputType, setPasswordInputType] =
    useState<InputType>("password");
  const [userPassword, setUserPassword] = useState<string>("");
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  /**
   * Opens the dialog and sets the initial value for the email field.
   *
   * @param newEmail New email to set as initial value for the email field.
   * @returns The new email if the user confirms the change, or the current email if the user
   * cancels the change.
   */
  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function openDialog(newEmail: string): Promise<string> {
    if (newEmail.toLowerCase() === currentUser?.email?.toLowerCase()) {
      // Do not show dialog if the email didn't change
      return currentUser?.email;
    }
    setIsDialogOpen(true);
    setNewDisplayEmail(newEmail);
    return newEmail;
  }

  /** Closes the dialog and resets the email field to the user's email */
  function closeDialog(): void {
    setIsDialogOpen(false);
    setUserPassword("");
    setIsChangingEmail(false);
  }

  /** Triggered when user clicks on cancel, closes the dialog. */
  function onCancel(): void {
    closeDialog();
    setNewDisplayEmail(currentUser?.email ?? "");
  }

  /**
   * Triggered when user clicks on confirm to change email, calls the updateCurrentUserProfile
   * action to update the user's email and closes the dialog at the end.
   */
  async function onConfirmEmail(): Promise<void> {
    setIsChangingEmail(true);
    trackEvent({
      name: UserProfile.updateProfile,
      props: { attribute: "email" },
    });

    try {
      if (!currentUser) {
        throw new Error("User is required");
      }
      if (!newDisplayEmail) {
        throw new Error("Email is required");
      }
      await dispatch(
        updateCurrentUserProfile({
          coreApiClient,
          user: {
            ...currentUser,
            email: newDisplayEmail,
          },
          password: userPassword,
        })
      );
      closeDialog();
      showToast({
        message: "Request to change email successfully sent",
        description:
          "Please check your inbox to validate your new email." +
          "Your email won't be updated until you validate it.",
        type: "success",
      });
      dispatch(setUpdatedEmail(newDisplayEmail));
      setNewDisplayEmail(newDisplayEmail);
    } catch (error) {
      setIsChangingEmail(false);
      handleErrorWithToast({
        id: `changeDisplayName-${Date.now().toString()}`,
        title: "Could not change email",
        error,
      });
      // Do not close dialog, let user try again in case password was incorrect
    }
  }

  return (
    <>
      <Box component="div" width="100%">
        <FaroTextField
          initialValue={newDisplayEmail}
          onConfirmed={openDialog}
          isFullWidth={true}
          faroVariant="row"
          isReadOnly={false}
          minInputLength={1}
          validate={isValidEmail}
        />
      </Box>

      <FaroDialog
        title={"Email address"}
        open={isDialogOpen}
        isConfirmLoading={isChangingEmail}
        isConfirmDisabled={!userPassword.length}
        onClose={onCancel}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={onConfirmEmail}
      >
        <Grid maxWidth="500px" width="70vw">
          <Box
            component="div"
            sx={{
              fontSize: DEFAULT_INPUT_FONT_SIZE,
              color: sphereColors.gray800,
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
            }}
          >
            After confirming your password, you will receive a verification
            email with a link you can click on to finalize the change.
          </Box>

          <LabelWithHelp title="New email" />
          <FaroSimpleTextField
            disabled
            value={newDisplayEmail}
            fullWidth={true}
            size="small"
            InputProps={{
              sx: {
                mb: SPACE_ELEMENTS_OF_MODAL,
                backgroundColor: sphereColors.gray100,
                "& .MuiInputBase-input.Mui-disabled": {
                  color: sphereColors.gray700,
                  WebkitTextFillColor: sphereColors.gray700,
                },
              },
            }}
          />

          <LabelWithHelp title="Confirm your password" />
          <FormControl fullWidth={true}>
            <FaroSimpleTextField
              value={userPassword}
              onChange={(event) => setUserPassword(event.target.value)}
              fullWidth={true}
              size="small"
              type={passwordInputType}
              placeholder="Enter current password"
              onKeyUp={(event) => {
                if (event.key === "Enter" && userPassword.length) {
                  // Send request if user presses enter and password is not empty
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
                  onConfirmEmail();
                }
              }}
              InputProps={{
                endAdornment: (
                  <FaroIconButton
                    component={
                      passwordInputType === "password" ? HideIcon : ShowIcon
                    }
                    onClick={() =>
                      setPasswordInputType(
                        passwordInputType === "password" ? "text" : "password"
                      )
                    }
                    buttonSize="24px"
                    iconSize="18px"
                  />
                ),
                sx: {
                  "& .MuiInputBase-input.Mui-disabled": {
                    color: sphereColors.gray800,
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
      </FaroDialog>
    </>
  );
}
