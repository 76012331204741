import {
  IntegrationProject,
  ProjectIntegrationId,
  ProjectIntegrationsMap,
} from "@services/integrations-service/integrations-service-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  isAutodeskLegacyProjectIntegration,
  isProcoreLegacyProjectIntegration,
  isProjectIntegration,
} from "@services/integrations-service/integrations-type-guards";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useIntegrationsService } from "@hooks/use-integrations-service";
import { runtimeConfig } from "@src/runtime-config";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { ReactSetStateFunction } from "@custom-types/types";

interface ReturnProps {
  /** List of integrations enabled in workspace level and not available in project level */
  enabledWorkspaceIntegrations: SphereDashboardAPITypes.IntegrationId[];

  /** List of integrations enabled in project level (for selected project) */
  enabledProjectIntegrations: SphereDashboardAPITypes.IntegrationId[];

  /** Callback to connect integration to project */
  connectProject: (integrationProject: IntegrationProject) => Promise<void>;

  /** Flag to determine is project connected */
  shouldShowConnectedDialog: boolean;

  /** Flag to determine is project connected */
  setShouldShowConnectedDialog: ReactSetStateFunction<boolean>;

  /** Callback to disconnect integration to project */
  disconnectProject: (
    integrationId: SphereDashboardAPITypes.IntegrationId
  ) => Promise<void>;
}

/** All the hooks that are used for integration process in project level */
export function useProjectIntegrations({
  projectId,
}: BaseProjectIdProps): ReturnProps {
  const [enabledWorkspaceIntegrations, setEnabledWorkspaceIntegrations] =
    useState<SphereDashboardAPITypes.IntegrationId[]>([]);
  const [enabledProjectIntegrations, setEnabledProjectIntegrations] = useState<
    SphereDashboardAPITypes.IntegrationId[]
  >([]);
  const [shouldShowConnectedDialog, setShouldShowConnectedDialog] =
    useState<boolean>(false);

  /** Integrations available in project level */
  const [projectIntegrations, setProjectIntegrations] = useState<
    ProjectIntegrationsMap | undefined
  >(undefined);

  const coreApiClient = useCoreApiClient();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const integrationsService = useIntegrationsService({
    coreApiClient,
    procoreApiUrl: runtimeConfig.integrations.procoreApiUrl,
    projectApiClient,
  });
  const { handleErrorWithToast } = useErrorContext();

  const isProcoreConnected = useMemo(() => {
    if (!projectIntegrations) {
      return false;
    }

    const isProcoreLegacyConnected = isProcoreLegacyProjectIntegration(
      projectIntegrations[ProjectIntegrationId.procore]
    );
    const isProcoreObservationsConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.procoreObservations]
    );
    const isProcoreRfisConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.procoreRfis]
    );

    return (
      isProcoreLegacyConnected &&
      isProcoreObservationsConnected &&
      isProcoreRfisConnected
    );
  }, [projectIntegrations]);

  const isAutodeskConnected = useMemo(() => {
    if (!projectIntegrations) {
      return false;
    }

    const isAutodeskLegacyConnected = isAutodeskLegacyProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodesk]
    );
    const isAutodeskAccIssuesConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskAccIssues]
    );
    const isAutodeskAccRfisConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskAccRfis]
    );
    const isAutodeskBim360IssuesConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskBim360Issues]
    );
    const isAutodeskBim360RfisConnected = isProjectIntegration(
      projectIntegrations[ProjectIntegrationId.autodeskBim360Rfis]
    );

    return (
      isAutodeskLegacyConnected &&
      ((isAutodeskAccIssuesConnected && isAutodeskAccRfisConnected) ||
        (isAutodeskBim360IssuesConnected && isAutodeskBim360RfisConnected))
    );
  }, [projectIntegrations]);

  const connectProject = useCallback(
    async (integrationProject: IntegrationProject): Promise<void> => {
      const updatedProjectIntegrations =
        await integrationsService.connectProject(integrationProject);
      setProjectIntegrations(updatedProjectIntegrations);
    },
    [integrationsService]
  );

  const disconnectProject = useCallback(
    async (
      integrationId: SphereDashboardAPITypes.IntegrationId
      // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
    ): Promise<void> => {
      const updatedProjectIntegrations =
        await integrationsService.disconnectProject(integrationId);
      setProjectIntegrations(updatedProjectIntegrations);
    },
    [integrationsService]
  );

  /** Get the list of integrations available in project level */
  useEffect(() => {
    async function getProjectIntegrations(): Promise<void> {
      try {
        const integrations = await integrationsService.getProjectIntegrations();
        setProjectIntegrations(integrations);
      } catch (error) {
        handleErrorWithToast({
          id: `getProjectIntegrations-${Date.now().toString()}`,
          title: "Failed to get the project integrations.",
          error,
        });
      }
    }

    void getProjectIntegrations();
  }, [handleErrorWithToast, integrationsService]);

  /** Get the list of integrations available in user level */
  useEffect(() => {
    async function getIntegrationTokens(): Promise<void> {
      const EnabledWorkspaceIntegrations: SphereDashboardAPITypes.IntegrationId[] =
        [];
      try {
        const integrationTokens =
          await integrationsService.getIntegrationTokens();
        // There is no need to show the same integration in available apps if they are already connected to the project
        integrationTokens.forEach((token) => {
          if (!enabledProjectIntegrations.includes(token.provider)) {
            EnabledWorkspaceIntegrations.push(token.provider);
          }
        });
        setEnabledWorkspaceIntegrations(EnabledWorkspaceIntegrations);
      } catch (error) {
        handleErrorWithToast({
          id: `getIntegrationTokens-${Date.now().toString()}`,
          title: "Failed to get the integration tokens.",
          error,
        });
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    getIntegrationTokens();
  }, [enabledProjectIntegrations, handleErrorWithToast, integrationsService]);

  /** Updates the list of enabled integrations to project */
  useEffect(() => {
    const enabledProjectIntegrations: SphereDashboardAPITypes.IntegrationId[] =
      [];
    if (isAutodeskConnected) {
      enabledProjectIntegrations.push(
        SphereDashboardAPITypes.IntegrationId.autodesk
      );
    }
    if (isProcoreConnected) {
      enabledProjectIntegrations.push(
        SphereDashboardAPITypes.IntegrationId.procore
      );
    }
    setEnabledProjectIntegrations(enabledProjectIntegrations);
  }, [isAutodeskConnected, isProcoreConnected]);

  return {
    enabledWorkspaceIntegrations,
    enabledProjectIntegrations,
    connectProject,
    shouldShowConnectedDialog,
    setShouldShowConnectedDialog,
    disconnectProject,
  };
}
