import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect } from "react";
import { selectIsProjectLoading } from "@store/i-elements/i-elements-selectors";
import { pointCloudsSelector } from "@store/point-clouds/point-clouds-selector";
import { PointCloud } from "@custom-types/point-cloud-types";
import {
  IElementType,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { hasFetchedPointCloudsIElementsSelector } from "@store/sdb-i-elements/sdb-i-elements-selectors";
import { setHasFetchedPointCloudsIElements } from "@store/sdb-i-elements/sdb-i-elements-slice";

interface UsePointClouds {
  /** Array of PointCloud entities */
  pointClouds: PointCloud[];

  /** Whether the data required to get the PointCloud entities is loading */
  isLoading: boolean;
}

/**
 * Custom hook that takes care of fetching the ielements of type "PointCloudStream" and "PointCloudStreamWebShare"
 * and their ancestors, for a specific project that is selected by via it's ID.
 * It skips fetching if the ielements have already been fetched.
 * Each "PointCloudStream" or "PointCloudStreamWebShare" ielement represent a point cloud data set.
 *
 * @returns:
 * - The PointCloud entities array
 * - The "isLoading" flag that indicates whether the data required to get the PointCloud entities is loading
 */
export function usePointClouds({
  projectId,
}: BaseProjectIdProps): UsePointClouds {
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const dispatch = useAppDispatch();
  const pointClouds = useAppSelector(pointCloudsSelector);
  const isLoading = useAppSelector(selectIsProjectLoading);
  const hasFetchedPointCloudsIElements = useAppSelector(
    hasFetchedPointCloudsIElementsSelector
  );

  useEffect(() => {
    /**
     * Fetches the ielements associated to point clouds:
     * - First fetches the point cloud elements
     * - Secondly fetches the ancestors of the point clouds
     * - Finally it fetches the IElemLink elements that are children of the fetched point cloud data session.This
     *   elements give information whether the point cloud was used to generate a merged point cloud.
     *
     * After a successful fetch it sets the hasFetchedPointCloudsIElements flag.
     * The dispatch response needs to be unwrapped so we can set the flag on a successful response.
     */
    async function fetchPointCloudsIElements(): Promise<void> {
      await dispatch(
        fetchProjectIElements({
          fetcher: async () => {
            const pointClouds = await projectApiClient.getAllIElements({
              types: [
                IElementType.pointCloudStream,
                IElementType.pointCloudStreamWebShare,
              ],
            });

            const pointCloudIds = pointClouds.map(
              (pointCloud) => pointCloud.id
            );
            const pointCloudAncestors = await projectApiClient.getAllIElements({
              descendantIds: pointCloudIds,
            });

            const dataSessions = pointCloudAncestors.filter(
              isIElementSectionDataSession
            );
            const dataSessionIds = dataSessions.map(
              (dataSession) => dataSession.id
            );
            const mergedIElemLinks = await projectApiClient.getAllIElements({
              types: [IElementType.iElemLink],
              ancestorIds: dataSessionIds,
            });

            return [
              ...pointClouds,
              ...pointCloudAncestors,
              ...mergedIElemLinks,
            ];
          },
        })
      ).unwrap();

      dispatch(setHasFetchedPointCloudsIElements(true));
    }

    if (!hasFetchedPointCloudsIElements) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      fetchPointCloudsIElements();
    }
  }, [dispatch, hasFetchedPointCloudsIElements, projectApiClient]);

  return { pointClouds, isLoading };
}
