import { assert } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { uniqueId } from "lodash";
import pLimit from "p-limit";
import { UploadController } from "@context-providers/file-upload/upload-controller";
import {
  FileUploadParams,
  RemoveTaskFn,
  StartUploadFn,
  UpdateTaskFn,
  UploadManagerInterface,
} from "@custom-types/upload-manager-types";

// Too many concurrent uploads can cause high CPU load, and requests might time out.
// Apparently 32 would be the optimum for a 7 Gbit connection, but that was measured using a native app.
export const MAX_CONCURRENT_UPLOADS = 16;

/**
 * A manager that handles all ongoing uploads.
 *
 * WARNING: this class should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider,
 * and the hooks useFileUpload and useCancelUpload.
 */
export class UploadManager implements UploadManagerInterface {
  #uploads = new Map<GUID, UploadController>();
  #limiter = pLimit(MAX_CONCURRENT_UPLOADS);

  /**
   * @param startTaskInStore Function to start a background task in the store
   * @param updateTaskInStore Function to update the task in the store corresponding to the upload
   * @param removeTaskFromStore Function to remove a task from the store.
   */
  constructor(
    public startTaskInStore: StartUploadFn,
    public updateTaskInStore: UpdateTaskFn,
    public removeTaskFromStore: RemoveTaskFn
  ) {
    this.uploadCompleted = this.uploadCompleted.bind(this);
    this.uploadFailed = this.uploadFailed.bind(this);
    this.uploadUpdated = this.uploadUpdated.bind(this);
  }

  /**
   * Used to remove all listeners and upload process from the class
   *
   * @param id ID of the upload to remove
   */
  #removeUpload(id: GUID): void {
    const uploadController = this.#uploads.get(id);
    if (!uploadController) {
      return;
    }

    uploadController.uploadCompleted.off(this.uploadCompleted);
    uploadController.uploadFailed.off(this.uploadFailed);
    uploadController.uploadUpdated.off(this.uploadUpdated);
    this.#uploads.delete(id);
  }

  /**
   * Callback function triggered when an upload is completed
   * Marks upload task as succeeded
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that completed
   * @param arg.downloadUrl URL containing the uploaded file at the remote location.
   */
  private uploadCompleted(arg: { id: GUID; downloadUrl: string }): void {
    this.updateTaskInStore(arg.id, { status: BackgroundTaskState.succeeded });
    this.#removeUpload(arg.id);
  }

  /**
   * Callback function triggered when an upload is failed
   * Marks upload task as failed
   * Removes the listener and process for the upload
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.error Error thrown that made the upload to fail.
   */
  private uploadFailed(arg: { id: GUID; error: Error }): void {
    this.updateTaskInStore(arg.id, {
      status: BackgroundTaskState.failed,
      errorMessage: arg.error.message,
    });
    this.#removeUpload(arg.id);
  }

  /**
   * Callback function triggered when an upload got progress
   *
   * @param arg the arguments
   * @param arg.id ID of the upload that failed
   * @param arg.progress Current progress of the given upload, from 0 to 100
   * @param arg.expectedEnd Expected end timestamp of this task
   */
  private uploadUpdated(arg: {
    id: GUID;
    progress: number;
    expectedEnd: number;
  }): void {
    this.updateTaskInStore(arg.id, {
      progress: arg.progress,
      status: BackgroundTaskState.started,
      expectedEnd: arg.expectedEnd,
    });
  }

  /**
   * Starts a new file upload and adds it to the managed uploads.
   */
  startFileUpload({
    file,
    isSilent = false,
    coreApiClient,
    finalizer,
    onUploadCompleted,
    onUploadFailed,
    onUploadUpdated,
    onUploadCanceled,
    context,
  }: FileUploadParams): void {
    const id = uniqueId();
    const controller = new UploadController(
      id,
      file,
      // The CoreAPI endpoint to upload files always requires the project ID field.
      // If the project ID is not defined then just pass an empty string.
      context.projectId ?? "",
      coreApiClient
    );
    if (onUploadCompleted) {
      controller.uploadCompleted.on(
        (arg: { id: GUID; downloadUrl: string; md5: string }) =>
          onUploadCompleted(
            arg.id,
            file.name,
            file.size,
            file.type,
            arg.downloadUrl,
            arg.md5
          )
      );
    }
    if (onUploadFailed) {
      controller.uploadFailed.on(
        (arg: { id: GUID; fileName: string; error: Error }) =>
          onUploadFailed(arg.id, arg.fileName, arg.error)
      );
    }
    if (onUploadUpdated) {
      controller.uploadUpdated.on((arg: { id: GUID; progress: number }) =>
        onUploadUpdated(arg.id, arg.progress)
      );
    }
    if (onUploadCanceled) {
      controller.uploadCanceled.on((arg: { id: GUID; fileName: string }) =>
        onUploadCanceled(arg.id, arg.fileName)
      );
    }
    controller.uploadCompleted.on(this.uploadCompleted);
    controller.uploadFailed.on(this.uploadFailed);
    controller.uploadUpdated.on(this.uploadUpdated);
    this.#uploads.set(id, controller);
    // Update the store with the new task
    this.startTaskInStore(id, file, isSilent, context);
    // Start the actual upload, once there is a free slot, according to the limiter.
    void this.#limiter(() => {
      return controller.uploader.doUpload(controller.abortController.signal, finalizer);
    });
  }

  /**
   * Cancels a file upload
   *
   * @param id The ID of the corresponding background task in the store
   * @param shouldRemoveTaskFromStore True to remove the task from store, primarily when all uploads are aborted.
   * @returns Whether the upload existed and was in progress, therefore canceled correctly.
   */
  cancelFileUpload(id: GUID, shouldRemoveTaskFromStore: boolean = false): boolean {
    const controller = this.#uploads.get(id);
    if (shouldRemoveTaskFromStore) {
      this.removeTaskFromStore(id);
    }
    // created:   If the upload is delayed by the limiter, we should still allow to cancel it.
    // scheduled: Seems currently not used for upload tasks; added "just in case".
    const ALLOWED_STATES_TO_CANCEL = [
      BackgroundTaskState.created,
      BackgroundTaskState.scheduled,
      BackgroundTaskState.started,
    ];
    const state = controller?.uploader?.state;
    if (!controller || !state || !ALLOWED_STATES_TO_CANCEL.includes(state)) {
      return false;
    }
    controller.abortController.abort();
    this.updateTaskInStore(id, { status: BackgroundTaskState.aborted });
    this.#uploads.delete(id);
    return true;
  }

  /**
   * Removes a file upload from the managed uploads
   *
   * @param id the ID of the upload
   * @returns whether the removal was successful or whether the upload was still in progress.
   */
  removeFileUpload(id: GUID): boolean {
    const controller = this.#uploads.get(id);
    if (!controller) {
      return false;
    }

    if (controller.uploader.state === BackgroundTaskState.started) {
      return false;
    }

    this.#removeUpload(id);
    this.removeTaskFromStore(id);
    return true;
  }

  /**
   * Sets the maximum number of concurrent file uploads.
   * @param value Integer >= 1.
   */
  setMaxConcurrentUploads(value: number): void {
    assert(
      Number.isInteger(value) && value >= 1,
      "The maximum number of concurrent uploads must be an integer >= 1."
    );
    this.#limiter.concurrency = value;
  }
}
