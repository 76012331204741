import { useCallback } from "react";
import { LsDataV2Package, ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useStagingAreaApiClient } from "@api/stagingarea-api/use-stagingarea-api-client";
import { APITypes } from "@stellar/api-logic";

type ReturnFunction = (
  lsDataV2Files: LsDataV2Package,
) => Promise<ReadLsDataV2Response | null>;

/** Try to read the LsDataV2 package. */
export function useReadLsDataV2(projectId: APITypes.ProjectId): ReturnFunction {
  const { handleErrorWithToast } = useErrorContext();
  const stagingAreaApi = useStagingAreaApiClient({ projectId });

  return useCallback(
    async (
      lsDataV2Files: LsDataV2Package
    ): Promise<ReadLsDataV2Response | null> => {
      try {
        return await stagingAreaApi.postReadLsDataV2(lsDataV2Files.files);
      } catch (error) {
        lsDataV2Files.isValid = false;
        handleErrorWithToast({
          id: `readLsDataV2-${Date.now().toString()}`,
          title: "Failed to read scan metadata.",
          error,
        });
        return null;
      }
    },
    [handleErrorWithToast, stagingAreaApi]
  );
}
