import { MemberTypes } from "@custom-types/member-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * Gets the initials of a team name to be shown in an avatar
 * E.g. "Sphere Developers" -> "SD"
 *
 * @param teamName The name of a team
 * @returns The initials to show inside an avatar.
 */
export function getTeamAvatarInitials(teamName?: string | null): string {
  if (!teamName) {
    return "";
  }
  const teamNameWords = teamName.split(" ");

  // If the team name has two or more words, return the first letter of the first two words.
  if (teamNameWords.length >= 2) {
    return `${teamNameWords[0][0]}${teamNameWords[1][0]}`.toUpperCase();
  }

  // If the team name has one word, return the first two letters of the word
  return teamName.slice(0, 2).toUpperCase();
}

/**
 * Checks if the given email belongs to a special user group.
 *
 * This function validates the email against a set of predefined patterns.
 * If the email matches any of the patterns, it is considered an email belonging to a user group.
 *
 * @param email - The email address to be validated.
 * @returns true if the email matches any special user group patterns; otherwise, false.
 */
export function isTeam(
  member: MemberTypes | SphereDashboardAPITypes.IUserAsManager
): boolean {
  // Check if the member has a "kind" property and if it's a team
  return member.kind === "user-group";
}

/**
 * Validates the team name
 * @returns true if the name contains comma or semicolon
 */
export function isValidTeamName(teamName: string): boolean {
  return !teamName.includes(",") && !teamName.includes(";");
}
