import {
  // eslint-disable-next-line no-restricted-imports -- We need the original component for user menu
  IconButton,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  currentUserSelector,
  loggedInUserSelector,
} from "@store/user/user-selector";
import {
  getPendingUserIcon,
  getPrettyName,
  getUserInitials,
} from "@utils/user-utils";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { SphereDivider } from "@components/common/sphere-divider";
import { useAuthContext } from "@context-providers/auth/auth-context";
import { LanguageSelector } from "@components/language-selector";
import { userMenuLink } from "@styles/common-styles";
import { getSvgColoredIconCss } from "@styles/common-styles";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";
import { MemberTabs } from "@router/route-params";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { UserMenuEvents } from "@utils/track-event/track-event-list";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import { resetSplashScreenStatus, setFueFlags } from "@store/user/user-slice";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  Link,
  MenuAccountSecurityOption,
  MenuSignOutOption,
  UserMenu as UserMenuDropdown,
} from "@faro-lotv/flat-ui";
import { runtimeConfig } from "@src/runtime-config";
import { AboutLocalInfo } from "@components/header/about-local-info";
import { useMembersUtils } from "@hooks/use-member-utils";

/** Defines the default size for all icons */
const ICONS_SIZE = "20px";

/** Defines the style when an item is hovered. */
const MENU_ITEM_HOVER_STYLE: CSSProperties = {
  backgroundColor: sphereColors.gray100,
  color: sphereColors.blue500,
  "& .MuiTypography-root": {
    color: sphereColors.blue500,
  },
  // Color other text elements like the selected language.
  "& > *": {
    color: sphereColors.blue500,
  },
  // Color to blue all icons
  "& .MuiSvgIcon-root,.MuiListItemIcon-root svg": {
    ...getSvgColoredIconCss(sphereColors.blue500),
  },
};

/** Creates a theme to override the style of MUI Tooltip */
const listItemTextTheme = createTheme({
  components: {
    MuiListItemText: {
      defaultProps: {
        sx: {
          "& .MuiTypography-root": {
            color: sphereColors.gray800,
            fontSize: "14px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            "&.MuiButtonBase-root:hover": {
              ...MENU_ITEM_HOVER_STYLE,
            },
            "&.MuiButtonBase-root:active": {
              backgroundColor: sphereColors.gray200,
            },
          }),
      },
      defaultProps: {
        // Use our custom click effect with :active.
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        disableRipple: true,
      },
    },
  },
});

/**
 * Gets a component that show a user menu to be inserted in the header bar.
 *
 * @returns React user menu component.
 */
export function UserMenu(): JSX.Element | null {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const currentUser = useAppSelector(currentUserSelector);
  const loggedInUser = useAppSelector(loggedInUserSelector);
  const currentCompany = useAppSelector(selectedSdbCompanySelector);
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const { logout } = useAuthContext();

  const { openProfilePage } = useMembersUtils();

  /** HTML element used to set the position of the menu. */
  const [menuAnchorElement, setMenuAnchorElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const isMenuOpen = Boolean(menuAnchorElement);

  /** Links used at the bottom of the user menu component */
  const links: Link[] = useMemo(() => {
    return [
      {
        text: "Terms",
        href: "https://www.farosphere.com/home/legal/terms-of-service",
        callback: () => {
          trackEvent({ name: UserMenuEvents.openTermsDetails });
        },
      },
      {
        text: "Privacy",
        href: "https://www.holobuilder.com/privacy-policy-docs/",
        callback: () => {
          trackEvent({ name: UserMenuEvents.openPrivacyDetails });
        },
      },
      {
        text: "Imprint",
        href: "https://www.faro.com/de-DE/Imprint",
        callback: () => {
          trackEvent({ name: UserMenuEvents.openImprintDetails });
        },
      },
    ];
  }, [trackEvent]);

  /**
   * Gets the user information to be displayed in the user menu.
   * Depending on the available information about the user it will use different sources.
   * If the current user is available it will use that information, otherwise it will use
   * the logged in user information.
   */
  const userData = useMemo(() => {
    // Prefer current user over logged in user since it is more complete.
    if (currentUser) {
      return {
        id: currentUser.id,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        fullName: getPrettyName(currentUser),
        userInitials: getUserInitials(currentUser),
        thumbnailUrl: currentUser.thumbnailUrl,
        icon: getPendingUserIcon(currentUser),
        largeIcon: getPendingUserIcon(currentUser, "large"),
      };
    }
    // Logged in user is the only information available on page to select workspaces,
    // since the route to fetch user details require a company ID.
    if (loggedInUser) {
      return {
        id: loggedInUser.userId,
        email: loggedInUser.mailAddress,
        firstName: loggedInUser.name,
        lastName: loggedInUser.lastName,
        fullName: getPrettyName({
          firstName: loggedInUser.name,
          lastName: loggedInUser.lastName,
        }),
        userInitials: getUserInitials({
          // For the logged in user route the firstName is under the name property.
          firstName: loggedInUser.name,
          lastName: loggedInUser.lastName,
        }),
        thumbnailUrl: loggedInUser.profileImageUrl,
        icon: undefined,
        largeIcon: undefined,
      };
    }
    return undefined;
  }, [currentUser, loggedInUser]);

  /** Gets the user information for userMenu from flat-ui */
  const userDisplayInfo = useMemo(() => {
    if (!userData) {
      return undefined;
    }

    return {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      name: userData.fullName,
      profileImageUrl: userData.thumbnailUrl,
    };
  }, [userData]);

  /** Triggered when the button is clicked to open the menu */
  function handleClick(event: React.MouseEvent<HTMLElement>): void {
    trackEvent({ name: UserMenuEvents.openUserMenu });

    setMenuAnchorElement(event.currentTarget);
  }

  /** Closes the user menu */
  function handleClose(): void {
    setMenuAnchorElement(undefined);
  }

  /** Handles the click on the profile section */
  function handleProfileClick(): void {
    handleClose();

    openProfilePage({
      userIdentity: userData?.id,
      memberTab: MemberTabs.account,
      source: "user menu",
    });
  }

  if (!userDisplayInfo) {
    return null;
  }

  return (
    <ThemeProvider theme={listItemTextTheme}>
      <IconButton
        onClick={handleClick}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        sx={{
          padding: "0px",
        }}
      >
        <SphereAvatar
          initials={userData?.userInitials}
          src={userData?.thumbnailUrl}
          size="small"
          icon={userData?.icon}
          shouldUseSimplifiedAvatar={false}
        />
      </IconButton>
      <UserMenuDropdown
        anchorElement={menuAnchorElement}
        userDisplayInfo={userDisplayInfo}
        open={isMenuOpen}
        closeMenu={handleClose}
        appVersion={runtimeConfig.appVersion}
        links={links}
        linkStyle={{ fontSize: "11px", ...userMenuLink }}
        additionalAboutInfo={<AboutLocalInfo />}
        onOpenAbout={() => {
          trackEvent({ name: UserMenuEvents.openAboutDetails });
        }}
      >
        {/* Middle section for actions like switch language and log out */}
        <Box component="div">
          {/* User profile item. Only available when user selected a company */}
          {currentCompany && (
            <>
              <MenuAccountSecurityOption onClick={handleProfileClick} />
              <SphereDivider />
            </>
          )}

          {/* Language item */}
          <LanguageSelector />

          <SphereDivider />

          {/* Logout item */}
          <MenuSignOutOption
            onClick={() => {
              logout();
              trackEvent({ name: UserMenuEvents.logOut });
            }}
          />

          {/* TODO: Replace it with dedicated devtool page: https://faro01.atlassian.net/browse/ST-1577 */}
          {/* Development tool */}
          {isAlphaTestingEnabled && (
            <Box component="div" sx={{ mt: "10px" }}>
              <SphereDivider />
              <MenuItem
                sx={{
                  mt: "10px",
                }}
                onClick={() => {
                  dispatch(resetSplashScreenStatus());
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
                  dispatch(setFueFlags({ coreApiClient }));
                }}
              >
                <ListItemText>
                  <var>Reset FUE Flags (Beta Test)</var>
                </ListItemText>
                <ListItemIcon sx={{ justifyContent: "end" }}>
                  <DeveloperModeIcon
                    sx={{
                      width: ICONS_SIZE,
                      height: ICONS_SIZE,
                      color: sphereColors.gray800,
                    }}
                  />
                </ListItemIcon>
              </MenuItem>
            </Box>
          )}
        </Box>
      </UserMenuDropdown>
    </ThemeProvider>
  );
}
