// eslint-disable-next-line no-restricted-imports -- The only place needed to define useParams
import { generatePath, useParams } from "react-router-dom";
import {
  AddOnsTabs,
  GroupTabs,
  MainRoutes,
  MemberTabs,
  ProjectTabs,
  QueryParams,
  RouteParams,
  TeamTabs,
  UsersTabs,
} from "@router/route-params";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { ProjectArchivingState } from "@custom-types/project-types";
import {
  BuildForbiddenRouteQueryStringProps,
  buildForbiddenRouteQueryString,
} from "@utils/route-utils";

/** List of query parameters that should be preserved when navigating between pages */
export const PRESERVED_QUERY_PARAMS = [
  QueryParams.enableAlpha,
  QueryParams.enableBeta,
];
export interface GenerateForbiddenPageRouteProps
  extends BuildForbiddenRouteQueryStringProps {
  /**
   * The id for the current used company.
   */
  companyId?: string;
}

/**
 * Use throughout app instead of plain `useParams` to get type-safety
 */
export function useAppParams(): Readonly<Partial<RouteParams>> {
  return useParams<RouteParams>();
}

/** Name of different paths in the app  */
export enum EAppRoutes {
  WorkspaceSelectionRoute = "WorkspaceSelectionRoute",
  ProjectsRoute = "ProjectsRoute",
  ProjectDetailsRoute = "ProjectDetailsRoute",
  MembersRoute = "MembersRoute",
  GroupsRoute = "GroupsRoute",
  GroupsDetailsRoute = "GroupsDetailsRoute",
  MemberProfileRoute = "MemberProfileRoute",
  ForbiddenRoute = "ForbiddenRoute",
  AddOnsRoute = "AddOnsRoute",
  TeamRoute = "TeamRoute",
  TeamOverviewRoute = "TeamOverviewRoute",
}

/** List of routes available in the app */
export const AppRoutes: Record<EAppRoutes, string> = {
  /** The route of workspace selection */
  [EAppRoutes.WorkspaceSelectionRoute]: MainRoutes.root,

  /** The route of projects with dynamic segments */
  [EAppRoutes.ProjectsRoute]: `${MainRoutes.app}/${MainRoutes.projects}/:projectStatus`,

  /** The route of project details with dynamic segments */
  [EAppRoutes.ProjectDetailsRoute]: `${MainRoutes.app}/${MainRoutes.projects}/:projectId/:projectTabs`,

  /** The route of company members */
  [EAppRoutes.MembersRoute]: `${MainRoutes.app}/${MainRoutes.members}/${UsersTabs.members}`,

  /** The route of company groups */
  [EAppRoutes.GroupsRoute]: `${MainRoutes.app}/${MainRoutes.groups}`,

  /** The route of group details with dynamic segments */
  [EAppRoutes.GroupsDetailsRoute]: `${MainRoutes.app}/${MainRoutes.groups}/:groupId/:groupTabs`,

  /** The route of member profile page */
  [EAppRoutes.MemberProfileRoute]: `${MainRoutes.app}/${MainRoutes.members}/${UsersTabs.members}/:memberId/:memberTabs`,

  /** The forbidden route */
  [EAppRoutes.ForbiddenRoute]: `${MainRoutes.app}/${MainRoutes.forbidden}`,

  /** Add ons route */
  [EAppRoutes.AddOnsRoute]: `${MainRoutes.app}/${MainRoutes.addons}/:addOnsTab`,

  /** The route of teams */
  [EAppRoutes.TeamRoute]: `${MainRoutes.app}/${MainRoutes.members}/${UsersTabs.teams}`,

  /** The route of team overview */
  [EAppRoutes.TeamOverviewRoute]: `${MainRoutes.app}/${MainRoutes.members}/${UsersTabs.teams}/:teamId/${TeamTabs.members}`,
};

/**
 * Generates the projects route. The default tab is active if is not provided
 */
export function generateProjectsRoute(
  companyId: APITypes.CompanyId,
  projectStatus: ProjectArchivingState = ProjectArchivingState.active
): string {
  return generatePath(AppRoutes.ProjectsRoute, {
    companyId,
    projectStatus,
  });
}

/**
 * Generates the add ons route. The default tab is Extensions
 */
export function generateAddOnsRoute(
  companyId: APITypes.CompanyId,
  addOnsTab: AddOnsTabs = AddOnsTabs.extensions
): string {
  return generatePath(AppRoutes.AddOnsRoute, {
    companyId,
    addOnsTab,
  });
}

/**
 * Generates the project details route. The default tab is Overview if it is not provided
 */
export function generateProjectDetailsRoute(
  companyId: APITypes.CompanyId,
  projectId: APITypes.ProjectId,
  projectTabs: ProjectTabs = ProjectTabs.overview
): string {
  return generatePath(AppRoutes.ProjectDetailsRoute, {
    companyId,
    projectId,
    projectTabs,
  });
}

/**
 * Generates the group details route. The default tab is Managers if it is not provided
 */
export function generateGroupsRoute(companyId: APITypes.CompanyId): string {
  return generatePath(AppRoutes.GroupsRoute, {
    companyId,
  });
}

/**
 * Generates the group details route. The default tab is Managers if it is not provided
 */
export function generateGroupDetailsRoute(
  companyId: APITypes.CompanyId,
  groupId: APITypes.GroupId,
  groupTabs: GroupTabs = GroupTabs.managers
): string {
  return generatePath(AppRoutes.GroupsDetailsRoute, {
    companyId,
    groupId,
    groupTabs,
  });
}

/**
 * Generates the member profile route.
 */
export function generateMemberProfileRoute(
  companyId: APITypes.CompanyId,
  memberId: APITypes.UserId,
  memberTabs: MemberTabs = MemberTabs.overview
): string {
  return generatePath(AppRoutes.MemberProfileRoute, {
    companyId,
    memberId,
    memberTabs,
  });
}

/**
 * Generates the path to the forbidden page route, including the
 * missing project roles as query parameters.
 */
export function generateForbiddenPageRoute({
  companyId,
  requiredCompanyRoles,
  requiredProjectRoles,
  requiredCompanySubscriptions,
  userId,
}: GenerateForbiddenPageRouteProps): string {
  const path = generatePath(AppRoutes.ForbiddenRoute, {
    companyId,
  });
  const query = buildForbiddenRouteQueryString({
    requiredCompanyRoles,
    requiredProjectRoles,
    requiredCompanySubscriptions,
    userId,
  });
  return path + query;
}

/**
 * Generates the teams route.
 */
export function generateTeamsRoute(companyId: APITypes.CompanyId): string {
  return generatePath(AppRoutes.TeamRoute, {
    companyId,
  });
}

/**
 * Generates the team details route. The default tab is members if it is not provided
 */
export function generateTeamDetailsRoute(
  companyId: APITypes.CompanyId,
  teamId: SphereDashboardAPITypes.TeamId,
  teamTabs: TeamTabs = TeamTabs.members
): string {
  return generatePath(AppRoutes.TeamOverviewRoute, {
    companyId,
    teamId,
    teamTabs,
  });
}

/**
 * Extracts and returns preserved query parameters from the current URL.
 * Preserved parameters are defined in the `PRESERVED_QUERY_PARAMS` array.
 */
export function getPreservedQueryParams(location: string): string {
  const searchParams = new URLSearchParams(location);
  const preservedParams = new URLSearchParams();

  PRESERVED_QUERY_PARAMS.forEach((param) => {
    const value = searchParams.get(param);
    if (value !== null) {
      preservedParams.set(param, value);
    }
  });

  const preservedString = preservedParams.toString();
  return preservedString ? `?${preservedString}` : "";
}
