import { FileUploadTask } from "@custom-types/file-upload-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { useAppSelector } from "@store/store-helper";
import { isTaskInProgress } from "@hooks/upload-tasks/upload-tasks-utils";

interface TaskGroup {
  /** List of all aborted tasks */
  abortedTasks: FileUploadTask[];

  /** List of all failed tasks */
  failedTasks: FileUploadTask[];

  /** List of all in-upload tasks */
  inProgressTasks: FileUploadTask[];

  /** List of all successful tasks */
  successfulTasks: FileUploadTask[];
}

/**
 * Defined as type because currently both are identical
 */
type UseUploadTasks = TaskGroup;

/**
 * Custom hook that takes care of fetching all upload tasks from store
 */
export function useUploadTasks(): UseUploadTasks {
  const uploadTasks = useAppSelector(uploadTasksSelector);

  const taskGroup: TaskGroup = {
    abortedTasks: [],
    failedTasks: [],
    inProgressTasks: [],
    successfulTasks: [],
  };

  // Reforming the tasks by group based on in-progress or failed
  uploadTasks.forEach((uploadTask) => {
    if (isTaskInProgress(uploadTask)) {
      taskGroup.inProgressTasks.push(uploadTask);
    } else if (uploadTask.status === BackgroundTaskState.aborted) {
      taskGroup.abortedTasks.push(uploadTask);
    } else if (uploadTask.status === BackgroundTaskState.failed) {
      taskGroup.failedTasks.push(uploadTask);
    } else if (uploadTask.status === BackgroundTaskState.succeeded) {
      taskGroup.successfulTasks.push(uploadTask);
    }
  });

  return taskGroup;
}
