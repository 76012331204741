import { useLocation } from "react-router-dom";
import { SidebarItemProps } from "@components/sidebar/sidebar-items";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { shouldShowNewTag } from "@utils/date-utils";
import { NewBadge } from "@components/common/new-badge";
import { NewTag } from "@components/common/new-tag";
import { MouseEventHandler } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { PreservingNavLink } from "@components/common/preserve-navigation/preserving-nav-link";

interface Props {
  /** Flag whether the sidebar should be shown full size or minified */
  isSidebarFullSize: boolean;

  /** Sidebar item to show. */
  sidebarItem: SidebarItemProps;

  /** Callback event after clicking the sidebar item */
  onClick?: MouseEventHandler;
}

/** Renders a single sidebar item */
export function SidebarListItem({
  isSidebarFullSize,
  sidebarItem,
  onClick,
}: Props): JSX.Element {
  const { pathname } = useLocation();

  return (
    <SphereTooltip title={sidebarItem.title}>
      <PreservingNavLink
        onClick={onClick}
        key={sidebarItem.identity}
        data-testid={sidebarItem.dataTestId}
        to={sidebarItem.route}
        style={({ isActive }) => {
          return {
            color: isActive ? sphereColors.blue500 : "inherit",
            textDecoration: "none",
          };
        }}
      >
        {({ isActive }) => (
          <ListItem
            disablePadding
            sx={{
              display: "block",
              my: 0.5,
              ...(isActive && {
                borderLeft: `3px solid ${sphereColors.blue500}`,
              }),
            }}
          >
            <ListItemButton
              selected={pathname === sidebarItem.route}
              sx={{
                minHeight: 48,
                justifyContent: isSidebarFullSize ? "initial" : "center",
                pl: "18px",
                pr: "2px",
                "&:hover": {
                  backgroundColor: sphereColors.gray200,
                  color: sphereColors.blue500,
                  fill: sphereColors.blue500,
                },
                ...(isActive && {
                  pl: 2.25,
                  backgroundColor: sphereColors.pureWhite,
                  "&:hover": {
                    backgroundColor: sphereColors.pureWhite,
                  },
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isSidebarFullSize ? 1 : "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "24px",
                  width: "24px",
                  ...(isActive && {
                    fill: sphereColors.blue500,
                  }),
                }}
              >
                {!isSidebarFullSize &&
                shouldShowNewTag(sidebarItem.hideNewBadgeDate) ? (
                  <NewBadge>{sidebarItem.icon}</NewBadge>
                ) : (
                  sidebarItem.icon
                )}
              </ListItemIcon>
              <ListItemText
                sx={{
                  opacity: isSidebarFullSize ? 1 : 0,
                  fontSize: "14px",
                }}
              >
                <Typography
                  sx={{
                    ...withEllipsis,
                    fontWeight: isActive ? "600" : "normal",
                    fontSize: "14px",
                  }}
                >
                  {sidebarItem.title}
                </Typography>
              </ListItemText>
              {isSidebarFullSize &&
                shouldShowNewTag(sidebarItem.hideNewBadgeDate) && (
                  <ListItemIcon sx={{ justifyContent: "end" }}>
                    <NewTag />
                  </ListItemIcon>
                )}
            </ListItemButton>
          </ListItem>
        )}
      </PreservingNavLink>
    </SphereTooltip>
  );
}
