import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { setBulkActionName } from "@store/table/table-slice";
import { useAppDispatch } from "@store/store-helper";
import SuccessIcon from "@assets/icons/success_32px.svg?react";
import { SvgIcon, Typography } from "@mui/material";
import { getSvgColoredIconCss } from "@utils/ui-utils";
import { sphereColors } from "@styles/common-colors";
import { BulkSuccessDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";

interface Props {
  /** The content of the dialog */
  dialogContent: BulkSuccessDialogContent;
}

/** Show success message if all the bulk actions are successful */
export function BulkSuccessDialog({ dialogContent }: Props): JSX.Element {
  const dispatch = useAppDispatch();

  return (
    <FaroDialog
      open={true}
      iconInTitle={
        <SvgIcon
          inheritViewBox
          component={SuccessIcon}
          sx={{
            ...getSvgColoredIconCss(sphereColors.green700),
            height: "28px",
            width: "28px",
            mr: "12px",
          }}
        />
      }
      title={dialogContent.title}
      isSuccessMessage={true}
      onConfirm={() => {
        dispatch(setBulkActionName(null));
      }}
      confirmText="Done"
    >
      <Typography
        sx={{
          fontSize: "14px",
        }}
      >
        {dialogContent.description}
      </Typography>
    </FaroDialog>
  );
}
