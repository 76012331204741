import { formatUserRoleType } from "@utils/data-display";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useToast } from "@hooks/use-toast";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  AssignableUpdateCompanyMemberRole,
  CompanyMemberTypes,
} from "@custom-types/member-types";
import { currentUserSelector } from "@store/user/user-selector";
import { RoleChangeSuccessDescription } from "@components/table/members/members-column/role-column/role-change-success-description";
import { updateMemberRoleInWorkspace } from "@store/members/members-slice";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { isSuccessResponse } from "@context-providers/error-boundary/error-boundary-utils";
import { SelectCompanyRole } from "@components/role/select-company-role";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

const selectableRoles = Object.values(AssignableUpdateCompanyMemberRole);

interface Props extends BaseCompanyIdProps {
  /** The member to show/adjust the role. */
  member: CompanyMemberTypes;
}

/** Render and adjust member roles in company context */
export function CompanyMemberRole({ companyId, member }: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);

  const isCurrentUser = currentUser?.identity === member.identity;
  const { canEditMember } = useHasUserValidRoleCompanyLevel();

  // For Project and Group Manager roles don't show the "No role" option since
  // changing to "No role" will have no effect.
  let allowedRoles: CoreAPITypes.EUserCompanyRole[] = selectableRoles;
  if (
    member.role === CoreAPITypes.EUserCompanyRole.companyManager ||
    member.role === CoreAPITypes.EUserCompanyRole.projectManager
  ) {
    allowedRoles = selectableRoles.filter(
      (role) => role !== CoreAPITypes.EUserCompanyRole.member
    );

    allowedRoles.push(member.role);
  }

  /**
   * Return non-editable member role if:
   * - Member is current logged user
   * - User has no permission to edit member
   */
  if (isCurrentUser || !canEditMember) {
    return (
      <span style={{ fontSize: DEFAULT_TEXT_FONT_SIZE }}>
        {formatUserRoleType(member.role)}
      </span>
    );
  }

  /** Trigger updating member role and show success message */
  async function onRoleChange(
    role: SphereDashboardAPITypes.IAssignmentCompanyRole
  ): Promise<void> {
    // Avoid same role click and
    // changing to PM or GM role
    if (
      role !== member.role &&
      role !== CoreAPITypes.EUserCompanyRole.companyManager &&
      role !== CoreAPITypes.EUserCompanyRole.projectManager
    ) {
      const result = await dispatch(
        updateMemberRoleInWorkspace({
          coreApiClient,
          companyId,
          role,
          identity: member.identity,
        })
      );

      // Errors are already handled by the slice, only notify about a successful result
      if (isSuccessResponse(result)) {
        showToast({
          message: "Role Changed",
          description: (
            <RoleChangeSuccessDescription role={formatUserRoleType(role)} />
          ),
          type: "success",
        });
      }
    }
  }

  return (
    <SelectCompanyRole
      selectedRole={
        member.role as SphereDashboardAPITypes.IAssignmentCompanyRole
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onChange={onRoleChange}
      isTableCell={true}
      disabledRoles={[
        CoreAPITypes.EUserCompanyRole.companyManager,
        CoreAPITypes.EUserCompanyRole.projectManager,
      ]}
    />
  );
}
