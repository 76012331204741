import { Grid, Stack, Alert, FormHelperText, Box } from "@mui/material";
import { useMemo, useState } from "react";
import {
  AdminAPITypes,
  APITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { useCoreApiClient } from "src/api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { FaroButton } from "@components/common/faro-button";
import { setOneProjects } from "@store/projects/projects-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { DASH } from "@utils/ui-utils";
import { Convert } from "@stellar/web-core";
import { LabelWithHelp } from "@components/common/label-with-help";
import { FaroSelect } from "@components/common/faro-select/faro-select";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { validateNumber } from "@utils/math-utils";
import { STACK_ERROR_SX } from "@styles/common-styles";
import {
  MemberAutocomplete,
  SelectedMember,
} from "@components/common/member-autocomplete/member-autocomplete";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { sphereColors } from "@styles/common-colors";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { GroupAutoComplete } from "@components/common/group-autocomplete/group-autocomplete";
import { useGroups } from "@hooks/use-groups";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { formatAreaUnit } from "@utils/data-display";
import { convertProjectTypesToSdbProject } from "@store/projects/projects-slice-utils";
import { selectedCompanyAvailableFeatureSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppNavigation } from "@hooks/use-app-navigation";

interface Errors {
  name: string | null;
  address: string | null;
  selectedGroupId: string | null;
  selectedProjectManagerIdentity: string | null;
  area: string | null;
  clientName: string | null;
  projectDescription: string | null;
}

const DEFAULT_ERRORS: Errors = {
  name: null,
  address: null,
  selectedGroupId: null,
  selectedProjectManagerIdentity: null,
  area: null,
  clientName: null,
  projectDescription: null,
};

/** Defines the minimum area in sqft that we allow for the project area. */
const MINIMUM_PROJECT_AREA = 1;

/** Renders the create project button and the functionality in projects page */
export function CreateProjectDialog(): JSX.Element | null {
  const [selectedProjectManager, setSelectedProjectManager] =
    useState<SelectedMember>(null);
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();

  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const companyGroups = useGroups();
  const { trackEvent } = useTrackEvent();
  const { navigateToProjectDetail } = useAppNavigation();

  const hasCreateProjectsFeature = useAppSelector(
    selectedCompanyAvailableFeatureSelector(
      APITypes.EUserSubscriptionRole.projectCreate
    )
  );

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const lastPage = 1;
  const [groupMembers, setGroupMembers] = useState<
    SphereDashboardAPITypes.IGroupMemberDetails[]
  >([]);
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [selectedGroupId, setSelectedGroupId] = useState<string>("");
  /** Stores the raw input of the area before validation and parsing. */
  const [area, setArea] = useState<string>("");
  /** Stores the error message of the area if there is one, null otherwise. */
  const [areaErrorMessage, setAreaErrorMessage] = useState<string | null>(null);
  /** Stores the parsed version of the area, only updates when valid values are input */
  const [areaParsed, setAreaParsed] = useState<number>(MINIMUM_PROJECT_AREA);
  const [areaUnit, setAreaUnit] = useState<AdminAPITypes.EAreaUnit>(
    AdminAPITypes.EAreaUnit.sqft
  );
  const [clientName, setClientName] = useState<string>("");
  const [projectDescription, setProjectDescription] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  /** Gets the identity of the selected project manager */
  const selectedProjectManagerIdentity: string | null = useMemo(() => {
    if (!selectedProjectManager) {
      return null;
    }
    if (typeof selectedProjectManager === "string") {
      return selectedProjectManager;
    }
    return selectedProjectManager?.identity;
  }, [selectedProjectManager]);

  const [errors, setErrors] = useState<Errors>(DEFAULT_ERRORS);

  /** Evaluates whether the project area text field has a valid input. */
  const isAreaValid = useMemo(() => {
    return areaErrorMessage === null;
  }, [areaErrorMessage]);

  /**
   * Evaluates whether the confirm button should be disabled because there are errors in the dialog.
   */
  const isConfirmDisabled: boolean = useMemo(() => {
    // The first page is never disabled because the fields are validated
    // when the user clicks on next.
    if (page === 0) {
      return false;
    }
    return !isAreaValid;
  }, [page, isAreaValid]);

  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function onOpenDialog(): Promise<void> {
    trackEvent({ name: ProjectEvents.startCreateNewProject });

    setName("");
    setAddress("");
    setSelectedGroupId("");
    setSelectedProjectManager(null);
    setClientName("");
    setArea("");
    setAreaErrorMessage(null);
    setAreaParsed(MINIMUM_PROJECT_AREA);
    setAreaUnit(AdminAPITypes.EAreaUnit.sqft);
    setProjectDescription("");
    setPage(0);
    setErrorMessage(null);
    setErrors(DEFAULT_ERRORS);
    setIsDialogOpen(true);
  }

  /**
   * Triggered every time the user inputs text in the project area
   * text fields. It sets the raw value of the area that is displayed on the text field
   * but it also stores the parsed value if it is valid or the error message if not.
   */
  function onAreaChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const changedArea = event.target.value;
    setArea(changedArea);
    const validatedArea = validateNumber({
      attrName: "Area",
      // Since area is optional we can allow the user to leave the field empty
      // and in that case we use the minimum value for the area.
      // what we don't allow is setting the value to 0.
      value: changedArea === "" ? 1 : changedArea,
      minOrEqual: 1,
      shouldAllowDecimal: false,
    });
    if (validatedArea.isValid) {
      setAreaErrorMessage(null);
      setAreaParsed(validatedArea.parsedNumber);
    } else {
      setAreaErrorMessage(validatedArea.errorMessage);
      setAreaParsed(MINIMUM_PROJECT_AREA);
    }
  }

  /**
   * Triggered every time a group is selected.
   * It fetches the members that belong to that group and stores them
   * in the members variable.
   *
   * @param groupId Id of the group to fetch its members from.
   * @returns The fetched members for the provided group, otherwise undefined.
   */
  async function onGroupSelected(
    groupId: string | number | null
  ): Promise<SphereDashboardAPITypes.IGroupMemberDetails[] | undefined> {
    if (!companyId) {
      return undefined;
    }

    if (!groupId) {
      setSelectedGroupId("");
      setSelectedProjectManager(null);

      return;
    }

    setSelectedGroupId(groupId.toString());
    // As soon as you select a different group, empty the members array.
    // TODO: This should be moved to the group slice as createAsyncThunk: https://faro01.atlassian.net/browse/ST-698
    setGroupMembers([]);
    try {
      const { members } = await coreApiClient.V3.SDB.getGroupById(
        companyId,
        groupId
      );
      setGroupMembers(members ?? []);
      // If we change groups we don't want to have the project manager already selected.
      setSelectedProjectManager(null);
      return members;
    } catch (error) {
      handleErrorWithToast({
        id: `getGroupById-${Date.now().toString()}`,
        title: "Cannot get the group details",
        error,
      });
    }
  }

  function setSingleError(
    errorKey: keyof typeof errors,
    errorValue: string | null
  ): void {
    setErrors((current) => {
      return {
        ...current,
        [errorKey]: errorValue,
      };
    });
  }

  /**
   * poor man's validation until we have a standardized validation technique
   *
   * @param pageNumber which page to validate
   * @returns a boolean that indicates whether the form is valid or not
   */
  function validateForm(pageNumber: number): boolean {
    // first clear all current errors
    setErrors(DEFAULT_ERRORS);

    // next, validate one by one
    let hasNoErrors = true;
    if (pageNumber === 0) {
      if (!name) {
        setSingleError("name", "Project name is required!");
        hasNoErrors = false;
      }
      if (!selectedGroupId) {
        setSingleError("selectedGroupId", "Project group is required!");
        hasNoErrors = false;
      }
      if (!selectedProjectManagerIdentity) {
        setSingleError(
          "selectedProjectManagerIdentity",
          "Project manager is required!"
        );
        hasNoErrors = false;
      }
    }

    // why do we need to manually set a variable "hasNoErrors" instead of checking the errors (by looping for example)?
    // because of react's queuing system. setState is not immediate, but we need to know if there is an error
    // immediately in the function that uses this function without using useEffect
    return hasNoErrors;
  }

  /**
   * Sends a request to the backend to create a project.
   * If the promise is resolved it means that the project was successfully created,
   * otherwise an error is thrown.
   */
  async function createProject(): Promise<void> {
    if (!companyId) {
      throw new Error("Company id is required!");
    }

    // Theoretically the user should not be able to reach this state
    // if the project manager was not provided but do this for type safety
    // and as an edge case if our members selection didn't work properly.
    if (!selectedProjectManagerIdentity) {
      throw new Error("Project manager is required!");
    }

    const sqft =
      areaUnit === "sqft" ? areaParsed : Convert.sqMeterToSqFoot(areaParsed);

    const project = await coreApiClient.V3.SDB.createNewProject(companyId, {
      name,
      address: address || DASH,
      clientName,
      description: projectDescription,
      sqft,
      groupId: selectedGroupId,
      projectManagerIdentity: selectedProjectManagerIdentity,
    });

    dispatch(setOneProjects(convertProjectTypesToSdbProject(project)));
    navigateToProjectDetail({ companyId, projectId: project.id });
  }

  function trackConfirm(): void {
    if (page !== lastPage) {
      return;
    }

    const eventProps: Record<string, string | number> = {
      descriptionLength: projectDescription?.length ?? 0,
      projectNameLength: name.length,
      addressLength: address.length,
      clientNameLength: clientName.length,
      area: areaParsed ?? 0,
    };

    trackEvent({
      name: ProjectEvents.createNewProject,
      props: eventProps,
    });
  }

  /** Submit groups to be invited */
  async function handleConfirm(): Promise<void> {
    trackConfirm();

    // If the form is not valid have an early exit
    if (!validateForm(page)) {
      return;
    }

    if (page < lastPage) {
      // If we are not in the last page, the confirm button is only used to go to the next page.
      setPage(page + 1);
      return;
    }

    setIsLoading(true);

    try {
      await createProject();
      // If the project has been created the dialog can be closed.
      setIsDialogOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // If the project creation failed, do not close the dialog and
      // show an error message inside the dialog.
      setErrorMessage(error?.message ?? "An unknown error occurred!");
    } finally {
      // Regardless whether it succeeded or fail to create a project, the loading spinner
      // should be hidden and the user should be able to initiate a new request to create
      // a project or manually close the dialog
      setIsLoading(false);
    }
  }

  if (!hasCreateProjectsFeature) {
    // As double check, we should not render any button if
    // the company does not have the feature to create projects.
    return null;
  }

  return (
    <>
      {/* Button to open the dialog */}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error*/}
      <FaroButton onClick={onOpenDialog}>New project</FaroButton>

      {/* Create project dialog */}
      <FaroDialog
        title={page === 0 ? "Create new project" : "Project details"}
        open={isDialogOpen}
        confirmText={page === lastPage ? "Create" : "Next"}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        isConfirmDisabled={isConfirmDisabled}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={handleConfirm}
        isConfirmLoading={isLoading}
        onClose={() => setIsDialogOpen(false)}
        onLeftSideButtonClick={
          page > 0 && lastPage > 0 ? () => setPage(page - 1) : undefined
        }
        bottomLeftSideComponent={
          <LabelWithHelp
            title="Required fields are marked with an asterisk"
            isRequired
            sx={{
              color: sphereColors.gray700,
              fontWeight: "normal",
            }}
          />
        }
      >
        {/* First page: Required fields */}
        {page === 0 && (
          <Grid maxWidth="100%" width="70vw">
            <Stack sx={STACK_ERROR_SX}>
              <LabelWithHelp title="Project name" isRequired />
              <FaroSimpleTextField
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
                error={!!errors.name}
                helperText={errors.name}
                placeholder="Enter the project name..."
                size="small"
              />
            </Stack>
            <Stack sx={STACK_ERROR_SX}>
              <LabelWithHelp
                title="Group"
                help={
                  "Groups are created to allow for a more flexible structure and ensure " +
                  "better management of members and projects."
                }
                isRequired
              />

              <GroupAutoComplete
                companyGroups={companyGroups}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
                callbackOnGroupSelected={onGroupSelected}
              />

              {errors.selectedGroupId && (
                <FormHelperText error sx={{ ml: 2 }}>
                  {errors.selectedGroupId}
                </FormHelperText>
              )}
            </Stack>
            <Stack sx={STACK_ERROR_SX}>
              <LabelWithHelp
                title="Project manager"
                help={
                  "Choose a project manager to manage this project. This person can edit " +
                  "permissions, invite new members and also can archive, download and remove " +
                  "their related projects."
                }
                isDisabled={!selectedGroupId}
                isRequired
              />
              <MemberAutocomplete
                members={groupMembers}
                selectedMember={selectedProjectManager}
                onOptionChange={setSelectedProjectManager}
                isDisabled={!selectedGroupId}
                placeholder="Choose who can manage this project..."
              />

              {errors.selectedProjectManagerIdentity && (
                <FormHelperText error sx={{ ml: 2 }}>
                  {errors.selectedProjectManagerIdentity}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
        )}

        {/* Second page: Optional fields */}
        {page === 1 && (
          <Grid maxWidth="100%" width="70vw" marginTop="20px">
            <Stack sx={STACK_ERROR_SX}>
              <LabelWithHelp
                title="Client name"
                help="The client is the person or organization for whom this project is carried out."
              />
              <FaroSimpleTextField
                value={clientName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setClientName(event.target.value);
                }}
                placeholder="Enter the client name..."
                size="small"
                error={!!errors.clientName}
                helperText={errors.clientName}
              />
            </Stack>
            <Stack sx={STACK_ERROR_SX}>
              <LabelWithHelp title="Project location" />
              <FaroSimpleTextField
                value={address}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAddress(event.target.value);
                }}
                error={!!errors.address}
                helperText={errors.address}
                placeholder="Example: street, region, country"
                size="small"
              />
            </Stack>
            <Stack>
              <LabelWithHelp
                title="Project area"
                help={
                  "A project area is the amount of square feet/meter that is assigned " +
                  "to a project. This value must be equal to or greater than 1."
                }
              />
            </Stack>
            <Box
              component="div"
              sx={{
                ...STACK_ERROR_SX,
                display: "flex",
                alignItems: "top",
                width: "100%",
              }}
            >
              <Box
                component="div"
                sx={{
                  width: "100%",
                }}
              >
                <FaroSimpleTextField
                  value={area}
                  // Using type="number" brought some undesired behavior like not working
                  // properly on Firefox or showing the buttons to increase/decrease value
                  // that are useless in this case. Therefore we use our own validation.
                  type="text"
                  onChange={onAreaChanged}
                  placeholder="Enter project area..."
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    sx: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  error={!!errors.area || !isAreaValid}
                  helperText={errors.area || areaErrorMessage}
                  style={{ flexGrow: 1 }}
                />
              </Box>
              <Box component="div">
                <FaroSelect
                  value={areaUnit}
                  size="small"
                  variant="outlined"
                  onChange={setAreaUnit}
                >
                  <FaroMenuItem value={AdminAPITypes.EAreaUnit.sqft}>
                    {formatAreaUnit(AdminAPITypes.EAreaUnit.sqft)}
                  </FaroMenuItem>
                  <FaroMenuItem value={AdminAPITypes.EAreaUnit.sqm}>
                    {formatAreaUnit(AdminAPITypes.EAreaUnit.sqm)}
                  </FaroMenuItem>
                </FaroSelect>
              </Box>
            </Box>
            <Stack sx={STACK_ERROR_SX}>
              <LabelWithHelp title="Project description" />
              <FaroSimpleTextField
                value={projectDescription}
                multiline
                minRows={4}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setProjectDescription(event.target.value);
                }}
                placeholder="Enter project description..."
                size="small"
                error={!!errors.projectDescription}
                helperText={errors.projectDescription}
              />
            </Stack>
            {errorMessage && (
              <Stack sx={STACK_ERROR_SX}>
                <Alert severity="error">{errorMessage}</Alert>
              </Stack>
            )}
          </Grid>
        )}
      </FaroDialog>
    </>
  );
}
