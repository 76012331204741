import { useState } from "react";
import { Markup } from "@custom-types/project-markups-types";
import { FaroTextButton } from "@components/common/faro-text-button";
import { sphereColors } from "@styles/common-colors";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { BulkActionEvents, TableType } from "@utils/track-event/track-event-list";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useToast } from "@hooks/use-toast";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { DeleteMarkupDialog } from "@pages/project-details/project-markups/markup-delete-dialog";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { nounPluralize } from "@utils/data-display";

interface Props extends BaseProjectIdProps {
  /** List of markups to show in table */
    selectedMarkups: Markup[];
  }

/** Show delete markup button and dispatch action where the delete dialog will act accordingly */
export function BulkDeleteMarkups({
    selectedMarkups,
    projectId,
  }: Props): JSX.Element {

    const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const { bulkDeleteMarkupsFromProject } = useProjectMarkupUpdate({ projectId });
    const { showToast } = useToast();
    const { trackEvent } = useTrackEvent();
    const { handleErrorWithToast } = useErrorContext();
    const { markups, updateMarkups } = useMarkupContext();

    /** Delete markups in bulk */
    async function deleteMarkupsInBulk(): Promise<void> {
      trackEvent({
        name: BulkActionEvents.bulkDeleteMarkup,
        props: {
          dataType: TableType.projectMarkups,
          numberOfEntities: selectedMarkups.length,
        },
      });

      try {
        setIsDeleting(true);
        const response = await bulkDeleteMarkupsFromProject(selectedMarkups);
          if(response && response.failedMarkups.length === 0){
            showToast({
              message: "Annotations deleted.", 
              type: "success",
            });
          } else {
            showToast({
              message: `Failed to delete ${nounPluralize({
                counter: response.failedMarkups.length,
                word: "annotation",
              })}`,
              type: "error",
            });
          }
          const updatedMarkups = markups.filter(
            (markup) => !response.successMarkups.some((element) => element.elementId === markup.id)
          );
          updateMarkups(updatedMarkups);

      } catch (error) {
        handleErrorWithToast({
          id: `Delete annotation-${Date.now().toString()}`,
          title: "Failed to delete the annotations.",
          error: getErrorDisplayMarkup(error),
        });
  
      } finally {
        setIsDeleting(false);
        setShouldShowDialog(false);
      }
    }
    return (
      <>
        <FaroTextButton
          onClick={() => setShouldShowDialog(true)}
          tooltipText= "Delete annotations"
          sx={{
            fontWeight: 600,
            paddingX: "10px",
            paddingY: "8px",
            width: "60px",
            color: sphereColors.blue500,
          }}
        >
          Delete
        </FaroTextButton>

        {shouldShowDialog && (
          <DeleteMarkupDialog
            dialogContent={{
              title: "Delete annotations?",
              description: "This will remove the selected annotations from your project.",
            }}
            onClose={() => setShouldShowDialog(false)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onConfirm={deleteMarkupsInBulk}
            isDeleting={isDeleting}
          />
        )}
      </>
    );
  }
