import FaroSphereLogo from "@assets/icons/faro-sphere-xg-logo-color.svg?react";
import ErrorIcon from "@assets/icons/new/error_page.svg?react";
import { FaroButton } from "@components/common/faro-button";
import { SphereSupportLink } from "@components/common/sphere-support-link";
import { UserMenu } from "@components/header/user-menu";
import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useEffect } from "react";

/**
 * This is a custom error component which is shown if something goes wrong in routing.
 * e.g. typing a route that is not available.
 */
export function CustomErrorBoundary(): JSX.Element {
  const { trackAsyncEvent } = useTrackEvent();

  /**
   * Handles refreshing the current page by reloading the window location.
   * This function uses `window.location.reload()` to reload the current page,
   * effectively resetting its state and content.
   */
  function handleRefresh(): void {
    window.location.reload();
  }

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      trackAsyncEvent({ name: "encountered unexpected error" });
    } catch (error) {
      throw new Error(
        "Failed to track async event: encountered unexpected error."
      );
    }
  });

  return (
    <Box sx={{ background: sphereColors.gray50, minHeight: "100vh" }}>
      <Box
        component="div"
        sx={{
          pt: "14px",
          pr: "24px",
          pl: "8px",
          pb: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FaroSphereLogo />
        <UserMenu />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: "50px" }}>
          <ErrorIcon width={400} height={"auto"} />
        </Box>
        <Box
          sx={{
            mt: "37px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            maxWidth: "700px",
          }}
        >
          <Typography
            sx={{
              fontSize: "38px",
              color: sphereColors.gray600,
              fontWeight: "600",
            }}
          >
            Something went wrong
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              color: sphereColors.gray600,
              fontWeight: "400",
            }}
          >
            We encountered an unexpected issue, please refresh the page. If the
            issue persists, contact <SphereSupportLink />.
          </Typography>
        </Box>

        <FaroButton sx={{ my: "85px" }} onClick={handleRefresh}>
          Refresh
        </FaroButton>
      </Box>
    </Box>
  );
}
