import { useCoreApiClient } from "@api/use-core-api-client";
import { SphereBarChart } from "@components/common/sphere-bar-chart/sphere-bar-chart";
import { Box, Skeleton } from "@mui/material";
import { useAppParams } from "@router/router-helper";
import {
  fetchingAnalyticsFlagsSelector,
  projectChartSelector,
} from "@store/analytics/analytics-selector";
import { fetchProjectStats } from "@store/analytics/analytics-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useEffect, useMemo } from "react";
import { ProjectChartController } from "@pages/project-details/project-overview/project-chart/project-chart-controller";

/* eslint-disable @typescript-eslint/no-magic-numbers -- aspect ratio value */
/** Chart aspect ratio */
const CHART_ASPECT_RATIO = 21 / 9;
/* eslint-enable @typescript-eslint/no-magic-numbers */

interface Props {
  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** Renders an analytics chart that displays the current project stats */
export function ProjectChart({ isLoading }: Props): JSX.Element {
  const { companyId, projectId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { isFetchingProjectStats } = useAppSelector(
    fetchingAnalyticsFlagsSelector
  );
  const chartData = useAppSelector(projectChartSelector);

  const shouldShowSkeleton = useMemo(() => {
    return isLoading || isFetchingProjectStats || !chartData;
  }, [chartData, isFetchingProjectStats, isLoading]);

  // Fetches the project stats
  useEffect(() => {
    if (companyId && projectId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(fetchProjectStats({ coreApiClient, projectId, companyId }));
    }
  }, [companyId, coreApiClient, dispatch, projectId]);

  return (
    <Box>
      {shouldShowSkeleton ? (
        <Skeleton variant="rectangular" width="100%">
          <ProjectChartController />
        </Skeleton>
      ) : (
        <ProjectChartController />
      )}
      <SphereBarChart
        data={chartData}
        aspectRatio={CHART_ASPECT_RATIO}
        shouldShowSkeleton={shouldShowSkeleton}
        shouldHideTitle
      />
    </Box>
  );
}
