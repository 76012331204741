import { MemberTypes } from "@custom-types/member-types";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * Converts a project member from SphereDashboardAPITypes.IProjectMemberBase to MemberTypes.
 * Adds a default role of 'member' to ensure proper state management in the store.
 */
export function convertToMemberTypes(
  member:
    | SphereDashboardAPITypes.IProjectMemberBase
    | SphereDashboardAPITypes.IUserAsManager
): MemberTypes {
  const convertedMember: MemberTypes = {
    identity: member.identity,
    email: member.email,
    role: CoreAPITypes.EUserCompanyRole.member,
  };

  return convertedMember;
}
