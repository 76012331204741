import {
  Alert,
  IconContainerProps,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { FeedbackModalEvents } from "@utils/track-event/track-event-list";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { sphereColors } from "@styles/common-colors";
import {
  StyledRating,
  Textarea,
} from "@pages/project-details/project-markups/project-markups-send-feedback-styled";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroTextButton } from "@components/common/faro-text-button";
import InfoIcon from "@mui/icons-material/Info";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useFeedback } from "@hooks/feedback/use-feedback";

/**
 * IconContainer is a functional component responsible for rendering an icon based on the provided value.
 *
 * @param {IconContainerProps} props - The props object containing the value to determine the icon.
 * @returns {JSX.Element} - Returns a span element containing the corresponding icon based on the value.
 */
function IconContainer(props: IconContainerProps): JSX.Element {
  const { value, ...other } = props;

  return <span {...other}>{value}</span>;
}

interface Props {
  /** Whether the component skeleton animation should be shown */
  shouldShowSkeleton?: boolean;
}

/**
 * Contains the dialog plus activator to send feedback about the annotations.
 *
 * @returns {JSX.Element}
 */
export function ProjectMarkupsSendFeedback({
  shouldShowSkeleton = false,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { sendFeedback } = useFeedback();

  const feedbackRef = useRef<HTMLTextAreaElement>(null);

  /** Whether the sending a request hence the submit button should be shown as loading */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Whether the alert is visible */
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true);

  /** Whether the dialog is open */
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  /** Stores the selected rating */
  const [rating, setRating] = useState<number>(0);

  /** Store the textarea rating */
  const [textarea, setTextarea] = useState<string>("");

  /** Opens the dialog */
  function openDialog(): void {
    trackEvent({ name: FeedbackModalEvents.openFeedbackDialogAnnotation });

    setIsDialogOpen(true);
  }

  /** Cleans up the state and closes the dialog */
  function onClose(): void {
    setIsDialogOpen(false);
    setRating(0);
    setTextarea("");
  }

  /** Submits the feedback for the annotations */
  async function onConfirm(): Promise<void> {
    setIsLoading(true);
    await sendFeedback({
      featureName: "Annotations",
      feedbackMessage: textarea,
      selectedRating: rating,
    });
    onClose();
    setIsLoading(false);
    setIsAlertVisible(false);
  }

  /**
   * This function handles changes in a textarea.
   *
   * @param {ChangeEvent<HTMLTextAreaElement>} event - The event object containing information
   * about the textarea change.
   *
   * @returns {void}
   */
  function handleChangeTextArea(event: ChangeEvent<HTMLTextAreaElement>): void {
    const { value } = event.target;

    setTextarea(value);
  }

  useEffect(() => {
    if (rating > 0) {
      if (feedbackRef.current) {
        feedbackRef.current.focus();
      }
    }
  }, [rating]);

  if (shouldShowSkeleton) {
    return (
      <Skeleton
        variant={"rectangular"}
        sx={{ width: "100%", height: "60px" }}
      />
    );
  }

  return (
    <Box component={"div"} data-testid="project-markups-send-feedback-alert">
      {isAlertVisible && (
        <>
          <Alert
            icon={false}
            severity="info"
            action={
              <>
                <FaroTextButton
                  size="large"
                  onClick={openDialog}
                  dataTestId="project-markups-send-feedback-button"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginRight: "16px",
                    "&:focus": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Give feedback
                </FaroTextButton>
                <FaroIconButton
                  onClick={() => setIsAlertVisible(false)}
                  component={CloseIcon}
                  buttonSize={"42px"}
                />
              </>
            }
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: sphereColors.blue50,
              border: `1px solid ${sphereColors.gray200}`,
            }}
            data-testid="project-markups-send-feedback-message"
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <InfoIcon
                sx={{ color: sphereColors.blue500, fontSize: "20px" }}
              />
              <Typography
                component={"span"}
                sx={{
                  fontSize: "14px",
                  color: sphereColors.gray800,
                }}
              >
                <strong>New feature in the works!</strong> Your quick feedback
                can shape its future.
              </Typography>
            </Box>
          </Alert>

          <FaroDialog
            title="How do you like the new Annotations tab?"
            open={isDialogOpen}
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onConfirm={onConfirm}
            isConfirmLoading={isLoading}
            isConfirmDisabled={rating ? false : true}
            customClasses={{
              container: "dialog-container-annotations",
            }}
            confirmText="Submit"
            isHideBackdrop={true}
            sx={{
              position: "absolute",
              right: "0",
              bottom: "0",
              top: "initial",
              left: "initial",
            }}
          >
            <StyledRating
              classes={{ root: "rating-annotations" }}
              name="annotations-feedback"
              IconContainerComponent={IconContainer}
              onChange={(_, newValue) => setRating(newValue ?? 0)}
              value={rating}
              highlightSelectedOnly
              data-testid="project-markups-send-feedback-rating"
            />
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: sphereColors.gray600,
                }}
                data-testid="project-markups-send-feedback-first-text"
              >
                Not helpful
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: sphereColors.gray600,
                }}
                data-testid="project-markups-send-feedback-second-text"
              >
                Very helpful
              </Typography>
            </Box>
            {rating > 0 && (
              <Box component={"div"} sx={{ marginTop: "47px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: sphereColors.gray800,
                  }}
                  data-testid="project-markups-send-feedback-textarea-message"
                >
                  Want to tell us more?
                </Typography>
                <Textarea
                  minRows={4}
                  placeholder="Enter..."
                  data-testid="project-markups-send-feedback-textarea"
                  onChange={handleChangeTextArea}
                  defaultValue={textarea}
                  ref={feedbackRef}
                  sx={{
                    color: sphereColors.gray800,
                  }}
                />
              </Box>
            )}
          </FaroDialog>
        </>
      )}
    </Box>
  );
}
