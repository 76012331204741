import { WorkspaceSpecificBannerProps } from "@components/banners/workspace-specific-banner/workspace-specific-banner-types";

/** Workspace specific banners */
export const WORKSPACE_SPECIFIC_BANNERS: WorkspaceSpecificBannerProps[] = [
  {
    text: "Attention, payment overdue. Please contact administrator to avoid service interruption.",
    variant: "error",
    ids: [
      // Past here the IDs of workspaces that need to see the payment overdue banner
    ],
  },
  {
    text: "Test banner",
    variant: "error",
    ids: [
      "67e9c632-cd66-4b1e-b592-2d03ff670283",
    ],
    deactivationDate: "2024-12-18T12:00:00.000Z",
  },
];
