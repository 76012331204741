import { FaroTextButton } from "@components/common/faro-text-button";
import { ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { APITypes } from "@stellar/api-logic";
import { getSphereViewerUrl, OPEN_OTHERS_TARGET_ATTRIBUTE, trackOpenProject } from "@utils/project-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Project ID. */
  projectId: APITypes.ProjectId;
}

export function OpenViewerButton({ projectId }: Props): JSX.Element {
  const { trackAsyncEvent } = useTrackEvent();

  const sphereViewerUrl = getSphereViewerUrl({ projectId }).href;

  return (
    <FaroTextButton
      size="small"
      dataTestId="sa-open-button"
      tooltipText="View the published data."
      to={sphereViewerUrl}
      target={OPEN_OTHERS_TARGET_ATTRIBUTE}
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
        trackOpenProject({
          openFrom: "projectDetails-dataManagement",
          numberOfMembers: undefined,
          openTarget: ProjectLaunchTarget.sphereViewer,
          trackAsyncEvent,
        });
      }}
    >
      Open
    </FaroTextButton>
  );
}
