import { useState } from "react";
import { Box } from "@mui/material";
import { FaroPopover } from "@faro-lotv/flat-ui";

interface Props {
  /**
   * The element that is the anchor of the popover.
   * Can be a button, icon, some text, etc.
   */
  children: JSX.Element | string;

  /** If the popover is enabled or not */
  isEnabled?: boolean;

  /** If the popover action button should be disabled during loading */
  isLoading?: boolean;

  /** Title of the popover */
  title: string;

  /** Content of the popover */
  description: string;

  /** Action button title */
  actionTitle: string;

  /** Function to execute when the button is clicked */
  action: () => void;
}

/** Notification popover that shows task message */
export function FaroPopoverAction({
  children,
  isEnabled = true,
  isLoading = false,
  title,
  description,
  actionTitle,
  action,
}: Props): JSX.Element {
  /** HTML element used to set the position of the menu. */
  const [notificationAnchorElement, setNotificationAnchorElement] =
    useState<null | HTMLElement>(null);

  const isMenuOpen = isEnabled && Boolean(notificationAnchorElement);

  /** Triggered when there's a mouse event on the child component to open the notification */
  function handleShow(event: React.MouseEvent<HTMLElement>): void {
    setNotificationAnchorElement(event.currentTarget);
  }

  /** Closes the notification */
  function handleClose(): void {
    setNotificationAnchorElement(null);
  }

  /** Executes the action */
  async function handleAction(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/await-thenable -- Please review lint error
    await action();
    handleClose();
  }

  return (
    <>
      <Box
        onClick={handleShow}
        onMouseOver={handleShow}
      >
        {children}
      </Box>
      <FaroPopover
        dark
        open={isMenuOpen}
        anchorEl={notificationAnchorElement}
        onClose={handleClose}
        closeOnClickOutside={false}
        title={title}
        description={description}
        actions={[
          {
            children: actionTitle,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onClick: handleAction,
            isLoading,
            sx: { textTransform: "none" },
          },
        ]}
        placement="bottom"
      />
    </>
  );
}
