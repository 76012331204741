import { SdbProject } from "@custom-types/project-types";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  defaultProjectThumbnail,
  getTrackProjectDateProps,
  openProjectInViewer,
  trackShareProject,
} from "@utils/project-utils";
import { DemoTag } from "@src/components/common/demo-tag";
import { EmptyPage } from "@src/components/common/empty-page/empty-page";
import ProjectsDuoColorIcon from "@assets/icons/projects-duo-color.svg?react";
import { sphereColors } from "@styles/common-colors";
import { FaroTextButton } from "@components/common/faro-text-button";
import {
  SHARE_PROJECT_DIALOG_PROVIDER_PROPS,
  ShareProject,
} from "@components/project/share-project";
import { useDialog } from "@components/common/dialog/dialog-provider";
import ShareIcon from "@assets/icons/Share.svg?react";
import OpenViewerIcon from "@assets/icons/new/open-in-viewer.svg?react";
import { PageContainer } from "@components/common/page-container";
import { ExternalLinks } from "@components/external-links";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { BaseCompanyIdProps, ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { userMenuLink, withEllipsisTwoLines } from "@styles/common-styles";
import { useMediaQueryList } from "@hooks/use-media-query";
import { CreateProjectDialog } from "@pages/projects/create-project-dialog";
import { useMemo } from "react";
import { generateProjectDetailsRoute } from "@router/router-helper";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { ProjectTabs } from "@router/route-params";
import { Link } from "react-router-dom";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { APITypes } from "@stellar/api-logic";
import { selectedCompanyAvailableFeatureSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";

interface Props extends BaseCompanyIdProps {
  /** The demo project */
  demoProject: SdbProject;
}

/** Show this specific empty page when workspace has only the demo project (as active) */
export function ProjectsOverviewDemoEmptyPage({
  demoProject,
  companyId,
}: Props): JSX.Element {
  const { navigateToProjectDetail } = useAppNavigation();
  const { createDialog } = useDialog();
  const { trackEvent, trackAsyncEvent } = useTrackEvent();
  const { isScreenMdAndLarger } = useMediaQueryList();
  const { canCreateProjects } = useHasUserValidRoleCompanyLevel();
  const hasCreateProjectsFeature = useAppSelector(
    selectedCompanyAvailableFeatureSelector(
      APITypes.EUserSubscriptionRole.projectCreate
    )
  );

  /** Flag whether the user has the permission to create projects and the subscription has the feature enabled */
  const shouldCreateProjects = useMemo(() => {
    return canCreateProjects && hasCreateProjectsFeature;
  }, [canCreateProjects, hasCreateProjectsFeature]);

  /** Stores the link to open the details page of the demo project */
  const demoProjectLink = useMemo(() => {
    return (
      <Box
        component={Link}
        to={generateProjectDetailsRoute(
          companyId,
          demoProject.id,
          ProjectTabs.overview
        )}
        sx={{
          ...userMenuLink,
          color: sphereColors.blue500,
        }}
        onClick={() => {
          trackEvent({
            name: ProjectEvents.openProjectOverview,
            props: {
              origin: "Empty projects page",
              type: "demo",
              projectId: demoProject.id,
              ...getTrackProjectDateProps(demoProject),
            },
          });
        }}
      >
        demo project
      </Box>
    );
  }, [companyId, demoProject, trackEvent]);

  return (
    <PageContainer
      title="Active Projects"
      description={
        <Typography
          sx={{
            color: sphereColors.gray600,
            fontSize: "12px",
          }}
        >
          Create, store and manage projects in your workspace. Projects can be
          organized into groups. <ExternalLinks link={"createNewProjectHelp"} />
        </Typography>
      }
    >
      <Stack
        sx={{
          width: "100%",
          mt: "15px",
          py: "10px",
          px: "20px",
          background: sphereColors.gray50,
          "&:hover": {
            background: sphereColors.gray100,
            "& .actionButtons": { visibility: "visible" },
          },
          cursor: "pointer",
        }}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", md: "center" }}
        onClick={() =>
          navigateToProjectDetail({ companyId, projectId: demoProject.id })
        }
      >
        <Stack direction="row">
          <Box
            component="img"
            src={demoProject.thumbnailUrl ?? defaultProjectThumbnail}
            sx={{
              maxHeight: "50px",
              maxWidth: "70px",
              objectFit: "contain",
              mx: "20px",
            }}
            alt="Project thumbnail"
          />

          <Box component="div">
            <Typography
              sx={{
                color: sphereColors.gray800,
                fontSize: "12px",
                fontWeight: "bold",
                mb: "7px",
              }}
            >
              Demo
            </Typography>
            <Typography
              sx={{
                color: sphereColors.gray600,
                fontSize: "12px",
                ...withEllipsisTwoLines,
              }}
            >
              Customise and explore our demo project
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            width: !isScreenMdAndLarger ? "100%" : undefined,
            display: !isScreenMdAndLarger ? "flex" : undefined,
            justifyContent: !isScreenMdAndLarger ? "space-between" : undefined,
            marginTop: !isScreenMdAndLarger ? "5px" : undefined,
          }}
        >
          <Stack
            className="actionButtons"
            direction="row"
            alignItems="center"
            sx={{ visibility: isScreenMdAndLarger ? "hidden" : "visible" }}
          >
            {/* Shows a button to open the project, for all empty workspaces it will always use the Sphere Viewer. */}
            <FaroTextButton
              startIcon={
                <OpenViewerIcon style={{ width: "20px", height: "20px" }} />
              }
              hasOnlyIcon={!isScreenMdAndLarger}
              onClick={(event) => {
                // We need to preventDefault and stopPropagation to avoid having the default
                // click behavior for the card of opening project details.
                event.preventDefault();
                event.stopPropagation();

                const numberOfMembers = demoProject.members.length || undefined;
                
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
                openProjectInViewer({
                  project: demoProject,
                  openFrom: "demo-project-empty-page",
                  numberOfMembers,
                  trackAsyncEvent,
                });
              }}
              sx={{ color: sphereColors.gray800 }}
            >
              {isScreenMdAndLarger ? "Open Project" : ""}
            </FaroTextButton>
            <Divider
              orientation="vertical"
              sx={{
                height: "20px",
                color: sphereColors.gray800,
                marginX: "16px",
              }}
            />

            {/* Shows a share button that always uses the Sphere Viewer for empty workspaces. */}
            <FaroTextButton
              startIcon={
                <ShareIcon style={{ width: "20px", height: "20px" }} />
              }
              hasOnlyIcon={!isScreenMdAndLarger}
              onClick={(event) => {
                // We need to preventDefault and stopPropagation to avoid having the default
                // click behavior for the card of opening project details.
                event.preventDefault();
                event.stopPropagation();
                trackShareProject({
                  ...demoProject,
                  eventLocation: "no projects page",
                  trackEvent,
                });
                
                // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
                createDialog(
                  SHARE_PROJECT_DIALOG_PROVIDER_PROPS,
                  <ShareProject
                    projectId={demoProject.id}
                    defaultOpenProjectTarget={ProjectLaunchTarget.sphereViewer}
                  />
                );
              }}
              sx={{ color: sphereColors.gray800, mr: "45px" }}
            >
              {isScreenMdAndLarger ? "Share Project" : ""}
            </FaroTextButton>
          </Stack>
          <DemoTag sx={{ mr: isScreenMdAndLarger ? "20px" : "0px" }} />
        </Stack>
      </Stack>
      <EmptyPage
        title={"You don't have any project yet"}
        subtitle={
          shouldCreateProjects ? (
            <>
              You haven't added projects to your workspace. Select the "new
              project" button to get started.
            </>
          ) : (
            <>
              You currently don't have any projects. To get started, explore and
              try out our {demoProjectLink}.
            </>
          )
        }
        icon={ProjectsDuoColorIcon}
        button={shouldCreateProjects && <CreateProjectDialog />}
      />
    </PageContainer>
  );
}
