import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { ProjectSettings } from "@custom-types/project-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  FetchAllProjectProps,
  FetchProjectsResponse,
  FetchUserProjectsProps,
  RemoveMemberProps,
  RemoveMemberResult,
  SearchedProjectsProps,
  SearchedProjectsResult,
  UpdateProjectDetailsProps,
  EditProjectFeatureProps,
  EditProjectFeatureResult,
  UpdateMemberRoleInProjectProps,
  UpdateMemberRoleInProject,
  GetProjectFeaturesResult,
  SdbSearchedProject,
  UpdateProjectSettingsProps,
} from "@store/projects/projects-slice-types";
import { RootState } from "@store/store-helper";
import {
  BaseProjectApiClientProps,
  CoreApiWithCompanyIdProjectIdProps,
  CoreApiWithProjectIdProps,
} from "@store/store-types";
import {
  isProjectDemo,
  projectStateToApiArchivingState,
} from "@utils/project-utils";
import {
  API_PROJECTS_PER_REQUEST,
  convertFeaturesAvailableToFeatures,
  updateMemberRoleInProjectBase,
} from "@store/projects/projects-slice-utils";
import { createMutationSetElementMetaData } from "@faro-lotv/service-wires";
import { SlideContainerMarkupSettings } from "@faro-lotv/ielement-types";

// TODO: No need to pass companyId as createAsyncThunk can access the store: https://faro01.atlassian.net/browse/HBD-253
/**
 * Fetch projects based on the archiving state of a company
 * Or all projects if archiving state is not passed
 */
export const fetchProjects = createAsyncThunk<
  FetchProjectsResponse,
  FetchAllProjectProps
>(
  "projects/fetchProjects",
  async ({ coreApiClient, companyId, projectArchivingState, next }) => {
    try {
      // Returns projects that match the archiving state with new routes.
      // The response is also paginated to include a maximum of API_PROJECTS_PER_REQUEST projects per request.
      // To get more projects, the next property should be passed as the start property in the next request.
      const paginatedProjects = await coreApiClient.V3.SDB.getProjectsByKeyword(
        {
          companyId,
          archivingStates: projectArchivingState
            ? projectStateToApiArchivingState(projectArchivingState)
            : [
                APITypes.ArchivingState.UNARCHIVED,
                APITypes.ArchivingState.ARCHIVED,
                APITypes.ArchivingState.ARCHIVED_READY_TO_DOWNLOAD,
                APITypes.ArchivingState.ARCHIVED_DOWNLOADED,
              ],
          start: next,
        }
      );

      let newNext = null;
      // The part where we compare the length of the response with the number of projects per request,
      // it is a quick fix to the bug in the backend where it returns the next property even if there
      // are no more projects to be fetched
      // TODO Remove once https://faro01.atlassian.net/browse/HBCORE-410 is implemented
      if (
        paginatedProjects.next &&
        paginatedProjects.data.length === API_PROJECTS_PER_REQUEST
      ) {
        newNext = paginatedProjects.next;
      }

      const projects = paginatedProjects.data.map((response) => {
        return {
          ...response.project,
          features: convertFeaturesAvailableToFeatures(
            response.featuresAvailable
          ),
          featuresAvailable: response.featuresAvailable,
          managers: {
            projectManager: response.context.projectManager,
          },
          context: response.context,
        };
      });

      return {
        projects,
        next: newNext,
        projectArchivingState,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetch project detail by providing projectId from the backend */
export const fetchProjectDetails = createAsyncThunk<
  SphereDashboardAPITypes.IProjectDetails,
  CoreApiWithCompanyIdProjectIdProps
>(
  "projects/fetchProjectDetails",
  async ({ coreApiClient, companyId, projectId }) => {
    try {
      const fetchedProjectDetails =
        await coreApiClient.V3.SDB.getProjectDetails(companyId, projectId);

      return fetchedProjectDetails;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Update project details */
export const updateProjectDetails = createAsyncThunk<
  SphereDashboardAPITypes.IProjectDetails,
  UpdateProjectDetailsProps,
  {
    state: RootState;
  }
>(
  "projects/updateProjectDetails",
  async ({ coreApiClient, projectId, payload }, { getState }) => {
    const {
      projects,
      projects: { selectedProject },
      sdbCompany: { selectedSdbCompanyId },
    } = getState();

    /**
     * The ID of the project that is going to be updated.
     * If projectId is not provided, the selectedProjectId from store is selected
     */
    const updatingProjectId = projectId || selectedProject.id;

    if (!selectedSdbCompanyId || !updatingProjectId) {
      throw new Error(
        "No companyId or projectId exist to was given to updateProjectDetails"
      );
    }

    if (
      selectedProject.id &&
      isProjectDemo(projects.entities[selectedProject.id])
    ) {
      throw new Error("Demo project can't be edited");
    }

    try {
      const updatedProject = await coreApiClient.V3.SDB.updateProjectDetails(
        selectedSdbCompanyId,
        updatingProjectId,
        payload
      );

      return updatedProject;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches searched projects from the backend so they can be put into the store */
export const fetchSearchedProjects = createAsyncThunk<
  SearchedProjectsResult,
  SearchedProjectsProps
>(
  "projects/fetchSearchedProjects",
  async ({ projectArchivingState, coreApiClient, companyId, searchText }) => {
    if (!companyId) {
      throw new Error("No companyId was given to search for projects");
    }

    if (!searchText) {
      return {
        searchedProjects: [],
        projectArchivingState,
      };
    }

    try {
      const unwrappedResponse = await coreApiClient.V3.SDB.getProjectsByKeyword(
        {
          search: searchText,
          companyId,
          archivingStates: projectArchivingState
            ? projectStateToApiArchivingState(projectArchivingState)
            : undefined,
        }
      );

      const filteredProjects = unwrappedResponse.data.map((response) => {
        return {
          ...response.project,
          features: convertFeaturesAvailableToFeatures(
            response.featuresAvailable
          ),
          featuresAvailable: response.featuresAvailable,
          managers: {
            projectManager: response.context.projectManager,
          },
          context: response.context,
        };
      });

      return {
        searchedProjects: filteredProjects,
        projectArchivingState,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches projects that the user has access to */
export const fetchUserProjects = createAsyncThunk<
  SdbSearchedProject[],
  FetchUserProjectsProps
>(
  "projects/fetchUserProjects",
  async ({
    coreApiClient,
    companyId,
    search,
    groupId,
    projectArchivingState,
    start,
  }) => {
    try {
      const unwrappedResponse = await coreApiClient.V3.SDB.getProjectsByKeyword(
        {
          search,
          companyId,
          groupId,
          archivingStates:
            projectArchivingState &&
            projectStateToApiArchivingState(projectArchivingState),
          start,
        }
      );

      const projects = unwrappedResponse.data.map((response) => {
        return {
          ...response.project,
          features: convertFeaturesAvailableToFeatures(
            response.featuresAvailable
          ),
          featuresAvailable: response.featuresAvailable,
          managers: {
            projectManager: response.context.projectManager,
          },
          context: response.context,
        };
      });

      return projects;
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Remove a member from the project using the backend,
 * and then removes it from project in the store as well
 */
export const removeMemberFromProject = createAsyncThunk<
  RemoveMemberResult,
  RemoveMemberProps
>(
  "projects/removeMemberFromProject",
  async ({ coreApiClient, companyId, projectId, member }) => {
    if (!companyId) {
      throw new Error("No companyId was given to remove member from project!");
    }

    try {
      await coreApiClient.V3.removeMemberFromProject(
        companyId,
        projectId,
        member.identity
      );
      return {
        projectId,
        member,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Send request to backend to edit project feature,
 * and then update it in the store as well
 */
export const editProjectFeature = createAsyncThunk<
  EditProjectFeatureResult,
  EditProjectFeatureProps
>(
  "projects/editProjectFeature",
  async ({ coreApiClient, companyId, selectedProject, payload }) => {
    if (!selectedProject) {
      throw new Error("No project was given to edit project feature!");
    }

    try {
      const newFeatures = await coreApiClient.V3.SDB.setProjectFeatures(
        companyId,
        selectedProject.id,
        payload
      );

      return { newFeatures, selectedProject };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

// TODO: Update the return type: https://faro01.atlassian.net/browse/ST-1734
/**
 * Fetch project context for a project from backend,
 * and then update it in the store as well
 */
export const fetchProjectContext = createAsyncThunk<
  SphereDashboardAPITypes.IProjectContextResponse,
  CoreApiWithProjectIdProps
>("projects/fetchProjectContext", async ({ coreApiClient, projectId }) => {
  if (!projectId) {
    throw new Error("No projectId was given to fetch project context!");
  }

  try {
    const projectContext = await coreApiClient.V3.SDB.getProjectContext(
      projectId
    );

    return projectContext;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/** Delete Project */
export const deleteProject = createAsyncThunk<
  APITypes.ProjectId,
  CoreApiWithCompanyIdProjectIdProps
>("projects/deleteProject", async ({ coreApiClient, companyId, projectId }) => {
  try {
    await coreApiClient.V3.SDB.deleteProject({ companyId, projectId });
    return projectId;
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/**
 * Update a member role in the project using the backend,
 * and then update it in project in the store as well.
 * Intended to use when updating a single member role,
 * since the error will be automatically handled by the error slice.
 */
export const updateMemberRoleInProjectSingle = createAsyncThunk<
  UpdateMemberRoleInProject,
  UpdateMemberRoleInProjectProps
>("projects/updateMemberRoleInProjectSingle", updateMemberRoleInProjectBase);

/**
 * Update a member role in the project using the backend,
 * and then update it in project in the store as well.
 * Intended to use when updating a member roles in bulk,
 * since the error won't be automatically handled by the error slice.
 */
export const updateMemberRoleInProjectBulk = createAsyncThunk<
  UpdateMemberRoleInProject,
  UpdateMemberRoleInProjectProps
>("projects/updateMemberRoleInProjectBulk", updateMemberRoleInProjectBase);

/** Send request to receive list of project feature and then update it in the store as well */
export const getProjectFeatures = createAsyncThunk<
  GetProjectFeaturesResult,
  CoreApiWithCompanyIdProjectIdProps
>(
  "projects/getProjectFeatures",
  async ({ coreApiClient, companyId, projectId }) => {
    try {
      const features = await coreApiClient.V3.SDB.getProjectFeatures(
        companyId,
        projectId
      );

      return { projectId, features };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/**
 * Fetch project settings for a project from the Project API backend.
 * The settings are included in the "metaDataMap" property of root ielement of the project.
 */
export const fetchProjectSettings = createAsyncThunk<
  ProjectSettings,
  BaseProjectApiClientProps
>("projects/fetchProjectSettings", async ({ projectApiClient }) => {
  try {
    const rootElement = await projectApiClient.getRootIElement();

    const shouldDisplayMarkupsForViewers =
      rootElement.metaDataMap?.slideContainerMarkupSettings?.displayForViewers;

    return {
      shouldDisplayMarkupsForViewers,
    };
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});

/**
 * Updates the project settings for the current project
 *
 * @returns the update project settings
 * @throws {Error} if it fails to update the settings
 */
export const updateProjectSettings = createAsyncThunk<
  ProjectSettings,
  UpdateProjectSettingsProps
>("projects/updateProjectSettings", async ({ projectApiClient, payload }) => {
  try {
    // Fetch the root IElement of the project. The ID of the root is required to update the settings
    const { id } = await projectApiClient.getRootIElement();

    const setting: SlideContainerMarkupSettings = {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by Project Api backend
      displayForViewers: payload.shouldDisplayMarkupsForViewers,
    };
    const mutation = createMutationSetElementMetaData(id, [
      {
        key: "slideContainerMarkupSettings",
        value: setting,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by Project Api backend
        skipIfPresent: false,
      },
    ]);

    // Update "displayForViewers" value of the root IElement
    await projectApiClient.applyMutations([mutation]);

    // Fetch the root IElement again to get the updated setting value
    const root = await projectApiClient.getRootIElement();

    const shouldDisplayMarkupsForViewers =
      root.metaDataMap?.slideContainerMarkupSettings?.displayForViewers;
    return {
      shouldDisplayMarkupsForViewers,
    };
  } catch (error) {
    throw new Error(getErrorDisplayMarkup(error));
  }
});
