import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { useToast } from "@hooks/use-toast";
import { CoreAPITypes } from "@stellar/api-logic";
import AddMemberIcon from "@assets/icons/new/add-member_18px.svg?react";
import {
  fetchingSnapshotsFlagsSelector,
  snapshotsMembersSelector,
} from "@store/snapshots/snapshots-selector";
import { SnapshotActionButton } from "@pages/project-details/project-snapshots/snapshot-action-button";
import {
  fetchSnapshotMembers,
  inviteMembersToSnapshot,
} from "@store/snapshots/snapshots-slice";
import { useAppParams } from "@router/router-helper";
import { fetchCompanyMembers } from "@store/members/members-slice";
import { companyMembersSelector } from "@store/members/members-selector";
import { MembersAutocomplete } from "@components/common/members-autocomplete/members-autocomplete";
import { createMemberOption } from "@components/common/members-autocomplete/members-autocomplete-utils";
import { AutoCompleteMemberOption } from "@components/common/members-autocomplete/members-autocomplete-types";
import { isValidEmail } from "@utils/member-utils";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { Grid, Stack } from "@mui/material";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

interface Props {
  /** The snapshot to invite members to */
  snapshot: CoreAPITypes.IProjectSnapshot;

  /** Whether the snapshot is opened in mobile version, and the button is collapsed in a menu. */
  isMobileVersion?: boolean;
}

/**
 * The title of the button to open the dialog, used either as a label in
 * mobile devices or tooltip on larger devices.
 */
const BUTTON_TITLE = "Invite members";

/**
 * Component to invite members to a snapshot.
 * It shows an activator that can be either an icon button in large devices,
 * or a menu item in mobile devices.
 * When the activator is clicked, it opens a dialog with an autocomplete field
 * to select the members to invite.
 */
export function InviteMemberToSnapshot({
  snapshot,
  isMobileVersion,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { isInvitingSnapshotMembers } = useAppSelector(
    fetchingSnapshotsFlagsSelector
  );
  const { showToast } = useToast();
  const { companyId } = useAppParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const companyMembers = useAppSelector(companyMembersSelector);
  const snapshotMembers = useAppSelector(snapshotsMembersSelector(snapshot.id));
  const { canViewAllCompanyUsers } = useHasUserValidRoleCompanyLevel();
  const [inputMessage, setInputMessage] = useState<
    AutoCompleteMessage | undefined
  >();
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  // Since having roles in SnapShots wasn't supported in the past,
  // until we have a decision if we should support this, we will not show the role selection
  // const [selectedRole, setSelectedRole] =
  //   useState<SphereDashboardAPITypes.IAssignmentProjectRole>(
  //     CoreAPITypes.EUserProjectRole.viewer
  //   );

  /** Checks whether the member is already in the snapshot */
  const isAlreadyMemberInSnapshot = useCallback(
    (identity: string) =>
      snapshotMembers.some(
        (snapshotMember) => identity === snapshotMember.identity
      ),
    [snapshotMembers]
  );

  /**
   * The options to show in the autocomplete menu
   * It includes all the company members, and whether they are already in the snapshot
   */
  const memberOptions: AutoCompleteMemberOption[] = useMemo(
    () =>
      companyMembers.map((member) => {
        const isAlreadyMember = isAlreadyMemberInSnapshot(member.identity);

        return createMemberOption({
          member,
          isDisabled: isAlreadyMember,
          disabledMessage: isAlreadyMember
            ? "Already member of the snapshot"
            : "",
        });
      }),
    [companyMembers, isAlreadyMemberInSnapshot]
  );

  /**
   * Callback to handle the selection of members. Identifies if the selection
   * contains new email addresses, and shows a hint about it.
   * Then stores the selected members in the state.
   */
  function handleMemberSelect(members: string[]): void {
    const isMembersContainsEmail = members.some((member) =>
      isValidEmail(member)
    );

    /** Show hints about adding or selecting an email */
    if (isMembersContainsEmail) {
      setInputMessage({
        type: "info",
        helperText:
          "Invitation to this snapshot will be sent to the new email addresses",
      });
    } else {
      setInputMessage(undefined);
    }

    setSelectedMembers(members.map((member) => member.toLowerCase()));
  }

  function onInputChange(value: string): void {
    if (!value) {
      // Removing message on clearing the search text
      setInputMessage(undefined);
    } else if (!isValidEmail(value)) {
      // Showing error message for new and invalid email
      setInputMessage({ type: "error", helperText: "Enter a valid email" });
    } else {
      setSelectedMembers([...selectedMembers, value]);
      setInputMessage(undefined);
    }
  }

  /**
   * Callback to open the dialog
   * If the user has the permission to view all company members, fetches them
   * It also fetches the members of the snapshot, to identify which members
   * are already in the snapshot.
   * If the user does not have the permission to view all company members,
   * we do not fetch the members, as the user will not be able to see them
   * in the autocomplete list, but can invite by typing the full email.
   */
  function openDialog(): void {
    if (companyId && canViewAllCompanyUsers) {
      // Always fetch company members if user has permission to do so, even if the user can't
      // fetch snapshot members could show the member's list, just won't be able to show
      // which ones belong already to the snapshot. This is not a problem because if you
      // invite a member twice, the backend will ignore the second invite.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchCompanyMembers({
          coreApiClient,
          companyId,
        })
      );

      // No need to check for permission because the component to invite members to a snapshot
      // is only shown if the user has admin permission in the snapshot
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchSnapshotMembers({
          coreApiClient,
          companyId,
          snapshotId: snapshot.id,
        })
      );
    }
    setInputMessage(undefined);
    setSelectedMembers([]);
    setIsOpen(true);
  }

  /**
   * Callback to validate if a new email is valid
   * It checks if the email is valid, and if it is not already a member of the snapshot
   * or already selected.
   */
  function isValidNewEmail(email: string): boolean {
    const lowerEmail = email.toLowerCase();
    return (
      isValidEmail(email) &&
      !isAlreadyMemberInSnapshot(lowerEmail) &&
      !selectedMembers.includes(lowerEmail)
    );
  }

  /**
   * Callback to invite members to the snapshot
   */
  async function inviteSnapshotMembers(): Promise<void> {
    try {
      if (!companyId) {
        return;
      }
      const result = await dispatch(
        inviteMembersToSnapshot({
          coreApiClient,
          companyId,
          role: CoreAPITypes.EUserProjectRole.viewer,
          snapshotId: snapshot.id,
          userIdentities: selectedMembers,
        })
      ).unwrap();
      showToast({
        type: "success",
        message: result.data.message,
      });
    } finally {
      // Regardless of the result, close the dialog
      // The error is handled by the errors-slice
      setIsOpen(false);
    }
  }

  return (
    <>
      <SnapshotActionButton
        openDialog={openDialog}
        icon={AddMemberIcon}
        buttonTitle={BUTTON_TITLE}
        dataTestId="INVITE_SNAPSHOT_MEMBERS"
        isMobileVersion={isMobileVersion}
      />

      {/* Dialog with the details of the member to be removed */}
      <FaroDialog
        title="Invite members to snapshot"
        confirmText="Send invite"
        open={isOpen}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={inviteSnapshotMembers}
        onClose={() => setIsOpen(false)}
        isConfirmLoading={isInvitingSnapshotMembers}
        isConfirmDisabled={selectedMembers.length === 0}
      >
        <Grid maxWidth="100%" width="600px">
          {/* Since having roles in SnapShots wasn't supported in the past,
              until we have a decision if we should support this, we will not show the role selection */}
          {/* <Stack marginBottom={SPACE_ELEMENTS_OF_MODAL}>
            <SelectRole
              // A snapshot behaves like a project
              subject="snapshot"
              selectedRole={selectedRole}
              onChange={setSelectedRole}
            />
          </Stack> */}

          <Stack>
            <MembersAutocomplete
              options={memberOptions}
              handleChange={handleMemberSelect}
              message={inputMessage}
              validateNewOption={isValidNewEmail}
              labelTitle="Members"
              onInputChange={onInputChange}
              hasAutoFocus={true}
            />
          </Stack>
        </Grid>
      </FaroDialog>
    </>
  );
}
