import { BACKGROUND_TASK_STATES } from "@api/progress-api/progress-api-constants";
import { SphereNotificationPopover } from "@components/common/sphere-notification-popover";
import { BaseBackgroundTaskProps } from "@custom-types/sdb-background-tasks-types";
import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { DASH } from "@utils/ui-utils";

/** Renders the status of the background tasks */
export function BackgroundTaskStatus({
  task,
}: BaseBackgroundTaskProps): JSX.Element {
  let fontColor: string;
  const status = task.state ?? DASH;
  const message = task.message;

  switch (status) {
    case BACKGROUND_TASK_STATES.Created:
    case BACKGROUND_TASK_STATES.Scheduled:
      fontColor = sphereColors.gray800;
      break;

    case BACKGROUND_TASK_STATES.Started:
      fontColor = sphereColors.blue600;
      break;

    case BACKGROUND_TASK_STATES.Succeeded:
      fontColor = sphereColors.green700;
      break;

    case BACKGROUND_TASK_STATES.Aborted:
      fontColor = sphereColors.yellow600;
      break;

    case BACKGROUND_TASK_STATES.Failed:
      fontColor = sphereColors.red600;
      break;

    default:
      fontColor = sphereColors.gray800;
  }

  return (
    <Box component="div" display="flex" alignItems="center">
      <Box
        component="div"
        sx={{
          color: fontColor,
          fontWeight: "600",
        }}
      >
        {status}
      </Box>
      {message && (
        <SphereNotificationPopover
          message={message}
          sx={{
            marginLeft: "10px",
          }}
        />
      )}
    </Box>
  );
}
