import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { BaseProjectProps, SdbProject } from "@custom-types/project-types";
import { ReactSetStateFunction } from "@custom-types/types";
import { InputBase } from "@mui/material";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DASH } from "@utils/ui-utils";
import { useEffect, useState } from "react";

interface Props extends BaseProjectProps {
  /** Whether the project is editable or not */
  isProjectEditable: boolean;

  /** State value/setter pair to whether the dialog should be open or not */
  shouldShowDialog: boolean;
  setShouldShowDialog: ReactSetStateFunction<boolean>;
}

/**
 * Gets the description that should be displayed in the dialog.
 * If the description has a dash it means that it is empty, therefore
 * we should show the text field as empty.
 */
function getDisplayDescription(project: SdbProject): string {
  if (project.description === DASH) {
    return "";
  }
  return project.description ?? "";
}

/**
 * Shows a text field and allow user to update the project description
 */
export function ProjectDescriptionModal({
  project,
  isProjectEditable,
  shouldShowDialog,
  setShouldShowDialog,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const [description, setDescription] = useState<string>(
    getDisplayDescription(project)
  );

  // Makes sure the dialog will always use the latest project description.
  // This is because the initial useState is set when the component is instantiated
  // but not every time it changes between opened and closed.
  useEffect(() => {
    setDescription(getDisplayDescription(project));
  }, [project, shouldShowDialog]);

  /** Closes the dialog */
  function onClose(): void {
    setShouldShowDialog(false);
  }

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  return (
    <FaroDialog
      open={shouldShowDialog}
      title="Description"
      onClose={onClose}
      onConfirm={() => {
        trackEvent({
          name: ChangeProjectDetailsEvents.changeProjectDescription,
          props: { newDescriptionLength: description.length },
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
        dispatch(
          updateProjectDetails({
            coreApiClient,
            // Since description can't be empty in the BE, send DASH as default.
            payload: { description: description || DASH },
          })
        );
        onClose();
      }}
      confirmText="Done"
      isConfirmDisabled={description === project.description}
    >
      {isProjectEditable ? (
        <FaroSimpleTextField
          sx={{
            width: "530px",
            border: "none",
          }}
          variant="outlined"
          placeholder="Add description..."
          value={description}
          fullWidth={true}
          multiline={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value);
          }}
          rows={10}
        />
      ) : (
        <InputBase
          readOnly
          sx={{
            width: "530px",
            border: "none",
          }}
          value={description}
          fullWidth={true}
          multiline={true}
          rows={10}
        />
      )}
    </FaroDialog>
  );
}
