import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import WarningIcon from "@assets/icons/warning_32px.svg?react";
import { useState } from "react";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { Alert, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useToast } from "@hooks/use-toast";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { deleteProject } from "@store/projects/projects-slice-thunk";
import {
  BaseProjectProps,
  ProjectArchivingState,
} from "@custom-types/project-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Renders the delete button and delete the project on click with confirmation dialog */
export function DeleteProject({ project }: BaseProjectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const companyId = useAppSelector(selectedSdbCompanyIdSelector);
  const { showToast } = useToast();
  const { navigateToProjects } = useAppNavigation();
  const { trackEvent } = useTrackEvent();

  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  async function deleteSelectedProject(): Promise<void> {
    if (companyId) {
      trackEvent({ name: ProjectEvents.deleteProject });

      setIsProcessing(true);
      try {
        // Using unwrap makes sure that showToast is only shown if there is no error
        await dispatch(
          deleteProject({
            coreApiClient,
            companyId,
            projectId: project.id,
          })
        ).unwrap();

        showToast({
          type: "success",
          message: "Project deleted successfully",
        });

        navigateToProjects({
          companyId,
          projectStatus: ProjectArchivingState.archived,
        });

        // error is handled by the deleteProject thunk in errors-slice
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <>
      <SphereTooltip title="Delete Project">
        <FaroIconButton
          component={DeleteIcon}
          onClick={() => setShouldShowDialog(true)}
        />
      </SphereTooltip>

      {/* Dialog to confirm before delete */}
      <FaroDialog
        title="Delete Project"
        confirmText="Delete"
        open={shouldShowDialog}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onConfirm={deleteSelectedProject}
        onClose={() => setShouldShowDialog(false)}
        isConfirmLoading={isProcessing}
        maxWidth="sm"
        fullWidth={true}
        confirmButtonColor="red"
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: sphereColors.gray800,
            mb: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          When deleting the{" "}
          <var>
            <b>{project.name}</b>
          </var>{" "}
          project, all data will be lost.
        </Typography>

        <Alert
          severity="warning"
          icon={<WarningIcon width="20px" height="20px" />}
          sx={{
            backgroundColor: sphereColors.yellow100,
            alignItems: "center",
            py: "0px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", color: sphereColors.gray800, py: "7px" }}
          >
            Deleted projects can not be recovered.
          </Typography>
        </Alert>
      </FaroDialog>
    </>
  );
}
