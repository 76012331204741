import { useCallback } from "react";
import { ProjectApi } from "@api/project-api/project-api";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useToast } from "@hooks/use-toast";
import { GUID } from "@faro-lotv/foundation";

type ReturnFunction = (
  projectApiClient: ProjectApi,
  registrationRevisionId: GUID,
  hasAlreadyCanceledRevision?: boolean,
) => Promise<void>;

/** Handles the case when all uploads were canceled by the user. */
export function useHandleAllUploadsCanceled(): ReturnFunction {
  const { showToast } = useToast();
  const cancelRevision = useCancelRevision();

  return useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID,
      hasAlreadyCanceledRevision: boolean = false
    ): Promise<void> => {
      showToast({
        message: "Canceled all Blink scan imports.",
        type: "info",
        shouldAutoHide: true,
      });

      if (!hasAlreadyCanceledRevision) {
        await cancelRevision(projectApiClient, registrationRevisionId);
      }
    },
    [cancelRevision, showToast]
  );
}
