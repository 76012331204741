import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroIconButton } from "@components/common/faro-icon-button";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { useCallback, MouseEvent } from "react";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import ShareIcon from "@assets/icons/Share.svg?react";
import {
  CreateDialogProps,
  useDialog,
} from "@components/common/dialog/dialog-provider";
import { SphereShareLink } from "@components/common/sphere-share-link";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useSharedMarkupActions } from "@hooks/project-markups/use-shared-markups-actions";

/**
 * Dialog provider props to use for the share annotation dialog.
 */
export const SHARE_ANNOTATION_DIALOG_PROVIDER_PROPS: CreateDialogProps = {
  title: "Share annotation",
  maxWidth: "sm",
  shouldHideActions: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
  fullWidth: true,
};

/**
 * Component that represents a share icon for a specific markup,
 * allowing users to share a link to the markup via a dialog.
 * Renders a share icon that, when clicked, displays a dialog with the link
 * to the currently viewed markup.
 *
 * @param markup The properties of the current markup
 * @returns A JSX element representing the markup share icon, wrapped
 * in a tooltip component to display "Share annotation" when hovering over the icon.
 */
export function MarkupActionShareMarkup({
  markup,
}: BaseMarkupProps): JSX.Element | null {
  const { createDialog } = useDialog();
  const { trackEvent } = useTrackEvent();
  const { markupHref } = useSharedMarkupActions({ markup });

  /** Triggered to share a markup by link */
  const onShareMarkup = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      event.stopPropagation();

      trackEvent({ name: AnnotationEvents.shareAnnotation });

      // Early exit if there isn't a markup URL to share
      if (!markupHref) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      createDialog(
        SHARE_ANNOTATION_DIALOG_PROVIDER_PROPS,
        <SphereShareLink title="Annotation link" url={markupHref} />
      );
    },
    [createDialog, markupHref, trackEvent]
  );

  if (!markupHref) {
    return null;
  }

  return (
    <SphereTooltip title="Share annotation">
      <FaroIconButton
        buttonSize={ACTION_BTN_SIZE}
        iconSize={ACTION_ICON_SIZE}
        component={ShareIcon}
        onClick={onShareMarkup}
      />
    </SphereTooltip>
  );
}
