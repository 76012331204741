import { ApiClient, SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationCompany, IntegrationProject } from "@services/integrations-service/integrations-service-types";

interface Props {
  /** Core API client instance */
  coreApiClient: ApiClient;
  /** URL of the integration API */
  integrationApiUrl: string;
}

/** Abstract class to manage a 3rd party integration */
export abstract class IntegrationService {
  protected abstract integrationId: SphereDashboardAPITypes.IntegrationId;
  protected coreApiClient: ApiClient;
  protected integrationApiUrl: string;

  constructor({
    coreApiClient,
    integrationApiUrl,
  }: Props) {
    this.coreApiClient = coreApiClient;
    this.integrationApiUrl = integrationApiUrl;
  }

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  public abstract getIntegrationCompanies(): Promise<IntegrationCompany[]>;

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  public abstract getIntegrationProjects(companyId: string): Promise<IntegrationProject[]>;

  /**
   * Issues a request to the integration API
   * @returns the response of the issued request
   * @throws {Error} if the request fails
   * @param url of the endpoint to issue the request
   * @param verb the request method
   * @param headers optional request headers to add
   */
  protected async request<T>({
    url,
    verb,
    headers,
  }: SphereDashboardAPITypes.ProxyRequestPayload): Promise<T> {
    return this.coreApiClient.V2.SDB.proxyRequest<T>(
      this.integrationId,
      {
        url: `${this.integrationApiUrl}/${url}`,
        verb,
        headers,
      }
    );
  }
}
