import { useCallback, useMemo } from "react";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { assert, GUID } from "@faro-lotv/foundation";
import { RegistrationState } from "@faro-lotv/service-wires";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { Typography } from "@mui/material";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { isTaskInProgress } from "@hooks/upload-tasks/upload-tasks-utils";

/** Props for Cancel Import Button */
interface Props {
  /** Flag to disable the button */
  isDisabled: boolean;
}

/** Button to cancel upload of scans */
export function CancelImportButton({ isDisabled }: Props): JSX.Element{
  const { createDialog } = useDialog();
  const uploadTasks = useAppSelector(uploadTasksSelector);
  const { uploadManager } = useFileUploadContext();
  const cancelRevision = useCancelRevision();
  const { trackEvent } = useTrackEvent();

  const project = useAppSelector(selectedProjectSelector);
  assert(project, "Project has to be defined");

  const projectApiClient = useProjectApiClient({
    projectId: project.id,
  });

  /** Revision of the current Staging Area uploads in the current project, or null if no uploads are in progress. */
  const registrationRevisionId = useMemo<GUID | null>(() => {
    for (const task of uploadTasks) {
      if (isTaskInProgress(task) && isElsScanFileUploadTaskContext(task.context) && task.context.projectId === project.id) {
        return task.context.registrationRevisionId;
      }
    }
    return null;
  }, [uploadTasks, project.id]);

  /**
   * Flag to disable the button. The prop isDisabled can still be false shortly after all uploads have finished.
   * Therefore we check registrationRevisionId as well, which is derived from the in-progress upload tasks.
   */
  const isButtonDisabled = useMemo<boolean>(() => {
    return isDisabled || !registrationRevisionId;
  }, [isDisabled, registrationRevisionId]);

  /** Ask for confirmation, then cancel all ongoing Staging Area uploads of the current project. */
  const onCancelImport = useCallback(async (): Promise<void> => {
    if (isDisabled || !registrationRevisionId) {
      // Nothing to cancel; this code path should be unreachable.
      return;
    }

    // Prompt the user to confirm the canceling of uploads.
    const hasAccepted = await createDialog(
      {
        title: "Cancel Import?",
        confirmText: "Cancel Import",
        closeText: "Resume Import",
      },
      <Typography
        sx={{
          fontSize: "14px",
          marginBottom: SPACE_ELEMENTS_OF_MODAL,
        }}
      >
        You are about to interrupt the current import process.
        <span style={{marginTop: "8px", display: "block"}}>
          Any files that have been uploaded will be removed and you will need to start over with a new upload.
        </span>
      </Typography>
    );

    if (hasAccepted) {
      // If the user has opened the dialog shortly before the upload has finished, it might now be too late to cancel.
      // We cannot check `isDisabled` here, because it still has the old value.
      // Canceling the revision first makes sure that useAddScansToRevisionAndMergeToMain() can detect it.
      const revision = await projectApiClient.getRegistrationRevision(registrationRevisionId);
      if (revision.state === RegistrationState.started) {
        await cancelRevision(projectApiClient, registrationRevisionId);
      }

      // We also need to cancel aborted and successful tasks, otherwise they would stay on the page.
      // E.g. smaller scans might be successful before the user cancels the upload.
      // If there's any unrelated upload task, better don't touch it.
      const tasksToCancel = uploadTasks.filter(
        (task) => isElsScanFileUploadTaskContext(task.context) && task.context.registrationRevisionId === registrationRevisionId
      );
      const tasksToCancelInProgress = tasksToCancel.filter((task) => isTaskInProgress(task));

      for (const task of tasksToCancel) {
        // Cancel upload (if in progress), and remove from store (always).
        uploadManager.cancelFileUpload(task.id, true);
      }

      trackEvent({
        name: DataManagementEvents.cancelImport,
        props: {
          tasksCanceledTotal: tasksToCancel.length,
          tasksCanceledInProgress: tasksToCancelInProgress.length,
          registrationRevisionId,
          revisionState: revision.state,
        },
      });
    }
  }, [
    createDialog, cancelRevision, isDisabled, projectApiClient, uploadManager, trackEvent, uploadTasks, registrationRevisionId,
  ]);

  return (
    <SphereTooltip
      dataTestId="sa-cancel-import-tooltip"
      // If there are multiple buttons, show them in the same row.
      boxProps={{
        sx: {
          display: "inline-block",
        },
      }}
      title={isButtonDisabled ? "The import cannot be canceled at the current step." : "Cancel the current import process"}
    >
      <FaroTextButton
        onClick={() => void onCancelImport()}
        isDisabled={isButtonDisabled}
        sx={{
          fontSize: "14px",
          fontWeight: 600,
        }}
        dataTestId="sa-cancel-import-button"
      >
        Cancel Import
      </FaroTextButton>
    </SphereTooltip>
  );
}
