import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  numberedEnv,
  muiProKey,
  localizeKey,
  sentryDSNKey,
  amplitudeStagingKey,
  procoreApiUrlStaging,
  isNumberedEnv,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "dev",
  appRegion: "eu",
  appEnv: "eu-dev",
  urls: {
    apiBaseUrl: "https://core.api.dev.holobuilder.eu",
    sphereViewerUrl: "https://viewer.dev.holobuilder.eu",
    dashboard20Api: "https://sphere.dev.holobuilder.eu/api",
    hbWebEditorUrl: "https://app.dev.holobuilder.eu",
    progressApiUrl: "https://progress.api.dev.holobuilder.eu",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.dev.holobuilder.eu"
        : "https://entry.dev.holobuilder.eu",
    cookieManagerUrl: "",
    cookieManagerPreflyUrl: "",
    powerBIBaseUrl: "https://hb-powerbi-func-eu-dev.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.dev.holobuilder.eu",
    hbDashboardUrl: "https://dashboard.dev.holobuilder.eu",
    registrationApiUrl: "https://cloud-reg.api.dev.holobuilder.eu",
    stagingAreaApiUrl: "https://staging-area.api.dev.holobuilder.eu",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: { auth: "", preview: "", id: "" },
    auth0: {
      domain: "login.eu.dev.farosphere.com",
      clientId: "wSsYe8Ye5nQ0jfTxs0Urxrqy9evj6W2u",
    },
    amplitudeKey: amplitudeStagingKey,
  },
  integrations: {
    procoreApiUrl: procoreApiUrlStaging,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support-eu+dev@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};
