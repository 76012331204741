import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { errorFlagsSelector } from "@store/errors/errors-selector";
import { useAppSelector } from "@store/store-helper";
import { useEffect } from "react";

/**
 * Listen to the store and determine which type of error to show
 */
export function useErrorHandler(): void {
  const { handleErrorWithToast, handleErrorWithDialog, handleErrorWithPage } =
    useErrorContext();

  const errorFlags = useAppSelector(errorFlagsSelector);

  // TODO: Check how to show the errors if there more than one at a time: https://faro01.atlassian.net/browse/ST-699
  // Loop over the errors and show appropriate UI error handler
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-for-in-array -- Please review lint error
    for (const key in errorFlags) {
      const errorFlag = errorFlags[key];
      switch (errorFlag.uiError) {
        case "toast":
          handleErrorWithToast({ ...errorFlag });
          break;

        case "dialog":
          handleErrorWithDialog({ ...errorFlag });
          break;

        case "page":
          handleErrorWithPage({ ...errorFlag });
          break;
      }
    }
  }, [
    errorFlags,
    handleErrorWithDialog,
    handleErrorWithPage,
    handleErrorWithToast,
  ]);
}
