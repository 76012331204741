import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { BaseTeamProps, SdbTeamMemberBase } from "@custom-types/teams-types";
import { MembersTable } from "@components/table/members/members-table";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchingTeamsFlagsSelector,
  nextTeamMemberSelector,
  selectedTeamMembersSelector,
  isDetailOpenedSelector,
} from "@store/teams/teams-selector";
import { MemberHeaders } from "@components/table/members/members-table-utils";
import { useEffect, useMemo } from "react";
import { TeamDetailsInfoBar } from "@pages/members/teams/team-details/team-details-info-bar";
import { mapTeamMembersToSdbMembers } from "@components/table/teams/teams-table-utils";
import { TeamMembersPageBulkActions } from "@pages/members/teams/team-members-page-bulk-actions";
import { useCoreApiClient } from "@api/use-core-api-client";
import { LoadMoreButton } from "@components/common/button/load-more-button";
import { fetchTeamMembers } from "@store/teams/teams-slice-thunk";
import { MembersEmptyPage } from "@components/common/empty-page/members-empty-page";
import { InviteMemberToTeam } from "@pages/members/teams/invite-member-to-team";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { TeamSidePanelDescription } from "@pages/members/teams/team-side-panel-description";
import { Box, Stack } from "@mui/material";
import {
  bulkActionNameSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import { useRemoveMemberBulkDialogContent } from "@components/table/members/bulk-actions/bulk-remove-member-helper";

/** Side panel width in pixel */
const SIDE_PANEL_WIDTH = "320";

/**
 * Contains the Members tab of the team details
 */
export function TeamMembers({ team }: Partial<BaseTeamProps>): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const teamMembers = useAppSelector(selectedTeamMembersSelector);
  const { isFetchingTeamMembers } = useAppSelector(fetchingTeamsFlagsSelector);

  const isDetailOpened = useAppSelector(isDetailOpenedSelector);

  /** Stores the id of the next team member to be fetched */
  const nextTeamMember = useAppSelector(nextTeamMemberSelector);

  const { successDialogContent } = useRemoveMemberBulkDialogContent("team");

  /**
   * Decides whether to show the empty page or not.
   * - True if there is no members in the team
   */
  const shouldShowEmptyPage =
    !isFetchingTeamMembers && teamMembers.length === 0;

  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );

  // Fetch team members
  useEffect(() => {
    if (companyId && team) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchTeamMembers({
          coreApiClient,
          companyId,
          teamId: team.id,
          next: null,
        })
      );
    }
  }, [companyId, coreApiClient, dispatch, team]);

  // Constructing the members list for members table from sample members of a team
  const sbdTeamMembers: SdbTeamMemberBase[] = useMemo(() => {
    if (!teamMembers.length) {
      return [];
    }

    return mapTeamMembersToSdbMembers(teamMembers);
  }, [teamMembers]);

  if (!companyId || !team) {
    return <NotFoundPage />;
  }

  const buttonComponents = TeamMembersPageBulkActions({
    companyId,
    team,
    teamMembers: sbdTeamMembers,
  });

  // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
  async function loadMoreMembers(): Promise<void> {
    if (companyId && team?.id && nextTeamMember) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchTeamMembers({
          coreApiClient,
          companyId,
          teamId: team.id,
          next: nextTeamMember,
        })
      );
    }
  }

  return (
    <>
      {bulkActionName === "removeMember" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {team && shouldShowEmptyPage ? (
        <Stack flexDirection="row" gap={isDetailOpened ? "20px" : 0}>
          <MembersEmptyPage
            // eslint-disable-next-line max-len
            subtitle={`All the members of your ${TEAM_DISPLAY_NAME} will be found here. You can add members to your ${TEAM_DISPLAY_NAME} by inviting them.`}
            button={<InviteMemberToTeam team={team} buttonVariant="filled" />}
          />

          <Box
            sx={{
              overflow: "hidden",
              opacity: isDetailOpened ? 1 : 0,
              visibility: isDetailOpened ? "visible" : "hidden",
              width: isDetailOpened ? `${SIDE_PANEL_WIDTH}px` : "0",
              transition: "0.3s ease-in-out",
              mt: "14px",
            }}
          >
            <TeamSidePanelDescription team={team} />
          </Box>
        </Stack>
      ) : (
        <>
          <TeamDetailsInfoBar isLoading={isFetchingTeamMembers} />

          <MembersTable
            companyId={companyId}
            members={sbdTeamMembers}
            team={team}
            tableSubject="team"
            isLoading={isFetchingTeamMembers}
            requiredColumns={[
              MemberHeaders.avatar,
              MemberHeaders.user,
              MemberHeaders.email,
              MemberHeaders.status,
              MemberHeaders.options,
            ]}
            bulkActionButtons={buttonComponents}
            sidePanelContent={<TeamSidePanelDescription team={team} />}
            sidePanelContentWidth={SIDE_PANEL_WIDTH}
            isSidePanelOpened={isDetailOpened}
          />

          <LoadMoreButton
            isLoading={isFetchingTeamMembers}
            buttonText="Load more members"
            shouldHideLoadMoreButton={!nextTeamMember}
            onClick={loadMoreMembers}
          />
        </>
      )}
    </>
  );
}
