import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";

/**
 * This file contains some common strings used throughout the app. The idea is to have a single source
 * of truth for strings used in multiple places (i.e. sidebar item names and their breadcrumbs counter parts).
 * this file will later be replaced by the i18n (internationalization) implementation
 */
export const commonStrings = {
  MEMBERS: "Members",
  PROJECTS: "Projects",
  FEEDBACK: "Feedback",
  GROUPS: "Groups",
  ANALYTICS: "Analytics",
  SETTINGS: "Settings",
  HOLOBUILDER: "Switch to HoloBuilder",
  ADDONS: "Add-ons",
  TEAMS: `${TEAMS_DISPLAY_NAME}`,
} as const;
