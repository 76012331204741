// eslint-disable-next-line no-restricted-imports -- The only place TextField is needed to define FaroTextField
import { CircularProgress, SxProps, TextField } from "@mui/material";
import React, {
  CSSProperties,
  FocusEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CLEAR_TEXT_BTN_ID,
  SAVE_TEXT_BTN_ID,
  TextFieldControls,
} from "@components/common/faro-text-field/text-field-controls";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import {
  DEFAULT_INPUT_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE,
} from "@styles/common-styles";
import {
  SphereTooltip,
  SphereTooltipProps,
} from "@components/common/sphere-tooltip";
import { addTransparency, getBottomBorderWithBoxShadow } from "@utils/ui-utils";

type FaroTextFieldVariant =
  /**
   * This text field needs to be used for important data to show.
   * Output will be blue inputs with large texts
   */
  | "main"

  /**
   * This text field is used when presented in a row and should
   * use the full width and a different effect on hover.
   */
  | "row"

  /**
   * This text field needs to be used when secondary details are shown
   * Output will be labels and inputs in gray
   */
  | "secondary";

export interface Props {
  /** The label for the text field */
  label?: string | JSX.Element;

  /** The initial value for the text filed */
  initialValue: string | number;

  /** Displayed inside the input field if provided */
  placeholder?: string;

  /**
   * The function for when the changes in text field is confirmed. If not available, the text field will be readonly.
   * The function should return the new value to be displayed in the text field after the promise has been resolved,
   * either the original value to reset the input if the update was unsuccessful in the backend, or the updated value.
   */
  onConfirmed?(value: string): Promise<string | number | undefined>;

  /** Different variants for the text-field */
  faroVariant?: FaroTextFieldVariant;

  /** Whether the textfield should be disabled or not */
  isDisabled?: boolean;

  /** Whether the textfield should occupy the whole width or not */
  isFullWidth?: boolean;

  /** Whether the textfield should be in multiline or not */
  isMultiline?: boolean;

  /** Whether the textfield should be editable or not */
  isReadOnly?: boolean;

  /** General properties of styling that is passed through sx to keep the components same as MUI component */
  sx?: SxProps;

  /** Maximum number of rows to display when multiline option is set to true. Default 3 */
  maxRows?: number;

  /**
   * Optional minimal characters allowed in the text field.
   * If the user has less than minInputLength the check button will be disabled
   */
  minInputLength?: number;

  /**
   * Optional maximum characters allowed in the text field.
   * User won't be able to type more if reached to max maxInputLength
   */
  maxInputLength?: number;

  /**
   * Optional function to validate the input value.
   * If the function returns false, the check button will be disabled.
   */
  validate?: (value: string) => boolean;

  /**
   * Optional value to show and store if the input has empty text.
   * This is particularly useful for text fields in properties that do not allow
   * an empty value but we give the user the feeling that the value can be empty.
   */
  defaultEmptyValue?: string;

  /**
   * Optionally wrap the whole text field in a tooltip that will show the value of the text field.
   * Default false
   */
  shouldShowTooltip?: boolean;

  /** Optional font size to use for the text field input */
  fontSize?: string;

  /**
   * Optional properties to pass to the tooltip.
   * Only if shouldShowTooltip is true.
   */
  tooltipProps?: Partial<SphereTooltipProps>;

  /**
   * Optional cursor style to use for the text field input.
   */
  cursor?: CSSProperties["cursor"];

  /** Optional property that controls if a loading endAdornment should be displayed */
  isLoading?: boolean;

  /** Optional property that place the adornment under the input field */
  isAdornmentOnBottom?: boolean;

  /** Optional property that controls if a character count on endAdornment should be displayed */
  shouldShowCharacterCount?: boolean;

  /** Optional property that controls if input text should be cleared when input field loose focus */
  shouldClearOnBlur?: boolean;

  /** True if there is any error */
  hasError?: boolean;

  /** Optional property to show helper text */
  helperText?: string;

  /** Triggers when user cancel the editing */
  onCancel?(): void;
}

/** Default maximum rows to show for multiline text field. */
const DEFAULT_MAX_ROWS = 3;

type FaroVariantCssProps<T extends keyof CSSProperties> = {
  [key in FaroTextFieldVariant]: CSSProperties[T];
};

export interface CssProps {
  borderBottomHeight: FaroVariantCssProps<"height">;
  borderBottomFocusHeight: FaroVariantCssProps<"height">;
  fontColor: FaroVariantCssProps<"color">;
  fontWeight: FaroVariantCssProps<"fontWeight">;
  inputFontSize: FaroVariantCssProps<"fontSize">;
  inputHeight: FaroVariantCssProps<"height">;
  boxHoverColorRow: FaroVariantCssProps<"backgroundColor">;
  paddingRight: FaroVariantCssProps<"paddingRight">;
  editButtonSize: FaroVariantCssProps<"height">;
  editIconSize: FaroVariantCssProps<"height">;
  controlButtonSize: FaroVariantCssProps<"height">;
  controlIconSize: FaroVariantCssProps<"height">;
}

const CSS_PROPS: CssProps = {
  /** Defines the height in pixels for the bottom border of the text field. */
  borderBottomHeight: {
    main: "1px",
    secondary: "1px",
    row: "1px",
  },

  /** Defines the height in pixels for the bottom border of the text field when is in focus mode */
  borderBottomFocusHeight: {
    main: "1px",
    secondary: "1px",
    row: "2px",
  },

  /** Adjusts the font color based on the faro variant used */
  fontColor: {
    main: sphereColors.blue500,
    secondary: sphereColors.gray800,
    row: sphereColors.gray800,
  },

  /** Adjusts the font weight based on the faro variant used */
  fontWeight: {
    main: "700",
    secondary: "400",
    row: "600",
  },

  /** Adjusts the font size for the input based on the faro variant used */
  inputFontSize: {
    main: DEFAULT_INPUT_FONT_SIZE,
    secondary: DEFAULT_INPUT_FONT_SIZE,
    row: DEFAULT_TITLE_FONT_SIZE,
  },

  /** Adjusts the font size for the input based on the faro variant used */
  inputHeight: {
    main: undefined,
    secondary: undefined,
    row: "59px",
  },

  // When the text field is in row mode, the hover color should be different.
  boxHoverColorRow: {
    main: undefined,
    secondary: undefined,
    row: addTransparency({
      color: sphereColors.gray500,
      alpha: EDecimalToHex.twentySix,
    }),
  },

  paddingRight: {
    main: "0px",
    secondary: "0px",
    row: "19px",
  },

  editButtonSize: {
    main: "24px",
    secondary: "24px",
    row: "36px",
  },

  editIconSize: {
    main: "16px",
    secondary: "16px",
    row: "24px",
  },

  controlButtonSize: {
    main: "24px",
    secondary: "24px",
    row: "24px",
  },

  controlIconSize: {
    main: "16px",
    secondary: "16px",
    row: "18px",
  },
};

/**
 * General component which shows a text field and basic FARO stylings
 */
export function FaroTextField({
  label,
  initialValue,
  placeholder,
  onConfirmed,
  faroVariant = "secondary",
  fontSize,
  isDisabled = false,
  isFullWidth = true,
  isMultiline = false,
  isReadOnly = true,
  shouldShowTooltip = false,
  tooltipProps = {},
  maxRows = DEFAULT_MAX_ROWS,
  minInputLength = 0,
  maxInputLength,
  validate = () => true,
  defaultEmptyValue = "",
  sx,
  cursor = undefined,
  isLoading = false,
  isAdornmentOnBottom = false,
  shouldShowCharacterCount = false,
  shouldClearOnBlur = true,
  hasError = false,
  helperText,
  onCancel = () => undefined,
  ...rest
}: Props): JSX.Element {
  const [value, setValue] = useState<string>(getDisplayValue(initialValue));
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  // Ref to store a reference to the input element
  const inputRef = useRef<HTMLInputElement | null>(null);

  // React to changes in case the initial value changes
  useEffect(() => {
    setValue(getDisplayValue(initialValue));
    // Do not include getDisplayValue in the dependency array because it causes unnecessary re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  /** Defines the boxShadow style for the text field when it is focused. */
  const focusBoxShadowEffect: CSSProperties["boxShadow"] =
    getBottomBorderWithBoxShadow({
      thickness: CSS_PROPS.borderBottomFocusHeight[faroVariant],
      color: sphereColors.blue500,
    });

  /** Defines the boxShadow style for the text field when it is hovered. */
  const hoverBoxShadowEffect: CSSProperties["boxShadow"] =
    getBottomBorderWithBoxShadow({
      thickness: CSS_PROPS.borderBottomHeight[faroVariant],
      color: sphereColors.gray400,
    });

  /** Flag whether the confirmation should be disabled because the input is not valid. */
  const isConfirmDisabled = useMemo(() => {
    return value.trim().length < minInputLength || !validate(value);
  }, [value, minInputLength, validate]);

  /** Enters the edit mode and makes sure the input is focused. */
  function enterEditMode(): void {
    inputRef?.current?.focus();
    setIsEditMode(true);
  }

  /** Leaves the edit mode and makes sure the input is not focused anymore */
  function leaveEditMode(): void {
    inputRef?.current?.blur();
    setIsEditMode(false);

    // To hide the edit icon when leaving edit mode
    isAdornmentOnBottom && setIsHovered(false);
  }

  /** Defines the style for the box shadow when on hover depending on the text field state. */
  const hoverBoxShadow: CSSProperties["boxShadow"] = useMemo(() => {
    if (isDisabled || isReadOnly) {
      return "none";
    }
    return isEditMode ? focusBoxShadowEffect : hoverBoxShadowEffect;
  }, [
    isEditMode,
    isDisabled,
    isReadOnly,
    focusBoxShadowEffect,
    hoverBoxShadowEffect,
  ]);

  /**
   * Gets the actual value to be displayed in the text field. If the value equals defaultEmptyValue
   * it means that the value should be an empty string.
   * E.g. when the value is a dash, make sure when the user clicks on the text field, the dash is
   * immediately removed and the user can start typing in an empty text field.
   */
  function getDisplayValue(value?: string | number): string {
    const str = (value ?? "").toString();
    return str === defaultEmptyValue ? "" : str;
  }

  async function onCheckClicked(
    event: React.MouseEvent | React.KeyboardEvent
  ): Promise<void> {
    event.stopPropagation();
    if (!isReadOnly && !isDisabled && onConfirmed) {
      // If the text field does not allow an empty value, use it when confirming the value.
      const returnValue = await onConfirmed(value || defaultEmptyValue);
      // If the return value is undefined, it means that the caller will update the value.
      // Either to reset to the previous value if the update was unsuccessful in the backend
      // or to update the value to the new value if the update was successful.
      setValue(getDisplayValue(returnValue));
    }
    leaveEditMode();
  }

  /**
   * Discards all changes in the dialog and closes the edit mode.
   */
  function closeEditMode(): void {
    leaveEditMode();
    setValue(getDisplayValue(initialValue));
  }

  /**
   * Triggered when the user clicks outside of the input field.
   * Be aware that some elements of the FaroTextField component might be
   * considered to be outside the input field, like the buttons or the label.
   */
  function onBlur(event: FocusEvent): void {
    // If the user clicked on either save or clear button, let the buttons handle the next step.
    if (
      [SAVE_TEXT_BTN_ID, CLEAR_TEXT_BTN_ID].includes(
        event.relatedTarget?.id ?? ""
      )
    ) {
      if (event.relatedTarget?.id === SAVE_TEXT_BTN_ID && isConfirmDisabled) {
        // If onBlur is triggered, the user clicked on save button and that button was disabled
        // the input would normally lose its focus. To prevent this just call focus on the input.
        inputRef?.current?.focus();
      }
      return;
    }
    // Otherwise we assumed the user clicked outside the dialog and therefore we discard changes
    // based on the shouldClearOnBlur props.
    (shouldClearOnBlur || !value.length) && closeEditMode();
  }

  function onClearClicked(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.stopPropagation();
    closeEditMode();
    onCancel();
  }

  /** Triggers when the multiline text field is focused */
  function onFocus(): void {
    // Scroll to last position if the text field has lot of texts
    if (isMultiline && inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }

  return (
    <SphereTooltip
      title={<var>{value}</var>}
      shouldShowTooltip={shouldShowTooltip && !isEditMode}
      {...tooltipProps}
    >
      <TextField
        placeholder={placeholder}
        inputRef={inputRef}
        focused={isEditMode}
        sx={{
          label: {
            color: sphereColors.gray500,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            // Do not color the label on edit mode
            "&.Mui-focused.MuiInputLabel-root": {
              color: sphereColors.gray500,
            },
            cursor: cursor ?? null,
          },
          "& input, & textArea": {
            color: CSS_PROPS.fontColor[faroVariant] ?? null,
            fontWeight: CSS_PROPS.fontWeight[faroVariant] ?? null,
            fontSize: fontSize ?? CSS_PROPS.inputFontSize[faroVariant] ?? null,
            height: CSS_PROPS.inputHeight[faroVariant] ?? null,
            cursor: cursor ?? null,
          },
          "& input": {
            textOverflow: "ellipsis",
          },
          ...(isMultiline && {
            textarea: {
              textAlign: "justify",
              pr: "10px",
              overflowX: "hidden",
              scrollbarColor: `${addTransparency({
                color: sphereColors.black,
                alpha: EDecimalToHex.twentySix,
              })} transparent`,
              scrollbarWidth: "thin",
            },
            "& .MuiInputAdornment-root": {
              // This style need to place the input adornment in correct position
              mt: "auto",
              mb: isAdornmentOnBottom ? "-33px" : "14px",
            },
          }),
          "& .MuiInputBase-root .MuiInputBase-inputMultiline": {
            // This property is needed for the text area
            fontSize,
          },
          "& .Mui-focused.MuiInputLabel-root": {
            // Use the correct blue text for the label on top of the text field when on edit mode.
            color: sphereColors.blue500,
          },
          "& .MuiInputBase-root.MuiInput-underline": {
            // On edit mode, the border effect should stay even if the component is not being hovered.
            boxShadow:
              isEditMode && !isDisabled && !isReadOnly
                ? focusBoxShadowEffect
                : "none",
            // Avoid using border to prevent the content from jumping, use box shadow instead.
            borderBottom: "none",
            "& .MuiInputBase-input": {
              // When the text field is in row mode and it is not editing, the hover should show a pointer.
              cursor: faroVariant === "row" && !isEditMode ? "pointer" : null,
            },
            "&:hover": {
              backgroundColor: !isEditMode
                ? CSS_PROPS.boxHoverColorRow[faroVariant]
                : undefined,
              // Avoid using border to prevent the content from jumping, use box shadow instead.
              borderBottom: "none",
              boxShadow: hoverBoxShadow,
            },
            "&:before,:after,:hover:before": {
              // Hide all other default borders for text fields
              borderBottom: "none",
            },
          },
          ...sx,
        }}
        inputProps={{ maxLength: maxInputLength }}
        InputProps={{
          onClick: () => {
            if (!isReadOnly && !isDisabled) {
              enterEditMode();
            }
          },
          onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (
              event.key === "Enter" &&
              !isConfirmDisabled &&
              !isReadOnly &&
              !isMultiline
            ) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
              onCheckClicked(event);
            }
            if (event.key === "Escape") {
              closeEditMode();
            }
          },
          onMouseOver: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false),
          // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
          disableUnderline: isReadOnly,
          // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
          readOnly: !isEditMode,
          endAdornment: (
            <>
              {!isReadOnly && !isDisabled && (
                <TextFieldControls
                  isHovered={isHovered}
                  isEditMode={isEditMode}
                  isConfirmDisabled={isConfirmDisabled}
                  paddingRight={CSS_PROPS.paddingRight[faroVariant]}
                  editButtonSize={CSS_PROPS.editButtonSize[faroVariant]}
                  editIconSize={CSS_PROPS.editIconSize[faroVariant]}
                  controlButtonSize={CSS_PROPS.controlButtonSize[faroVariant]}
                  controlIconSize={CSS_PROPS.controlIconSize[faroVariant]}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
                  onEditClicked={enterEditMode}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
                  onCheckClicked={onCheckClicked}
                  onClearClicked={onClearClicked}
                  isAdornmentOnBottom={isAdornmentOnBottom}
                  shouldShowCharacterCount={shouldShowCharacterCount}
                  inputLength={value.length}
                  maxInputLength={maxInputLength}
                />
              )}
              {isLoading ? (
                <>
                  <span style={{ marginLeft: "5px", fontSize: "10px" }}>
                    Changing
                  </span>
                  <CircularProgress
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "5px",
                    }}
                    size={14}
                  />
                </>
              ) : null}
            </>
          ),
        }}
        variant="standard"
        label={label}
        // Should show the default empty value, when the input is not in edit mode, e.g. a dash.
        value={isEditMode ? value : value || defaultEmptyValue}
        fullWidth={isFullWidth}
        multiline={isMultiline}
        maxRows={maxRows}
        onBlur={onBlur}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(getDisplayValue(event.target.value));
          validate(event.target.value);
        }}
        disabled={isDisabled}
        onFocus={onFocus}
        error={hasError}
        helperText={helperText}
        {...rest}
      />
    </SphereTooltip>
  );
}
