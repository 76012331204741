import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";
import { getMemberTableSubjectDisplayName } from "@components/table/members/members-table-utils";

/** Determines the content of bulk dialog and bulk success dialog for removing member */
export function useRemoveMemberBulkDialogContent(
  memberTableSubject: MemberTableSubject
): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );
  const tableSubjectName = getMemberTableSubjectDisplayName(memberTableSubject);

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: `Remove members from the ${tableSubjectName}?`,
      description: `When you confirm this action, 
      the following members will no longer be able to access this ${tableSubjectName}.`,
    },

    failed: {
      title: `Couldn't remove ${numberOfErrors} of ${numberOfUpdatingItems} members`,
      description: `These members couldn't be removed from this ${tableSubjectName},
      check the details and try again.`,
    },
    confirmButtonText: "remove",
    entityType: "member",
  });

  const successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: "member",
    })} removed`,
    description: `All selected members have been removed from this ${tableSubjectName}.`,
  };

  return { bulkDialogContent, successDialogContent };
}
