import { sphereColors } from "@styles/common-colors";
import { STATUS_OPTIONS } from "@pages/project-details/project-markups/status/markup-status-types";
import { memo, useState } from "react";
import { Box, Fade, Menu } from "@mui/material";
import { FaroMenuSection } from "@faro-lotv/flat-ui";
import { useAppSelector } from "@store/store-helper";
import { MarkupStatus } from "@pages/project-details/project-markups/status/markup-status";
import { MarkupStatusDropdownItem } from "@pages/project-details/project-markups/status/markup-status-dropdown-item";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { markupsTemplateIdsSelector } from "@store/markups/markups-selector";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import {
  BaseMarkupColumnProps,
  Markup,
} from "@custom-types/project-markups-types";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { cloneDeep, findIndex } from "lodash";
import {
  AnnotationStatus,
  IElementDropDownMarkupField,
  IElementType,
} from "@faro-lotv/ielement-types";

/** Renders the markup status dropdown to change the markup status */
function MarkupStatusDropdownComponent({
  markup,
  isSidePanelOpen = false,
}: BaseMarkupColumnProps): JSX.Element {
  const {
    projectId,
    markups,
    setIsMarkupUpdating,
    updateSelectedMarkupId,
    hasPermissionToEditMarkup,
    updateMarkups,
  } = useMarkupContext();
  const { updateMarkupStatus } = useProjectMarkupUpdate({ projectId });

  const templateIds = useAppSelector(markupsTemplateIdsSelector);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isEditModeEnabled = Boolean(anchorEl);

  const status = markup.status?.value;

  /** Triggers when user click on the status on the table, makes the dropdown visible */
  function onStatusChipClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();

    // Early return if already an update is running
    if (isUpdating) {
      return;
    }

    setAnchorEl(event.currentTarget);
    updateSelectedMarkupId(markup.id);
  }

  /** Triggers when the status dropdown is closed */
  function onDropdownClose(): void {
    setAnchorEl(null);
    updateSelectedMarkupId(undefined);
  }

  /** Update markups on context */
  function processMarkupChanges(newMarkups: Markup[]): void {
    updateMarkups(newMarkups);

    setIsUpdating(false);
    setIsMarkupUpdating(false);
  }

  /** Triggers when user clicks on the new status from dropdown */
  async function onStatusChange(
    newStatus: AnnotationStatus | undefined
  ): Promise<void> {
    // Early return if status is not changed
    if (newStatus === status) {
      return;
    }

    if (!templateIds) {
      throw new Error("Expected project to have an advanced markup template");
    }

    setIsUpdating(true);
    setIsMarkupUpdating(true);

    onDropdownClose();

    const mutations = await updateMarkupStatus({
      markup,
      newStatus,
      statusTemplateId: templateIds.statusTemplateId,
    });

    if (!mutations) {
      return;
    }

    const newMarkups = cloneDeep(markups);
    const markupIndex = findIndex(newMarkups, { id: markup.id });
    const currentItem = newMarkups[markupIndex].status;

    if ("newElement" in mutations[0]) {
      const newElement = mutations[0].newElement;

      const newItem: IElementDropDownMarkupField = {
        ...newElement,
        id: newElement.id,
        rootId: newElement.rootId,
        name: newElement.name,
        templateId: templateIds.statusTemplateId,
        type: IElementType.dropDownMarkupField,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        root_Id: newElement.rootId,
        createdBy: mutations[0].createdAt,
        createdAt: mutations[0].createdAt,
        modifiedBy: mutations[0].createdAt,
        modifiedAt: mutations[0].createdAt,
      };

      newMarkups[markupIndex].status = newItem;

      processMarkupChanges(newMarkups);

      return;
    }

    if ("cascadeLinks" in mutations[0]) {
      newMarkups[markupIndex].status = undefined;

      processMarkupChanges(newMarkups);

      return;
    }

    if (!currentItem) {
      throw new Error("Item is required but was undefined.");
    }

    if ("value" in mutations[0]) {
      const itemUpdated: IElementDropDownMarkupField = {
        ...currentItem,
        value: mutations[0].value,
      };

      newMarkups[markupIndex].status = itemUpdated;

      processMarkupChanges(newMarkups);

      return;
    }
  }

  // Return without dropdown if user has no permission to edit
  if (!hasPermissionToEditMarkup) {
    return <MarkupStatus markup={markup} />;
  }

  return (
    <>
      <Box
        onClick={onStatusChipClick}
        sx={{
          height: "100%",
          width: "100%",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          pl: isSidePanelOpen ? 0 : "8px",
          boxSizing: "border-box",
          borderBottom: "1px solid transparent",
          ...(isEditModeEnabled && {
            // Show blue bottom border and white background on edit mode
            background: sphereColors.pureWhite,
            borderBottom: `2px solid ${sphereColors.blue500}`,
          }),
          ...(!isEditModeEnabled && {
            "&:hover": {
              // Show gray bottom border on hover
              borderBottom: `1px solid ${sphereColors.gray800}`,
            },
          }),
        }}
      >
        <MarkupStatus markup={markup} />

        {isUpdating && <FaroButtonSpinner />}
      </Box>
      <Menu
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={isEditModeEnabled}
        onClose={onDropdownClose}
        TransitionComponent={Fade}
        slotProps={{
          paper: {
            sx: {
              minWidth: "126px",
              marginTop: "2px",
            },
          },
        }}
        sx={{
          "& .MuiMenuItem-root": {
            marginX: "8px",
            "&:hover": {
              color: sphereColors.blue500,
            },
          },
          "& .MuiMenuItem-root.Mui-selected": {
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210, 0.08)",
            },
          },
        }}
      >
        <FaroMenuSection>CHANGE STATUS</FaroMenuSection>
        {STATUS_OPTIONS.map((markupOption) => (
          <MarkupStatusDropdownItem
            key={markupOption.key}
            markupOption={markupOption}
            activeStatuses={status ? [status] : []}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
            onStatusChange={onStatusChange}
          />
        ))}
      </Menu>
    </>
  );
}

export const MarkupStatusDropdown = memo(MarkupStatusDropdownComponent);
