import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { changeDescr } from "@faro-lotv/project-source";
import { createMutationSetElementDescription } from "@faro-lotv/service-wires";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppDispatch } from "@store/store-helper";
import { sphereColors } from "@styles/common-colors";
import {
  DEFAULT_INPUT_FONT_SIZE,
  inputPlaceholderItalicSx,
} from "@styles/common-styles";
import { useState } from "react";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { cloneDeep, findIndex } from "lodash";

/** Maximum number of characters for markup's detail */
const MAX_INPUT_LENGTH = 10000;

/** Maximum number of rows for markup's detail input field */
const MAX_ROW = 7;

/** Render markup description with ability to update */
export function MarkupDescription({ markup }: BaseMarkupProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  const {
    projectId,
    setIsMarkupUpdating,
    hasPermissionToEditMarkup,
    updateMarkups,
    markups,
  } = useMarkupContext();
  const projectApiClient = useProjectApiClient({
    projectId,
  });

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  /** Triggers when user clicks on confirm button */
  async function onDescriptionUpdate(newValue: string): Promise<string> {
    // Early return if new value isn't changed
    if (newValue === markup.descr) {
      return newValue;
    }

    trackEvent({
      name: AnnotationEvents.editAnnotation,
      props: {
        property: "description",
        isValueEmpty: !newValue.length,
      },
    });

    const mutations = [
      createMutationSetElementDescription(markup.id, newValue),
    ];

    try {
      setIsUpdating(true);
      setIsMarkupUpdating(true);

      await projectApiClient.applyMutations(mutations);

      // Update local store
      // eslint-disable-next-line @typescript-eslint/await-thenable -- Please review lint error
      await dispatch(changeDescr({ id: markup.id, descr: newValue }));
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationDescription-${Date.now().toString()}`,
        title: "Could not change annotation description. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    } finally {
      const newMarkups = cloneDeep(markups);
      const markupIndex = findIndex(newMarkups, { id: markup.id });

      newMarkups[markupIndex].descr = newValue;

      updateMarkups(newMarkups);
      setIsUpdating(false);
      setIsMarkupUpdating(false);
    }

    return newValue;
  }

  return (
    <Box component={"div"} sx={{ mt: "40px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "12px",
            color: sphereColors.gray700,
          }}
          data-testid="markup-side-panel-info-description"
        >
          {"Description"}
        </Typography>

        {isUpdating && <CircularProgress size={"1rem"} sx={{ ml: "5px" }} />}
      </Box>

      <FaroTextField
        maxRows={MAX_ROW}
        isDisabled={isUpdating}
        isReadOnly={!hasPermissionToEditMarkup}
        isMultiline={true}
        shouldClearOnBlur={false}
        isAdornmentOnBottom={true}
        shouldShowCharacterCount={true}
        maxInputLength={MAX_INPUT_LENGTH}
        placeholder="Add description here"
        initialValue={markup.descr || ""}
        onConfirmed={(newValue) => onDescriptionUpdate(newValue.trim())}
        fontSize={DEFAULT_INPUT_FONT_SIZE}
        sx={{
          ...inputPlaceholderItalicSx,
        }}
        data-testid="markup-side-panel-info-description-value"
      />
    </Box>
  );
}
