import { formatUserRoleType } from "@utils/data-display";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useToast } from "@hooks/use-toast";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { CompanyMemberTypes } from "@custom-types/member-types";
import { currentUserSelector } from "@store/user/user-selector";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { updateMemberRoleInGroup } from "@store/groups/groups-slice";
import { GroupMemberEvents } from "@utils/track-event/track-event-list";
import { RoleChangeSuccessDescription } from "@components/table/members/members-column/role-column/role-change-success-description";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SelectGroupRole } from "@components/role/select-group-role";
import { useHasUserValidRoleGroupLevel } from "@hooks/access-control/use-has-user-valid-role-group-level";

interface Props extends BaseCompanyIdProps {
  /** The member to show/adjust the role. */
  member: CompanyMemberTypes;

  /** The selected group */
  selectedGroup: SphereDashboardAPITypes.IGroupDetails;
}

/** Render and adjust member roles in group context */
export function GroupMemberRole({
  companyId,
  member,
  selectedGroup,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const { canEditMember } = useHasUserValidRoleGroupLevel({ selectedGroup });

  const isCurrentUser = currentUser?.identity === member.identity;
  const isCurrentUserGM =
    currentUser?.role === CoreAPITypes.EUserCompanyRole.companyManager;

  /**
   * Return non-editable member role if:
   * - Member is current logged user and the role is group manager,
   *   as he might loose access to the group
   * - User has no permission to edit member
   */
  if ((isCurrentUser && isCurrentUserGM) || !canEditMember) {
    return (
      <span style={{ fontSize: DEFAULT_TEXT_FONT_SIZE }}>
        {formatUserRoleType(member.role)}
      </span>
    );
  }

  /** Trigger updating member role and show success message */
  async function onRoleChange(
    role: SphereDashboardAPITypes.GroupMemberCompanyRole
  ): Promise<void> {
    // Avoid same role click
    if (role === member.role) {
      return;
    }

    trackEvent({
      name: GroupMemberEvents.changeRole,
      props: { role: role },
    });

    // Using unwrap makes sure that showToast is only shown if there is no error
    await dispatch(
      updateMemberRoleInGroup({
        coreApiClient,
        companyId,
        groupId: selectedGroup.id,
        role,
        userIdentity: member.identity,
      })
    ).unwrap();

    showToast({
      message: "Role Changed",
      description: (
        <RoleChangeSuccessDescription role={formatUserRoleType(role)} />
      ),
      type: "success",
    });
    // error is handled by the removeCompanyMember thunk in errors-slice
  }

  return (
    <SelectGroupRole
      selectedRole={member.role as SphereDashboardAPITypes.IAssignmentGroupRole}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onChange={onRoleChange}
      isTableCell={true}
    />
  );
}
