import { IntegrationActions } from "@pages/integrations/integrations-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { ProjectIntegrationsConnectDialog } from "@pages/project-details/project-integrations/project-integrations-connect-dialog";
import { ProjectIntegrationsDisconnectDialog } from "@pages/project-details/project-integrations/project-integrations-disconnect-dialog";
import { ProjectIntegrationsConnectedDialog } from "@pages/project-details/project-integrations/project-integrations-connected-dialog";
import { ReactSetStateFunction } from "@custom-types/types";
import { IntegrationProject } from "@services/integrations-service/integrations-service-types";
import { memo } from "react";

interface Props {
  /** Selected integration ID */
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId;

  /** Callback while confirming connect dialog */
  onConnectConfirm(integrationProject: IntegrationProject): Promise<void>;

  /** Flag to determine if project is connecting or disconnecting */
  isLoading: boolean;

  /** Callback while confirming disconnect dialog */
  onDisconnectConfirm(): Promise<void>;

  /** All the actions that can be performed for integrations in projects */
  selectedAction?: Extract<
    IntegrationActions,
    "ConnectToProject" | "DisconnectFromProject"
  >;

  /** Callback while closing the dialogs */
  onClose(): void;

  /** True if the connected dialog should show */
  shouldShowConnectedDialog: boolean;

  /** Callback to update shouldShowConnectedDialog state */
  setShouldShowConnectedDialog: ReactSetStateFunction<boolean>;
}

/** Renders the correct dialog based on the selected action  */
function ProjectIntegrationsDialogComponent({
  selectedIntegrationId,
  onConnectConfirm,
  isLoading,
  onDisconnectConfirm,
  shouldShowConnectedDialog,
  setShouldShowConnectedDialog,
  selectedAction,
  onClose,
}: Props): JSX.Element | null {
  if (shouldShowConnectedDialog) {
    return (
      <ProjectIntegrationsConnectedDialog
        selectedIntegrationId={selectedIntegrationId}
        onClose={() => setShouldShowConnectedDialog(false)}
      />
    );
  }

  if (selectedAction === "ConnectToProject") {
    return (
      <ProjectIntegrationsConnectDialog
        isConnecting={isLoading}
        selectedIntegrationId={selectedIntegrationId}
        onConnectConfirm={onConnectConfirm}
        onClose={onClose}
      />
    );
  }

  if (selectedAction === "DisconnectFromProject") {
    return (
      <ProjectIntegrationsDisconnectDialog
        isDisconnecting={isLoading}
        onDisconnectConfirm={onDisconnectConfirm}
        onClose={onClose}
      />
    );
  }

  return null;
}

export const ProjectIntegrationsDialog = memo(
  ProjectIntegrationsDialogComponent
);
