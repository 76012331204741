import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationCompany, IntegrationProject } from "@services/integrations-service/integrations-service-types";
import { ProcoreEntity } from "@services/integrations-service/procore/procore-types";
import { IntegrationService } from "@services/integrations-service/integration-service";

/** Service that provides methods to manage the Procore 3rd party integration */
export class ProcoreService extends IntegrationService {
  protected integrationId = SphereDashboardAPITypes.IntegrationId.procore;

  /**
   * @returns the IntegrationCompany entities of the user
   * @throws {Error} if it fails to get the entities
   */
  public async getIntegrationCompanies(): Promise<IntegrationCompany[]> {
    const procoreCompanies = await this.getProcoreCompanies();

    return procoreCompanies.map((procoreCompany) => ({
      integrationId: SphereDashboardAPITypes.IntegrationId.procore,
      id: procoreCompany.id.toString(),
      name: procoreCompany.name,
    }));
  }

  /**
   * @returns the IntegrationProject entities for a given integration company
   * @throws {Error} if it fails to get the entities
   * @param companyId ID of the company
   */
  public async getIntegrationProjects(
    companyId: string
  ): Promise<IntegrationProject[]> {
    const procoreProjects = await this.getProcoreProjects(companyId);

    const integrationProjects: IntegrationProject[] = procoreProjects.map((procoreProject) => {
      const bcfProjectId = `${procoreProject.id}~${companyId}`;
      return {
        integrationId: SphereDashboardAPITypes.IntegrationId.procore,
        id: procoreProject.id.toString(),
        name: procoreProject.name,
        companyId,
        rfisBcfProjectId: bcfProjectId,
        issuesOrObservationsBcfProjectId: bcfProjectId,
        type: "none",
      };
    });

    return integrationProjects;
  }

  /**
   * @returns the companies of the user
   * @throws {Error} if it fails to fetch the companies
   */
  private async getProcoreCompanies(): Promise<ProcoreEntity[]> {
    return this.request({
      url: "companies",
      verb: "GET",
    });
  }

  /**
   * @returns the projects for a given company
   * @throws {Error} if it fails to fetch the projects
   * @param companyId ID of the company
   */
  private async getProcoreProjects(companyId: string): Promise<ProcoreEntity[]> {
    return this.request({
      url: `companies/${companyId}/projects`,
      verb: "GET",
    });
  }
}
