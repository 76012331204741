import { Box } from "@mui/material";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { useMemo } from "react";

/** Class name to highlight part of string */
const HIGHLIGHT_CLASS = "text_highlight";

interface IntegrationAutocompleteItemProps {
  /** The integration entry name */
  title: string;

  /** Input value typed on the input field */
  inputValue?: string;
}

/**
 * Shows a single integration entry as an option in the autocomplete menu.
 * When a value is provided means that a integration entry is displayed, and then shows
 * the integration entry name.
 */
export function IntegrationAutocompleteItem({
  title,
  inputValue,
}: IntegrationAutocompleteItemProps): JSX.Element {
  const highlightedLabel = useMemo(() => {
    /** Early return if there is no input value entered */
    if (!inputValue) {
      return title;
    }

    // Finding the match parts
    const matches = AutosuggestHighlightMatch(title, inputValue);
    const parts = AutosuggestHighlightParse(title, matches);

    return (
      <>
        {parts.map((part, index) => (
          <span className={part.highlight ? HIGHLIGHT_CLASS : ""} key={index}>
            {part.text}
          </span>
        ))}
      </>
    );
  }, [title, inputValue]);

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        paddingX: "10px",
        paddingY: "5px",
        width: "100%",
      }}
    >
      <Box
        component="var"
        sx={{
          marginLeft: "10px",
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          width: "100%",
          ...withEllipsis,
          [`& .${HIGHLIGHT_CLASS}`]: {
            fontWeight: "bold",
          },
        }}
      >
        {highlightedLabel}
      </Box>
    </Box>
  );
}
