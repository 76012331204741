import { getProjectApiClient } from "@api/project-api/project-api-utils";
import { FaroSwitch } from "@components/common/faro-switch";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { useToast } from "@hooks/use-toast";
import {
  FeatureSettingLayout,
  TOOLTIP_TEXT_CHECKED,
  TOOLTIP_TEXT_NOT_CHECKED,
} from "@src/pages/project-details/project-settings/feature-setting-layout";
import {
  fetchingProjectsFlagsSelector,
  isSelectedProjectEditableSelector,
  selectedProjectSelector,
  shouldDisplayMarkupsForViewersSelector,
} from "@store/projects/projects-selector";
import { updateProjectSettings } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { isProjectDemo } from "@utils/project-utils";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useMemo } from "react";

/** Setting to display annotations for users with viewer roles */
export function SettingDisplayForViewers(): JSX.Element {
  const { showToast } = useToast();
  const selectedProject = useAppSelector(selectedProjectSelector);
  const isChecked = useAppSelector(shouldDisplayMarkupsForViewersSelector);
  const { isUpdatingProjectSettings, isFetchingProjectSettings } =
    useAppSelector(fetchingProjectsFlagsSelector);
  const isProjectEditable = useAppSelector(isSelectedProjectEditableSelector());
  const { canEditProjectSettings } = useHasUserValidRoleProjectLevel({
    selectedProject,
  });
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();

  const isDisabled = useMemo(() => {
    return (
      !isProjectEditable ||
      !canEditProjectSettings ||
      !selectedProject ||
      isProjectDemo(selectedProject) ||
      isFetchingProjectSettings ||
      isUpdatingProjectSettings
    );
  }, [
    canEditProjectSettings,
    isFetchingProjectSettings,
    isProjectEditable,
    isUpdatingProjectSettings,
    selectedProject,
  ]);

  const onChange = useCallback(
    async (
      _: React.ChangeEvent<HTMLInputElement>,
      shouldCheck: boolean
      // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
    ): Promise<void> => {
      // eslint-disable-next-line @typescript-eslint/require-await -- Please review lint error
      trackEvent({
        name: ProjectEvents.updateProjectSettings,
        props: {
          setting: "display-annotations-for-viewers",
          isSettingEnabled: shouldCheck,
        },
      });

      try {
        if (!selectedProject) {
          throw new Error("Selected project is undefined");
        }

        const projectApiClient = getProjectApiClient({
          projectId: selectedProject.id,
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
        dispatch(
          updateProjectSettings({
            projectApiClient,
            payload: {
              shouldDisplayMarkupsForViewers: shouldCheck,
            },
          })
        );
      } catch (error) {
        showToast({
          message: "Cannot update project settings",
          description: getErrorDisplayMarkup(error),
          type: "error",
        });
      }
    },
    [dispatch, selectedProject, showToast, trackEvent]
  );

  return (
    <FeatureSettingLayout
      featureName="Project viewers"
      actionComponent={
        <FaroSwitch
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
          onChange={onChange}
          isLoading={isUpdatingProjectSettings}
          disabled={isDisabled}
          checked={isChecked}
          tooltipTextChecked={TOOLTIP_TEXT_CHECKED}
          tooltipTextNotChecked={TOOLTIP_TEXT_NOT_CHECKED}
          sx={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
        />
      }
    />
  );
}
