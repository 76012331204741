import { TeamsTable } from "@components/table/teams/teams-table";
import { TeamsDetails } from "@pages/members/teams/teams-details";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import {
  fetchingTeamsFlagsSelector,
  teamsSelector,
} from "@store/teams/teams-selector";
import { fetchTeams } from "@store/teams/teams-slice-thunk";
import { useEffect, useMemo } from "react";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { searchSelector } from "@store/ui/ui-selector";
import { useDebounce } from "@utils/time-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SearchbarEvents } from "@utils/track-event/track-event-list";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import {
  TEAM_DISPLAY_NAME,
  TEAMS_DISPLAY_NAME,
} from "@src/constants/team-constants";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppParams } from "@router/router-helper";
import { TeamsPageBulkActions } from "@pages/members/teams/teams-page-bulk-actions";
import { NotFoundPage } from "@pages/not-found-page";
import MembersIcon from "@assets/icons/members-duo-color.svg?react";
import { CreateTeam } from "@pages/members/teams/create-team";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

/**
 * The component that will render the kpi details and the table for the teams
 */
export function Teams(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();
  const dispatch = useAppDispatch();
  const teams = useAppSelector(teamsSelector);
  const { status } = useAppSelector(fetchingTeamsFlagsSelector);
  const { searchText } = useAppSelector(searchSelector);
  const { trackEvent } = useTrackEvent();

  const { canCreateNewTeam } = useHasUserValidRoleCompanyLevel();

  // Fetch teams
  useEffect(() => {
    if (companyId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(fetchTeams({ coreApiClient, companyId }));
    }
  }, [companyId, coreApiClient, dispatch]);

  // Constructing the empty page content if should show
  const emptyPageContent = useMemo(() => {
    if (teams.length || status === FetchingStatus.pending) {
      return null;
    }

    if (status === FetchingStatus.rejected) {
      return (
        <EmptyPage
          title="Error"
          subtitle={`Failed to fetch the ${TEAMS_DISPLAY_NAME}. Please reload the page and try again.`}
          icon={FailedIcon}
        />
      );
    }

    return (
      <EmptyPage
        title={`You don't have any ${TEAMS_DISPLAY_NAME} yet`}
        // eslint-disable-next-line max-len -- This is a string template
        subtitle={`You haven't added ${TEAMS_DISPLAY_NAME} to your workspace. Select the "new ${TEAM_DISPLAY_NAME}" button to create one.`}
        icon={MembersIcon}
        button={
          canCreateNewTeam && <CreateTeam shouldUseContainedButton={false} />
        }
      />
    );
  }, [canCreateNewTeam, status, teams.length]);

  /** Debounce interval in milliseconds */
  const DEBOUNCE_TIME = 300;

  // Uses useDebounce hook to react to changes on the search input text.
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_TIME);

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        name: SearchbarEvents.searchForTeam,
      });
    }
  }, [debouncedSearchText, trackEvent]);

  if (!companyId) {
    return <NotFoundPage />;
  }

  const buttonComponents = TeamsPageBulkActions({
    companyId,
    teams,
  });

  return (
    emptyPageContent ?? (
      <>
        <TeamsDetails />

        <TeamsTable
          teams={teams}
          isLoading={status === FetchingStatus.pending}
          requiredColumns={[
            TeamsHeaders.name,
            TeamsHeaders.users,
            TeamsHeaders.creationDate,
            TeamsHeaders.options,
          ]}
          bulkActionButtons={buttonComponents}
        />
      </>
    )
  );
}
