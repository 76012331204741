import { useCoreApiClient } from "@api/use-core-api-client";
import { Grid } from "@mui/material";
import { AnalyticsInfoBar } from "@pages/analytics/analytics-info-bar";
import { useAppParams } from "@router/router-helper";
import { fetchCompanyStats } from "@store/analytics/analytics-slice";
import { useAppDispatch } from "@store/store-helper";
import { useEffect } from "react";
import { AnalyticsCharts } from "@components/analytics/analytics-charts";

/** Renders workspace tabs in analytics page */
export function WorkspaceAnalytics(): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { companyId } = useAppParams();

  useEffect(() => {
    function fetchStats(): void {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(
        fetchCompanyStats({
          coreApiClient,
          companyId: companyId ?? "",
        })
      );
    }

    fetchStats();
  }, [coreApiClient, companyId, dispatch]);

  return (
    <Grid width="100%">
      <AnalyticsInfoBar />
      <AnalyticsCharts />
    </Grid>
  );
}
