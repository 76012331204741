import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Box, Stack, Typography } from "@mui/material";
import { IntegrationSections } from "@pages/integrations/integrations-types";
import FaroSphereLogoMini from "@assets/icons/faro-sphere-xg-logo-mini.svg?react";
import ArrowLeftRightIcon from "@assets/icons/new/arrow-left-right.svg?react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { IntegrationIcon } from "@pages/project-details/project-integrations/integration-icon";
import { memo } from "react";

interface Props {
  /** Selected integration ID */
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId;

  /** Callback while closing the dialog */
  onClose(): void;
}

/** Renders project integration connected dialog */
function ProjectIntegrationsConnectedDialogComponent({
  selectedIntegrationId,
  onClose,
}: Props): JSX.Element {
  return (
    <FaroDialog
      open={true}
      title=""
      onConfirm={onClose}
      confirmText="Got it"
      isSuccessMessage={true}
      fullWidth={true}
    >
      <Stack textAlign="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            marginY: "60px",
            marginX: "auto",
          }}
        >
          <IntegrationIcon icon={FaroSphereLogoMini} />
          <ArrowLeftRightIcon />
          <IntegrationIcon
            icon={IntegrationSections[selectedIntegrationId].icon}
          />
        </Box>

        <Typography
          sx={{
            fontSize: "20px",
            marginBottom: "10px",
          }}
        >
          <var>{IntegrationSections[selectedIntegrationId].displayName}</var> is
          sync and connected
        </Typography>

        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
          }}
        >
          Your Sphere XG project has been integrated with your{" "}
          <var>{IntegrationSections[selectedIntegrationId].displayName}</var>{" "}
          project
        </Typography>
      </Stack>
    </FaroDialog>
  );
}

export const ProjectIntegrationsConnectedDialog = memo(
  ProjectIntegrationsConnectedDialogComponent
);
