import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { Autocomplete, ListItem } from "@mui/material";
import { SyntheticEvent } from "react";
import { IntegrationAutocompleteItem } from "@pages/project-details/project-integrations/integration-autocomplete-item";
import {
  IntegrationCompany,
  IntegrationProject,
} from "@services/integrations-service/integrations-service-types";

interface Props {
  /** Selected value */
  value: IntegrationCompany | IntegrationProject | null;

  /** List of integration entry to show as autocomplete item */
  options: (IntegrationCompany | IntegrationProject)[];

  /** Callback function to update selected item */
  onChange(
    _: SyntheticEvent<Element, Event>,
    value: string | IntegrationCompany | IntegrationProject | null
  ): void;

  /** Placeholder text to show in autocomplete input autocomplete */
  placeholder: string;

  /** Flag to disable the autocomplete */
  shouldDisabled?: boolean;

  /** Flag to focus the autocomplete */
  shouldFocus?: boolean;
}

/** Renders autocomplete for integration entry */
export function IntegrationAutocomplete({
  value,
  options,
  onChange,
  shouldDisabled = false,
  shouldFocus = false,
  placeholder,
}: Props): JSX.Element {
  return (
    <Autocomplete
      value={value ?? ""}
      disabled={shouldDisabled}
      autoHighlight
      options={options}
      getOptionLabel={(option) => typeof option === "string" ? option : option.name}
      onChange={onChange}
      filterSelectedOptions
      freeSolo={true}
      autoSelect={false}
      clearOnBlur={false}
      renderInput={(params) => (
        <FaroSimpleTextField
          {...params}
          autoFocus={shouldFocus}
          variant="outlined"
          placeholder={placeholder}
          sx={{
            "& .MuiOutlinedInput-root": { paddingY: "2px" },
          }}
        />
      )}
      renderOption={(props, option, state) => {
        return (
          <ListItem
            {...props}
            sx={{ padding: "0" }}
            key={`listItem-${state.index}`}
          >
            <IntegrationAutocompleteItem
              title={option.name}
              inputValue={state.inputValue}
            />
          </ListItem>
        );
      }}
    />
  );
}
