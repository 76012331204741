import { Box, Typography } from "@mui/material";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { RateComponent } from "@components/common/rate-component";
import { useMemo, useState } from "react";
import { sphereColors } from "@styles/common-colors";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import { SphereSupportLink } from "@components/common/sphere-support-link";
import { useFeedback } from "@hooks/feedback/use-feedback";
import { ReactSetStateFunction } from "@custom-types/types";

interface Props {
  /** Flag whether the feedback dialog should be shown */
  shouldShowFeedbackDialog: boolean;

  /**
   * Function to control the visibility of the feedback dialog.
   *
   * @param value - A boolean that determines whether the feedback dialog should be shown (`true`) or hidden (`false`).
   */
  setShouldShowFeedbackDialog: ReactSetStateFunction<boolean>;

  /**
   * Optional name of the feature that the feedback is about.
   *
   * @default "General"
   */
  featureName?: string;

  /**
   * Optional text for the confirm button
   *
   * @default "Submit"
   */
  confirmText?: string;

  /** Optional text for the cancel button */
  cancelText?: string;

  /**
   * Optional text for the feedback question
   *
   * @default "Want to tell us more?"
   */
  feedbackQuestionText?: string;

  /** Optional function to execute when the user submits feedback */
  onSubmitFeedback?: () => void;

  /** Optional function to execute when the user cancels the feedback */
  onCancelFeedback?: () => void;

  /**
   * Optional function to execute when the user closes the feedback dialog via the X button in the top right corner.
   * If not provided, the dialog will not show the X button in the top right corner.
   */
  onCloseFeedback?: () => void;
}

/**
 * SendFeedbackDialog is a modal component that allows users to provide feedback on their experience with the dashboard.
 */
export function SendFeedbackDialog({
  shouldShowFeedbackDialog,
  setShouldShowFeedbackDialog,
  featureName = "General",
  confirmText = "Submit",
  cancelText,
  feedbackQuestionText = "Want to tell us more?",
  onSubmitFeedback,
  onCancelFeedback,
  onCloseFeedback,
}: Props): JSX.Element {
  const { sendFeedback } = useFeedback();

  /** Whether the sending a request hence the submit button should be shown as loading */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Stores the selected rating */
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  /** Stores the feedback text */
  const [feedbackText, setFeedbackText] = useState<string>("");

  /** Whether the submit button should disabled because the user didn't input anything yet */
  const isSubmitDisabled = useMemo(() => {
    return !selectedRating && !feedbackText;
  }, [selectedRating, feedbackText]);

  /** Cleans up the state and closes the dialog */
  function onClose(): void {
    setSelectedRating(null);
    setFeedbackText("");
    setShouldShowFeedbackDialog(false);
  }

  /** Submits the feedback */
  async function onConfirm(): Promise<void> {
    setIsLoading(true);
    await sendFeedback({
      featureName,
      feedbackMessage: feedbackText,
      selectedRating,
    });
    onClose();

    onSubmitFeedback?.();

    // Sets the loading to false after the dialog is closed to avoid a flickering effect
    // and to make sure the user doesn't click the button again.
    setIsLoading(false);
  }

  return (
    <FaroDialog
      title="How was your experience with the dashboard?"
      open={shouldShowFeedbackDialog}
      isConfirmLoading={isLoading}
      isConfirmDisabled={isSubmitDisabled}
      closeText={cancelText}
      confirmText={confirmText}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => {
        onCloseFeedback?.();
        onClose();
      }}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onConfirm={onConfirm}
      onCancelFeedback={() => {
        onCancelFeedback?.();
        onClose();
      }}
      shouldForceShowCloseButton={!!onCloseFeedback}
    >
      <Box component="div">
        {/* Wrapper for the rating component */}
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            mb: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          <RateComponent value={selectedRating} onChange={setSelectedRating} />
        </Box>

        {/* Wrapper for the feedback text label */}
        <Box
          component="div"
          sx={{
            width: "100%",
            fontSize: "14px",
            color: sphereColors.gray800,
            mb: "4px",
          }}
        >
          {feedbackQuestionText}
        </Box>

        {/* Wrapper for the feedback text input */}
        <Box
          component="div"
          sx={{
            width: "100%",
          }}
        >
          <FaroSimpleTextField
            hiddenLabel={true}
            multiline={true}
            fullWidth={true}
            placeholder="Enter feedback…"
            rows={4}
            onChange={(event) => {
              setFeedbackText(event.target.value);
            }}
          />
          <Typography
            sx={{
              fontSize: DEFAULT_TEXT_FONT_SIZE,
              marginTop: "4px",
            }}
          >
            If you need help with a specific problem, please contact{" "}
            <SphereSupportLink />
          </Typography>
        </Box>
      </Box>
    </FaroDialog>
  );
}
