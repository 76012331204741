import { FaroButton } from "@components/common/faro-button";
import { Dialog, Grid, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { FaroClickableIcon } from "@components/common/faro-clickable-icon";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { SlideProgressIndicator } from "@components/splash-screen/slide-progress-indicator";
import { sphereColors } from "@styles/common-colors";
import { SphereDivider } from "@components/common/sphere-divider";
import {
  SplashScreenContent,
  SplashScreenProps,
} from "@components/splash-screen/splash-screen-utils";
import { useAppDispatch } from "@store/store-helper";
import { setSplashScreenStatus, setFueFlags } from "@store/user/user-slice";
import { FaroTextButton } from "@components/common/faro-text-button";
import { useMediaQueryList } from "@hooks/use-media-query";
import { TutorialEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import { ImageRenderer } from "@components/common/image-renderer";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** The splash screen that needs to be shown to user */
  splashScreen: SplashScreenProps;

  /** Callback to run when splash screen is closed either by finishing it or skipping it */
  onSplashScreenClosed: () => void;
}

// TODO: The value is the same as SPACE_OUTSIDE_OF_MODAL. Refactoring FaroDialog to cover splash-screen makes sense.
/** Defines the side padding for the dialog */
const DIALOG_PADDING = "32px";

/** Shows a splash screen of the given topic */
export function SplashScreen({
  splashScreen,
  onSplashScreenClosed,
}: Props): JSX.Element {
  const [activeScreenNumber, setActiveScreenNumber] = useState<number>(0);
  const { isScreenMdAndLarger } = useMediaQueryList();
  const coreApiClient = useCoreApiClient();
  const { trackEvent } = useTrackEvent();

  const dispatch = useAppDispatch();

  /** The content that is currently shown to user */
  const activeScreenContent: SplashScreenContent = useMemo(
    () => splashScreen.content[activeScreenNumber],
    [activeScreenNumber, splashScreen]
  );

  /** Click handler for clicking on a slide indicator */
  function onSlideIndicatorClicked(selectedScreenNumber: number): void {
    trackEvent({
      name: TutorialEvents.jumpToPage,
      props: { topic: splashScreen.topic },
    });
    setActiveScreenNumber(selectedScreenNumber);
  }

  /** Shows the X button to close the dialog. Can be placed on different places depending on the screen size */
  const closeButton = (
    <FaroClickableIcon
      component={CloseIcon}
      onClick={onSkipButtonClicked}
      iconSize="20px"
    />
  );

  function onNextButtonClicked(): void {
    // If the user is on the last screen, then reset the active screen number
    if (activeScreenNumber === splashScreen.content.length - 1) {
      trackEvent({
        name: TutorialEvents.finishTutorial,
        props: { topic: splashScreen.topic },
      });

      setActiveScreenNumber(0);
      dispatch(
        setSplashScreenStatus({
          topic: splashScreen.topic,
          status: "completed",
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      dispatch(setFueFlags({ coreApiClient }));
      onSplashScreenClosed();
    } else {
      trackEvent({
        name: TutorialEvents.selectNextPage,
        props: { topic: splashScreen.topic },
      });
      setActiveScreenNumber((prevValue) => prevValue + 1);
    }
  }

  function onSkipButtonClicked(): void {
    trackEvent({
      name: TutorialEvents.skipTutorial,
      props: { topic: splashScreen.topic },
    });

    dispatch(
      setSplashScreenStatus({
        topic: splashScreen.topic,
        status: "skipped",
      })
    );
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    dispatch(setFueFlags({ coreApiClient }));
    onSplashScreenClosed();
  }

  return (
    <Dialog open={true} fullWidth={true} maxWidth="lg">
      <Grid container alignItems={{ xs: "center", md: "normal" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: sphereColors.gray100,
            padding: DIALOG_PADDING,
          }}
        >
          <Grid item container>
            {!isScreenMdAndLarger && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                {closeButton}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ImageRenderer
                image={{
                  src: activeScreenContent.image.src,
                  altText: activeScreenContent.image.altText,
                }}
                sx={{
                  height: {
                    xs: "200px",
                    md: "440px",
                  },
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          xs={12}
          md={6}
          justifyContent="space-between"
          sx={{
            padding: DIALOG_PADDING,
          }}
        >
          <Grid container direction="column">
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: "24px" }}
            >
              <Typography
                sx={{
                  fontSize: 10,
                  color: sphereColors.gray600,
                  fontWeight: "bold",
                }}
              >
                Step {activeScreenNumber + 1} of {splashScreen.content.length}
              </Typography>
              {isScreenMdAndLarger && closeButton}
            </Grid>
            <Grid item sx={{ mb: "20px" }}>
              <Typography sx={{ fontSize: 20, color: sphereColors.gray800 }}>
                {activeScreenContent.title}
              </Typography>
              <SphereDivider sx={{ mt: "24px", mb: "32px" }} />
              <Typography sx={{ fontSize: 16, color: sphereColors.gray800 }}>
                {activeScreenContent.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FaroTextButton
              onClick={() => {
                trackEvent({
                  name: TutorialEvents.selectPreviousPage,
                  props: { topic: splashScreen.topic },
                });
                setActiveScreenNumber((prevValue) => prevValue - 1);
              }}
              sx={{
                visibility: activeScreenNumber === 0 ? "hidden" : "visible",
                fontWeight: "600",
              }}
            >
              Back
            </FaroTextButton>
            <SlideProgressIndicator
              activeScreenNumber={activeScreenNumber}
              totalNumberOfScreens={splashScreen.content.length}
              onSlideIndicatorClicked={onSlideIndicatorClicked}
            />
            <FaroButton onClick={onNextButtonClicked}>
              {activeScreenNumber === splashScreen.content.length - 1
                ? "Done"
                : "Next"}
            </FaroButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
