import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroSwitch } from "@components/common/faro-switch";
import { useAppParams } from "@router/router-helper";
import { APITypes } from "@stellar/api-logic";
import {
  getProjectByIdSelector,
  isFeatureEnabledForProjectSelector,
  isSelectedProjectEditableSelector,
} from "@store/projects/projects-selector";
import { editProjectFeature } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { isProjectDemo } from "@utils/project-utils";
import { useState } from "react";
import {
  FeatureSettingLayout,
  TOOLTIP_TEXT_CHECKED,
  TOOLTIP_TEXT_NOT_CHECKED,
} from "@pages/project-details/project-settings/feature-setting-layout";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { AvailableLinks } from "@components/external-links";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/** Renders 2d Photo feature setting */
export function Feature2DPhotos({
  projectId,
}: BaseProjectIdProps): JSX.Element | null {
  const { companyId } = useAppParams();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));
  const isProjectEditable = useAppSelector(isSelectedProjectEditableSelector());
  const is2DPhotoEnabled = useAppSelector(
    isFeatureEnabledForProjectSelector(
      projectId,
      APITypes.EUserSubscriptionRole.ft_image_2d
    )
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { canEditProjectSettings } = useHasUserValidRoleProjectLevel({
    selectedProject,
  });

  /** Triggers when setting toggle button is clicked */
  async function onChangeFeatureSetting(
    shouldEnableImage2d: boolean
  ): Promise<void> {
    // Track event feature toggling
    trackEvent({
      name: ProjectEvents.adjustFeatureSettings,
      props: {
        featureName: APITypes.EUserSubscriptionRole.ft_image_2d,
        isFeatureEnabled: shouldEnableImage2d,
      },
    });

    if (companyId && selectedProject) {
      setIsLoading(true);

      const payload = {
        [APITypes.EProjectFeatureKey.ft_image_2d]: shouldEnableImage2d,
      };

      try {
        await dispatch(
          editProjectFeature({
            coreApiClient,
            companyId,
            selectedProject,
            payload,
          })
        );
      } catch (error) {
        handleErrorWithToast({
          id: `edit2dPhotoSetting-${Date.now().toString()}`,
          title: "Error changing 2d Photo setting",
          error,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  // Early return if selected project is not available
  if (!selectedProject) {
    return null;
  }

  const actionComponent = (
    <FaroSwitch
      disabled={
        !isProjectEditable ||
        isProjectDemo(selectedProject) ||
        !canEditProjectSettings
      }
      checked={is2DPhotoEnabled}
      isLoading={isLoading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onChange={(event, isChecked) => onChangeFeatureSetting(isChecked)}
      tooltipTextNotChecked={TOOLTIP_TEXT_NOT_CHECKED}
      tooltipTextChecked={TOOLTIP_TEXT_CHECKED}
      sx={{ cursor: !isProjectEditable ? "not-allowed" : "pointer" }}
    />
  );

  return (
    <FeatureSettingLayout
      featureName="2D Photos"
      featureDescription="Enables the use of 2D photos for this project."
      learnMoreUrl={AvailableLinks.photos2dHelp}
      actionComponent={actionComponent}
    />
  );
}
