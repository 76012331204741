import { PageSectionProps } from "@components/page/page-section";
import { Box, SvgIcon } from "@mui/material";
import {
  defaultProjectLaunchTargetSelector,
  fetchingSdbCompanyFlagsSelector,
  isFetchingSdbCompanySelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { SphereTooltipIcon } from "@components/common/sphere-tooltip-icon";
import InfoIcon from "@assets/icons/new/info_20px.svg?react";
import { useAppServer } from "@hooks/use-app-server";
import { inputPlaceholderItalicSx, withEllipsis } from "@styles/common-styles";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { useBrandingSettings } from "@hooks/use-branding-settings";
import { WorkspaceLogoSetting } from "@pages/settings/general-settings/workspace-logo-setting";
import { FaroSwitch } from "@components/common/faro-switch";
import { useEffect } from "react";
import { useDefaultPlatformSection } from "@pages/settings/general-settings/use-default-platform-section";
import { BaseSettingsPage } from "@pages/settings/base-settings-page";

/**
 * Component that shows the general settings, including workspace name and server.
 */
export function GeneralSettings(): JSX.Element {
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const { serverDisplayName, serverIcon } = useAppServer();
  const {
    brandingSettings,
    onChangeBrandingSettings,
    fetchBrandingSettings,
    hasBrandingSettings,
  } = useBrandingSettings();
  const isLoading = useAppSelector(isFetchingSdbCompanySelector);
  const { isUpdatingBrandingSettings } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const defaultPlatformSection = useDefaultPlatformSection();
  const { shouldShowOpenInWebEditorBtn } = useAppSelector(
    defaultProjectLaunchTargetSelector
  );

  // Fetch branding settings of the company
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    fetchBrandingSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only fetch once
  }, []);

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Workspace details",
      subtitle:
        "An overview of the details in your workspace.\nHere you can modify the settings to make them fit your needs.",
      items: [
        {
          label: "Workspace name",
          shouldAddRightPadding: true,
          content: (
            <Box
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              <Box
                component="div"
                sx={{
                  flexGrow: 1,
                  paddingRight: "10px",
                  ...withEllipsis,
                }}
              >
                <SphereTooltip
                  title={<var>{selectedSdbCompany?.name}</var>}
                  shouldSkipWrapper={true}
                >
                  <Box
                    component="var"
                    sx={{
                      ...withEllipsis,
                    }}
                  >
                    {selectedSdbCompany?.name}
                  </Box>
                </SphereTooltip>
              </Box>
              <Box component="div">
                <SphereTooltipIcon
                  text="Please contact support to request changes to the workspace name."
                  icon={InfoIcon}
                  size="21px"
                />
              </Box>
            </Box>
          ),
        },
        {
          label: "Server",
          shouldAddRightPadding: true,
          content: (
            <Box
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              <SvgIcon
                inheritViewBox={true}
                component={serverIcon}
                sx={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <Box component="div" marginLeft="10px">
                {serverDisplayName}
              </Box>
            </Box>
          ),
        },
      ],
    },

    // Default platform section
    defaultPlatformSection,

    {
      title: "HoloBuilder branding",
      additionalInfo: "ONLY IN HOLOBUILDER",
      subtitle: `Configure your company branding including your logo and company URL in the dashboard 
      to be added automatically to all your new and existing company projects.`,
      isHidden:
        !shouldShowOpenInWebEditorBtn || (!isLoading && !hasBrandingSettings),
      items: [
        {
          label: "Workspace URL",
          content: (
            <Box component="div" width="100%">
              <FaroTextField
                placeholder="https://example.com/"
                initialValue={brandingSettings.logo.target}
                onConfirmed={(value) =>
                  onChangeBrandingSettings(value, "target")
                }
                isFullWidth={true}
                faroVariant="row"
                isReadOnly={false}
                minInputLength={0}
                sx={{ ...inputPlaceholderItalicSx }}
              />
            </Box>
          ),
        },
        {
          label: "Show HoloBuilder logo",
          shouldAddRightPadding: true,
          content: (
            <Box
              component="div"
              width="100%"
              display="flex"
              justifyContent="end"
            >
              <Box component="div" width="50px" display="flex">
                <FaroSwitch
                  disabled={false}
                  isLoading={isUpdatingBrandingSettings}
                  checked={brandingSettings.logo.showHoloBuilderLogo}
                  onChange={(event, isChecked) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
                    onChangeBrandingSettings(isChecked, "showHoloBuilderLogo");
                  }}
                />
              </Box>
            </Box>
          ),
        },
        {
          shouldHideBottomBorder: true,
          shouldContentTakeFullWidth: true,
          content: (
            <Box component="div" marginTop="240px" width="100%">
              <WorkspaceLogoSetting />
            </Box>
          ),
        },
      ],
    },
  ];
  const filteredPageSectionProps = pageSectionsProps.filter(
    (pageSectionProps) => !pageSectionProps.isHidden
  );

  return (
    <BaseSettingsPage
      pageSectionProps={filteredPageSectionProps}
      isLoading={isLoading}
      dataTestId="workspace-settings-general"
    />
  );
}
