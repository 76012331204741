import { Box, SvgIcon } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { ElementType } from "react";

interface Props {
  /** The icon to show */
  icon: ElementType;
}

/** Renders icon for the integrations */
export function IntegrationIcon({ icon }: Props): JSX.Element {
  return (
    <Box
      sx={{
        width: "95px",
        height: "95px",
        backgroundColor: sphereColors.gray50,
        display: "grid",
        placeItems: "center",
        borderRadius: "10px",
        boxShadow: `0 1px 0 0 ${sphereColors.gray200}`,
      }}
    >
      <SvgIcon
        inheritViewBox={true}
        component={icon}
        sx={{
          width: "65px",
          height: "65px",
        }}
      />
    </Box>
  );
}
