import { useCoreApiClient } from "@api/use-core-api-client";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { LabelWithHelp } from "@components/common/label-with-help";
import {
  MemberAutocomplete,
  SelectedMember,
} from "@components/common/member-autocomplete/member-autocomplete";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { ReactSetStateFunction } from "@custom-types/types";
import { Stack } from "@mui/material";
import { SelectGroup } from "@pages/members/select-group";
import { useAppParams } from "@router/router-helper";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

interface Props {
  /** The selected group ID */
  selectedGroupId: string;

  /** Setter for selectedGroupId */
  setSelectedGroupId: ReactSetStateFunction<string>;

  /** The selected project manager */
  selectedProjectManager: SelectedMember;

  /** Setter for setSelectedProjectManager */
  setSelectedProjectManager: ReactSetStateFunction<SelectedMember>;
}

/** List of groups and project managers with selecting ability */
export function GroupAndProjectManagerSelection({
  selectedGroupId,
  setSelectedGroupId,
  selectedProjectManager,
  setSelectedProjectManager,
}: Props): JSX.Element {
  const { companyId } = useAppParams();
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();

  const [groupMembers, setGroupMembers] = useState<
    SphereDashboardAPITypes.IGroupMemberDetails[]
  >([]);

  // Triggered every time a group is selected.
  // It fetches the members that belong to that group and stores them
  // in the members variable.
  useEffect(() => {
    async function onGroupSelected(groupId: string | number): Promise<void> {
      if (!companyId) {
        return undefined;
      }

      // As soon as you select a different group, empty the members array.
      // TODO: This should be moved to the group slice as createAsyncThunk: https://faro01.atlassian.net/browse/ST-698
      setGroupMembers([]);
      try {
        const { members } = await coreApiClient.V3.SDB.getGroupById(
          companyId,
          groupId
        );

        const membersResult = members ?? [];
        setGroupMembers(membersResult);
      } catch (error) {
        handleErrorWithToast({
          id: `getGroupById-${Date.now().toString()}`,
          title: "Cannot get the group details",
          error,
        });
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    onGroupSelected(selectedGroupId);
  }, [companyId, coreApiClient, handleErrorWithToast, selectedGroupId]);

  return (
    <>
      <Stack sx={{ mb: SPACE_ELEMENTS_OF_MODAL }}>
        <SelectGroup
          onChange={(id) => {
            setSelectedGroupId(id.toString());
            setSelectedProjectManager(null);
          }}
          selectedGroupId={selectedGroupId}
          isRequired={false}
        />
      </Stack>
      <Stack sx={{ mb: SPACE_ELEMENTS_OF_MODAL }}>
        <LabelWithHelp title="Manager" isDisabled={!selectedGroupId} />
        <MemberAutocomplete
          members={groupMembers}
          selectedMember={selectedProjectManager}
          onOptionChange={setSelectedProjectManager}
          isDisabled={!selectedGroupId}
          placeholder="Choose who can manage this project..."
        />
      </Stack>
    </>
  );
}
