import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { CircularProgress } from "@faro-lotv/flat-ui";
import { useAppSelector } from "@store/store-helper";
import { UsersTabs } from "@router/route-params";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { Teams } from "@pages/members/teams/teams";
import { MembersPage } from "@pages/members/members-page";
import { useMemo } from "react";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";
import { capitalizeFirstLetter } from "@utils/string-utils";

/** Content that will have the logic for the users tabs */
export function MembersWorkspace(): JSX.Element {
  const { usersTab } = useAppParams();
  const { companyId } = useAppParams();
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const { canViewTeams } = useHasUserValidRoleCompanyLevel();

  const shouldShowTeamsTab = useMemo(
    () => isAlphaTestingEnabled && canViewTeams,
    [canViewTeams, isAlphaTestingEnabled]
  );

  const usersTabs = [
    {
      label: "Members",
      route: UsersTabs.members,
      content: () => <MembersPage />,
      loadingContent: <CircularProgress />,
    },
  ];

  if (
    !companyId ||
    !usersTab ||
    !Object.values(UsersTabs).includes(usersTab) ||
    (usersTab === UsersTabs.teams && !shouldShowTeamsTab)
  ) {
    return <NotFoundPage />;
  }

  if (shouldShowTeamsTab) {
    usersTabs.push({
      label: capitalizeFirstLetter(TEAMS_DISPLAY_NAME),
      route: UsersTabs.teams,
      content: () => <Teams />,
      loadingContent: <CircularProgress />,
    });
  }

  return (
    <TabsWithRoutes
      dataTestId="USER_CONTAINER"
      selectedTab={usersTab}
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openWorkspaceUsersTab}
      tabs={usersTabs}
    />
  );
}
