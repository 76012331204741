import { formatUserRoleType } from "@utils/data-display";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCoreApiClient } from "@api/use-core-api-client";
import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { useToast } from "@hooks/use-toast";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { updateMemberRoleInProjectSingle } from "@store/projects/projects-slice-thunk";
import { isSelectedProjectEditableSelector } from "@store/projects/projects-selector";
import { RoleChangeSuccessDescription } from "@components/table/members/members-column/role-column/role-change-success-description";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { isSuccessResponse } from "@context-providers/error-boundary/error-boundary-utils";
import { SelectProjectRole } from "@components/role/select-project-role";
import { currentUserSelector } from "@store/user/user-selector";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { hasUserValidCompanyRole } from "@utils/access-control/company/company-access-control";
import { useMemo } from "react";

interface Props extends BaseCompanyIdProps, BaseProjectIdProps {
  /** The member to show/adjust the role. */
  member: SphereDashboardAPITypes.IProjectMemberBase;
}

/** Render and adjust member roles in project context. */
export function ProjectMemberRole({
  companyId,
  projectId,
  member,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const isProjectEditable = useAppSelector(isSelectedProjectEditableSelector());
  const currentUser = useAppSelector(currentUserSelector);
  const { canChangeRole } = useHasUserValidPermissionProjectLevel();

  /** Determine if the change role dropdown should be hidden */
  const shouldHideDropdown = useMemo(() => {
    const isCompanyAdmin = hasUserValidCompanyRole({
      currentUser,
      requiredCompanyRoles: [CoreAPITypes.EUserCompanyRole.companyExecutive],
    });

    const isCurrentUserProjectAdmin =
      member.id === currentUser?.id &&
      member.role === CoreAPITypes.EUserProjectRole.admin;

    const isResourceOwner = member.role === CoreAPITypes.EUserProjectRole.owner;

    /**
     * Should hide role change dropdown if:
     * - Project is not editable
     * - User doesn't have a valid role to change
     * - Current user is a project admin of this project
     * - Current user isn't a company admin
     * - Member is resource owner
     */
    const shouldHideDropdown =
      (!isProjectEditable ||
        !canChangeRole ||
        isCurrentUserProjectAdmin ||
        isResourceOwner) &&
      !isCompanyAdmin;

    return shouldHideDropdown;
  }, [canChangeRole, currentUser, isProjectEditable, member.id, member.role]);

  // Return non-editable member role
  if (shouldHideDropdown) {
    return (
      <span style={{ fontSize: DEFAULT_TEXT_FONT_SIZE }}>
        {formatUserRoleType(member.role)}
      </span>
    );
  }

  /** Trigger updating member role and show success message */
  async function onRoleChange(
    role: CoreAPITypes.EUserProjectRole
  ): Promise<void> {
    const result = await dispatch(
      updateMemberRoleInProjectSingle({
        coreApiClient,
        companyId,
        projectId,
        role,
        identity: member.identity,
      })
    );

    // Errors are already handled by the slice, only notify about a successful result
    if (isSuccessResponse(result)) {
      showToast({
        message: "Role Changed",
        description: (
          <RoleChangeSuccessDescription role={formatUserRoleType(role)} />
        ),
        type: "success",
      });
    }
  }

  return (
    <SelectProjectRole
      selectedRole={
        member.role as SphereDashboardAPITypes.IAssignmentProjectRole
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onChange={onRoleChange}
      isTableCell={true}
    />
  );
}
