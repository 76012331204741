import { ErrorCard } from "@components/progress-overview/error-card";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { CardLayout } from "@components/progress-overview/card-layout";
import { FileUploadTask } from "@custom-types/file-upload-types";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { LinearProgress, Stack } from "@mui/material";
import { RemainingTimeLabel } from "@faro-lotv/flat-ui";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { useAppSelector } from "@store/store-helper";
import { useCancelUpload } from "@hooks/upload-tasks/use-cancel-upload";

interface TaskCardProps {
  /** The task to show */
  task: FileUploadTask;
}

/** Renders a task in the menu card to notify the user of a progress item */
export function ProgressCard({ task }: TaskCardProps): JSX.Element {
  const project = useAppSelector(
    getProjectByIdSelector(task.context.projectId)
  );
  const cancelUploadTask = useCancelUpload();

  /** Return failed card if the task is failed */
  if (task.status === BackgroundTaskState.failed) {
    return <ErrorCard task={task} />;
  }

  return (
    <CardLayout
      name={task.fileName}
      projectName={project?.name}
      menu={[
        {
          name: "Cancel Upload",
          // We don't need to await the async cancelling, we just need to trigger it.
          callback: () => void cancelUploadTask(task.id),
          color: "error",
        },
      ]}
    >
      <LinearProgress
        value={task.progress}
        variant={task.progress > 0 ? "determinate" : "indeterminate"}
      />

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          color: `${sphereColors.gray800}E6`,
        }}
      >
        Uploading... &nbsp;
        {task.expectedEnd && (
          <>
            &nbsp;(
            <RemainingTimeLabel
              expectedEnd={task.expectedEnd}
              sx={{
                color: `${sphereColors.gray800}E6`,
                fontWeight: "600",
              }}
            />
            )
          </>
        )}
      </Stack>
    </CardLayout>
  );
}
