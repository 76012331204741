import { useCallback } from "react";
import { getProjectApiClient } from "@api/project-api/project-api-utils";
import {
  ElsScanFileUploadTaskContext,
  MultiUploadedFileResponse,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { useAddScansToRevisionAndMergeToMain } from "@hooks/data-management/use-add-scans-to-revision-and-merge-to-main";
import { useHandleAllUploadsCanceled } from "@hooks/data-management/use-handle-all-uploads-canceled";
import { useHandleAllUploadsFailed } from "@hooks/data-management/use-handle-all-uploads-failed";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

type ReturnFunction = (
  uploadedResponse: MultiUploadedFileResponse,
  context: ElsScanFileUploadTaskContext
) => Promise<void>;

/** Callback after all files were uploaded or canceled. */
export function useOnUploadComplete(project: SdbProject): ReturnFunction {
  const { trackEvent } = useTrackEvent();
  const handleAllUploadsFailed = useHandleAllUploadsFailed();
  const handleAllUploadsCanceled = useHandleAllUploadsCanceled();
  const addScansToRevisionAndMergeToMain = useAddScansToRevisionAndMergeToMain(project);

  return useCallback(
    async (
      uploadedResponse: MultiUploadedFileResponse,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      const projectApiClient = getProjectApiClient({
        projectId: context.projectId,
      });

      const successfulUploads = uploadedResponse.successful;
      const failedUploads = uploadedResponse.failed;
      const canceledUploads = uploadedResponse.canceled;

      trackEvent({
        name: DataManagementEvents.finishUpload,
        props: {
          successfulUploads: successfulUploads.length,
          failedUploads: failedUploads.length,
          canceledUploads: canceledUploads.length,
        },
      });

      // Handle case when there are zero successful uploads
      if (!successfulUploads.length) {
        // If there are failed uploads consider it an all-failed case
        if (failedUploads.length) {
          return handleAllUploadsFailed(
            projectApiClient,
            context.registrationRevisionId
          );
        }

        // If there are zero failed uploads and some canceled uploads consider it an all-canceled case
        if (!failedUploads.length && canceledUploads.length) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.registrationRevisionId
          );
        }
      }

      // Handle case when there were successful uploads.
      // The function will also check again if the revision was already canceled.
      if (successfulUploads.length) {
        const canceledImport = !(await addScansToRevisionAndMergeToMain(
          successfulUploads,
          failedUploads,
          projectApiClient,
          context
        ));
        if (canceledImport) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.registrationRevisionId,
            true
          );
        }
      }
    },
    [
      addScansToRevisionAndMergeToMain,
      handleAllUploadsCanceled,
      handleAllUploadsFailed,
      trackEvent,
    ]
  );
}
