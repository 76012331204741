import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { FaroTextButton } from "@components/common/faro-text-button";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { Typography } from "@mui/material";
import { isProcessingSelector } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { colorConst, sphereColors } from "@styles/common-colors";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useState } from "react";
import { ButtonProps } from "@mui/material";
import { startRegistration } from "@pages/project-details/project-data-management/register-data-utils";

interface Props extends ButtonProps {
  /** True if the button is only shown because of dev mode. */
  isDevMode?: boolean;
}

/** Renders a button that triggers the data preparation workflow */
export function RegisterDataButton({ isDevMode = false }: Props): JSX.Element {
  const { projectId } = useDataManagementContext();
  const { trackEvent } = useTrackEvent();
  const { createDialog } = useDialog();
  const { showToast } = useToast();
  const { handleErrorWithToast, handleErrorSilently } = useErrorContext();
  const currentUser = useAppSelector(currentUserSelector);
  const hasUploadedDataProcessing = useAppSelector(isProcessingSelector);
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const progressApiClient = useProgressApiClient({
    projectId: projectId.toString(),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Triggers a multi cloud registration.
   * First checks if there are still scans being processed and shows a warning to the user.
   * After the multi cloud registration has successfully started the cloud registration tasks
   * are fetched in order to show the registration progress to the user.
   */
  const onClick = useCallback(async () => {
    let shouldRegisterData = true;

    if (hasUploadedDataProcessing) {
      shouldRegisterData = await createDialog(
        {
          title: "Register data",
        },
        <>
          <Typography
            sx={{
              marginBottom: "24px",
            }}
          >
            Some scans are still processing. If you continue they won't be
            included in the data preparation process.
          </Typography>
          <Typography>Do you want to continue?</Typography>
        </>
      );
    }

    if (shouldRegisterData && projectId && currentUser && currentUser.id) {
      await startRegistration({
        projectId,
        userId: currentUser.id,
        progressApiClient,
        projectApiClient,
        trackEvent,
        setIsLoading,
        showToast,
        dispatch,
        handleErrorSilently,
        handleErrorWithToast,
      });
    }
  }, [
    createDialog,
    currentUser,
    handleErrorWithToast,
    hasUploadedDataProcessing,
    projectId,
    showToast,
    trackEvent,
    dispatch,
    handleErrorSilently,
    progressApiClient,
    projectApiClient,
  ]);

  return (
    <FaroTextButton
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onClick={onClick}
      tooltipText="Register data."
      size="small"
      isDisabled={isLoading}
      dataTestId="register-data-button"
      sx={{ color: isDevMode ? colorConst.devFeature : undefined }}
    >
      Register Data{isDevMode ? " (dev)" : ""}
      <FaroButtonSpinner
        shouldHide={!isLoading}
        loadingIndicatorColor={sphereColors.blue600}
        loadingTrackColor={sphereColors.gray100}
      />
    </FaroTextButton>
  );
}
