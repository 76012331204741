import {
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  numberedEnv,
  muiProKey,
  localizeKey,
  sentryDSNKey,
  amplitudeStagingKey,
  procoreApiUrlStaging,
  isNumberedEnv,
} from "@src/runtime-config-base";
import { RuntimeConfig } from "@src/runtime-config-types";

export const runtimeConfig: RuntimeConfig = {
  environment: "staging",
  appRegion: "com",
  appEnv: "com-staging",
  urls: {
    apiBaseUrl: "https://core.api.staging.holobuilder.com",
    sphereViewerUrl: "https://viewer.staging.holobuilder.com",
    dashboard20Api: "https://sphere.staging.holobuilder.com/api",
    hbWebEditorUrl: "https://app.staging.holobuilder.com",
    progressApiUrl: "https://progress.api.staging.holobuilder.com",
    sphereEntryPageUrl:
      numberedEnv === "dev1"
        ? "https://dev1.entry.staging.holobuilder.com"
        : "https://entry.staging.holobuilder.com",
    cookieManagerUrl:
      "https://cookies.staging.holobuilder.com/cookie-manager/manager/latest",
    cookieManagerPreflyUrl:
      "https://cookies.staging.holobuilder.com/cookie-manager/prefly/latest",
    powerBIBaseUrl: "https://hb-powerbi-func-com-staging.azurewebsites.net",
    projectApiUrl: "https://v2.project.api.staging.holobuilder.com",
    hbDashboardUrl: "https://dashboard.staging.holobuilder.com",
    registrationApiUrl: "https://cloud-reg.api.staging.holobuilder.com",
    stagingAreaApiUrl: "https://staging-area.api.staging.holobuilder.com",
  },
  features: {
    muiProKey,
    localizeKey,
    sentryDSNKey,
    gtm: {
      auth: "5EgnC5iiXCgkb63BXWkCtw",
      preview: "env-324",
      id: "GTM-PGZC4L",
    },
    auth0: {
      domain: "login.eu.stg.farosphere.com",
      clientId: "iRaekFL1jv86gIakQI31pbVMwGLEwB5T",
    },
    amplitudeKey: amplitudeStagingKey,
  },
  integrations: {
    procoreApiUrl: procoreApiUrlStaging,
  },
  appVersion,
  appTimestamp,
  commitId,
  commitUrl,
  pullRequestId,
  pullRequestUrl,
  jiraTicketId,
  jiraTicketUrl,
  mailSupportAddress: "support+staging@holobuilder.com",
  isNumberedEnv,
  numberedEnv,
};
