import { useCoreApiClient } from "@api/use-core-api-client";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { useIntegrationsService } from "@hooks/use-integrations-service";
import { Divider, Stack, Typography } from "@mui/material";
import { IntegrationSections } from "@pages/integrations/integrations-types";
import { runtimeConfig } from "@src/runtime-config";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { memo, SyntheticEvent, useEffect, useState } from "react";
import { IntegrationAutocomplete } from "@pages/project-details/project-integrations/integration-autocomplete";
import {
  IntegrationCompany,
  IntegrationProject,
} from "@services/integrations-service/integrations-service-types";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SphereLabel } from "@components/common/sphere-label";

interface Props {
  /** Selected integration ID */
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId;

  /** Callback while confirming connect dialog */
  onConnectConfirm(integrationProject: IntegrationProject): Promise<void>;

  /** True if connecting project is in progress */
  isConnecting: boolean;

  /** Callback while closing the dialog */
  onClose(): void;
}

/** Renders project integration connect dialog */
function ProjectIntegrationsConnectDialogComponent({
  selectedIntegrationId,
  onConnectConfirm,
  isConnecting,
  onClose,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const integrationsService = useIntegrationsService({
    coreApiClient,
    procoreApiUrl: runtimeConfig.integrations.procoreApiUrl,
  });
  const { handleErrorWithToast } = useErrorContext();

  const [companies, setCompanies] = useState<IntegrationCompany[]>([]);
  const [selectedCompany, setSelectedCompany] =
    useState<IntegrationCompany | null>(null);

  const [projects, setProjects] = useState<IntegrationProject[]>([]);
  const [selectedProject, setSelectedProject] =
    useState<IntegrationProject | null>(null);

  // Fetch companies of integration provider
  useEffect(() => {
    async function fetchCompanies(): Promise<void> {
      try {
        const companies = await integrationsService.getIntegrationCompanies(
          selectedIntegrationId
        );

        setCompanies(companies);
      } catch (error) {
        handleErrorWithToast({
          id: `fetchCompanies-${Date.now().toString()}`,
          title: "Error fetching integration workspaces",
          error,
        });
      }
    }

    void fetchCompanies();
  }, [handleErrorWithToast, integrationsService, selectedIntegrationId]);

  // Fetch projects under selected company of integration provider
  useEffect(() => {
    async function fetchProjects(): Promise<void> {
      if (!selectedCompany) {
        return;
      }

      try {
        const projects = await integrationsService.getIntegrationProjects(
          selectedCompany
        );

        setProjects(projects);
      } catch (error) {
        handleErrorWithToast({
          id: `fetchProjects-${Date.now().toString()}`,
          title: "Error fetching integration projects",
          error,
        });
      }
    }

    void fetchProjects();
  }, [handleErrorWithToast, integrationsService, selectedCompany]);

  function handleCompanyChange(
    _: SyntheticEvent<Element, Event>,
    newValue: IntegrationCompany | null
  ): void {
    setSelectedCompany(newValue);
    setSelectedProject(null);
  }

  function handleProjectChange(
    _: SyntheticEvent<Element, Event>,
    newValue: IntegrationProject | null
  ): void {
    setSelectedProject(newValue);
  }

  async function handleOnConfirm(): Promise<void> {
    if (selectedProject) {
      await onConnectConfirm(selectedProject);
    }
  }

  return (
    <FaroDialog
      open={true}
      isConfirmLoading={isConnecting}
      isConfirmDisabled={!selectedCompany || !selectedProject}
      onConfirm={() => void handleOnConfirm()}
      confirmText={isConnecting ? "Connecting" : "Connect"}
      fullWidth={true}
      title={
        <>
          Let's connect this project to{" "}
          <var>{IntegrationSections[selectedIntegrationId].displayName}</var>{" "}
        </>
      }
      onClose={onClose}
    >
      <Stack>
        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          Select the{" "}
          <var>{IntegrationSections[selectedIntegrationId].displayName}</var>{" "}
          project you would like to sync with this project.
        </Typography>

        <Divider sx={{ marginBottom: SPACE_ELEMENTS_OF_MODAL }} />

        {/* Workspace autocomplete */}
        <SphereLabel
          title={
            <>
              <var style={{ fontWeight: "inherit" }}>
                {IntegrationSections[selectedIntegrationId].displayName}
              </var>{" "}
              workspace
            </>
          }
        />
        <IntegrationAutocomplete
          value={selectedCompany}
          options={companies}
          onChange={handleCompanyChange}
          placeholder="Select workspace"
          shouldFocus={true}
        />

        {/* Project autocomplete */}
        <SphereLabel
          title={
            <>
              <var style={{ fontWeight: "inherit" }}>
                {IntegrationSections[selectedIntegrationId].displayName}
              </var>{" "}
              project
            </>
          }
          sx={{ marginTop: SPACE_ELEMENTS_OF_MODAL }}
        />
        <IntegrationAutocomplete
          value={selectedProject}
          options={projects}
          placeholder="Select project"
          onChange={handleProjectChange}
          shouldDisabled={!selectedCompany}
        />
      </Stack>
    </FaroDialog>
  );
}

export const ProjectIntegrationsConnectDialog = memo(
  ProjectIntegrationsConnectDialogComponent
);
