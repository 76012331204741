import { Box, CircularProgress, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { MarkupsSidePanelAttachmentItem } from "@pages/project-details/project-markups/sidepanel";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { MarkupUploadAttachment } from "@pages/project-details/project-markups/sidepanel/attachment/markup-upload-attachment";
import { useState } from "react";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { nounPluralize } from "@utils/data-display";
import { MarkupUploadingAttachment } from "@pages/project-details/project-markups/sidepanel/attachment/markup-uploading-attachment";

/**
 * Component responsible for displaying and managing attachments associated with a markup in the side panel.
 *
 * @param {BaseMarkupProps} props - The properties for the component, including the markup data.
 * @returns {JSX.Element} The rendered component.
 */
export function MarkupsSidePanelAttachments({
  markup,
}: BaseMarkupProps): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const { hasPermissionToEditMarkup } = useMarkupContext();

  const [isDownloadingAll, setIsDownloadingAll] = useState<boolean>(false);

  const numberOfAttachments = markup?.attachments.length || 0;

  /**
   * Downloads all images associated with a markup by creating individual download links for each image.
   * The images are downloaded sequentially using fetch and Blob API.
   *
   * @returns A Promise that resolves once all images are successfully downloaded.
   */
  async function downloadAllAttachments(): Promise<void> {
    if (!numberOfAttachments) {
      return;
    }

    // TODO: https://faro01.atlassian.net/browse/ST-1757 Create a zip file for the multiple images
    try {
      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: {
          property: "download all annotation attachments",
          isValueEmpty: false,
        },
      });

      setIsDownloadingAll(true);

      await Promise.all(
        markup?.attachments.map(async (attachment) => {
          const response = await fetch(attachment.uri);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = attachment.name;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          window.URL.revokeObjectURL(url);
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: "error when we were downloading the attachments",
        title: "Error with the download",
        error,
      });
    } finally {
      setIsDownloadingAll(false);
    }
  }

  return (
    <Box sx={{ mt: "34px" }}>
      {hasPermissionToEditMarkup && <MarkupUploadAttachment markup={markup} />}

      <Box sx={{ marginTop: "42px" }}>
        {numberOfAttachments ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: sphereColors.gray800,
                fontSize: "14px",
                mb: "12px",
              }}
            >
              {nounPluralize({
                counter: numberOfAttachments,
                word: "Attachment",
              })}
            </Typography>

            {isDownloadingAll ? (
              <CircularProgress size={"1rem"} sx={{ ml: "5px" }} />
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  color: sphereColors.blue500,
                  fontSize: "12px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
                onClick={downloadAllAttachments}
              >
                Download all
              </Typography>
            )}
          </Box>
        ) : (
          <Typography
            sx={{
              fontWeight: 600,
              color: sphereColors.gray800,
              mb: "10px",
              fontSize: "14px",
            }}
          >
            No attachments
          </Typography>
        )}

        <MarkupUploadingAttachment markupId={markup.id} />

        {numberOfAttachments > 0 && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          />
        )}

        {markup?.attachments && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {markup.attachments.map((attachment) => (
              <MarkupsSidePanelAttachmentItem
                attachment={attachment}
                key={attachment.id}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
