import { useAppParams } from "@router/router-helper";
import { WorkspaceSpecificBannerProps } from "@components/banners/workspace-specific-banner/workspace-specific-banner-types";
import { useMemo, useState } from "react";
import { isDateOverdue } from "@utils/date-utils";
import { Banner, FaroThemeOptions } from "@faro-lotv/flat-ui";
import { Box, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme(FaroThemeOptions);

/** Renders a banner that shows only for specific workspaces */
export function WorkspaceSpecificBanner({
  text,
  variant,
  ids,
  deactivationDate,
}: WorkspaceSpecificBannerProps): JSX.Element | null {
  const { companyId } = useAppParams();

  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  const hasDeactivationDatePassed = useMemo(() => {
    if(!deactivationDate) {
      return false;
    }
    return isDateOverdue(deactivationDate);
  }, [deactivationDate]);

  if (
    !companyId ||
    !ids.includes(companyId) ||
    hasDeactivationDatePassed ||
    isDismissed
  ) {
    return null;
  }

  return (
    <Box data-testid="workspace-specific-banner" component="div">
      <ThemeProvider theme={theme}>
        <Banner
          variant={variant}
          onClose={() => setIsDismissed(true)}
        >
          {text}
        </Banner>
      </ThemeProvider>
    </Box>
  );
}
