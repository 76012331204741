import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { Box, Skeleton } from "@mui/material";
import { PowerBIAPITypes } from "@stellar/api-logic";
import { selectedSdbCompanyIdSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { CommonStyles, DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect, useState } from "react";

/**
 * The (human-readable) names of the pages of the Premium Analytics reports
 * Don't change the name as the pages are already defined in the Power BI app
 */
export enum PremiumAnalyticsPages {
  projects = "Projects",
  user = "User",
  groups = "Groups",
}

interface Props {
  /** Determines which premium page should be displayed */
  pageDisplayName: PremiumAnalyticsPages;
}

/** Embeds premium analytics page using Power BI */
export function PowerBIAnalytics({ pageDisplayName }: Props): JSX.Element {
  const companyId = useAppSelector(selectedSdbCompanyIdSelector);
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [powerBIMeta, setPowerBIMeta] =
    useState<PowerBIAPITypes.IPowerBIReportMeta | null>(null);
  const [pageName, setPageName] = useState<string | null>(null);

  // Fetch Power BI meta data to embed in premium analytics page
  useEffect(() => {
    async function fetchPowerBIMeta(): Promise<void> {
      if (companyId) {
        try {
          setIsLoading(true);

          const companyReportMeta =
            await coreApiClient.V0.POWER_BI.getCompanyReportMeta(companyId);

          setPowerBIMeta(companyReportMeta);

          // Set the page name
          const page = companyReportMeta.pages.find(
            (page) => page.displayName === pageDisplayName
          );
          const name = page ? page.name : companyReportMeta.pages[0].name;
          setPageName(name);
        } catch (error) {
          handleErrorWithToast({
            id: `fetchPower BICompanyReport-${Date.now().toString()}`,
            title: "Error fetching analytics data",
            error,
          });
        } finally {
          setIsLoading(false);
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
    fetchPowerBIMeta();
  }, [companyId, coreApiClient, handleErrorWithToast, pageDisplayName]);

  return (
    <Box
      component="div"
      width="100%"
      height="80vh"
      sx={{
        marginTop: "12px",
        "& .Power_Bi_Embedded": {
          height: "100%",
        },
        "& .Power_Bi_Embedded iframe": {
          border: CommonStyles.Borders.gray200Divider,
        },
      }}
    >
      {/* Loading skeleton */}
      {isLoading && (
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", height: "100%" }}
        />
      )}

      {/* Analytics */}
      {!isLoading && powerBIMeta && pageName && (
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: powerBIMeta.reportId,
            tokenType: models.TokenType.Embed,
            embedUrl: powerBIMeta.embedUrl,
            accessToken: powerBIMeta.embedToken,
            pageName: pageName,
            filters: [],
            settings: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Defined in the Power BI settings
              filterPaneEnabled: false,
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Defined in the Power BI settings
              navContentPaneEnabled: false,
            },
          }}
          cssClassName="Power_Bi_Embedded"
        />
      )}

      {/* No data to display */}
      {!isLoading && (!powerBIMeta || !pageName) && (
        <Box
          component="div"
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
          }}
        >
          No premium analytics to display.
        </Box>
      )}
    </Box>
  );
}
