import { BaseMemberProps } from "@custom-types/member-types";
import { Link } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { useCoreApiClient } from "@api/use-core-api-client";
import {
  BaseCompanyIdProps,
  BaseProjectIdProps,
} from "@custom-types/sdb-company-types";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { isMemberActive } from "@utils/user-utils";
import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import {
  TableType,
  ProjectMemberEvents,
} from "@utils/track-event/track-event-list";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props
  extends BaseCompanyIdProps,
    Partial<BaseProjectIdProps>,
    BaseMemberProps {
  /** Recognizes on which subject this component is being used */
  subjectType: MemberTableSubject;
}

/**
 * Resend invite for a single unregistered member of:
 * projects when project is available and component rendered under the team tab of project overview,
 * workspace when project is not available because component is rendered under member on the workspace level.
 * This component is hidden by default and it is shown on table hover using ${SHOW_ON_HOVER_CLASS}.
 */
export function ResendInvitation({
  companyId,
  projectId,
  member,
  subjectType,
}: Props): JSX.Element | null {
  const coreApiClient = useCoreApiClient();
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  async function onResendInvitationClick(): Promise<void> {
    trackEvent({
      name: ProjectMemberEvents.resendInvitation,
      props: {
        dataType:
          subjectType === "project"
            ? TableType.projectMembers
            : TableType.companyMembers,
      },
    });

    try {
      // Resend invite to project
      if (projectId && subjectType === "project") {
        // No need to be an asyncThunk as we don't need to update the store
        await coreApiClient.V3.SDB.resendProjectMembershipInvite({
          companyId,
          projectId,
          email: member.email,
        });
      }

      // Resend invite to workspace or group
      if (
        !projectId &&
        (subjectType === "workspace" || subjectType === "group")
      ) {
        // No need to be an asyncThunk as we don't need to update the store
        await coreApiClient.V3.SDB.resendCompanyMembershipInvite({
          companyId,
          identity: member.email,
        });
      }

      showToast({
        message: "Invitation sent",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `resendInvite-${Date.now().toString()}`,
        title: "Could not send invitation",
        error,
      });
    }
  }

  if (isMemberActive(member)) {
    return null;
  }

  return (
    <Link
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onClick={onResendInvitationClick}
      underline="always"
      className={SHOW_ON_HOVER_CLASS}
      sx={{
        marginLeft: "10px",
        color: sphereColors.blue500,
        textDecorationColor: sphereColors.blue500,
        cursor: "pointer",
        // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
        visibility: "hidden",
      }}
    >
      Resend Invite
    </Link>
  );
}
